(<template>
  <article class="assign-withdraw">
    <p class="assign-withdraw__text">{{ $gettext('Are you sure you want to decline?') }}</p>
    <button class="sk-btn sk-btn--red assign-withdraw__btn"
            @click="catchAbandon">{{ $gettext('Decline') }}</button>
  </article>
</template>)

<script>
  export default {
    props: {
      data: {
        type: Object,
        default() {
          return {};
        }
      }
    },
    watch: {
      $route() {
        this.closeModal();
      }
    },
    methods: {
      closeModal() {
        this.$emit('closemodal');
      },
      catchAbandon() {
        this.data.context.sendAbandon();
        this.closeModal();
      }
    }
  };
</script>

<style scoped>
  .assign-withdraw {
    display: block;
    width: 100%;
    padding: 20px 30px;
  }

  .assign-withdraw__text {
    padding-bottom: 20px;
    font-size: 16px;
  }

  .assign-withdraw__btn {
    width: auto;
    padding: 0 30px;
  }
</style>
