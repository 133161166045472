<template>
  <div :class="{'dangerous-edit-footer': isDangerousFieldsEdit}"
       class="booking-footer">
    <div class="booking-footer__wrapper post-assignment__wrapper">
      <div class="booking-footer__text">{{ text }}</div>
      <button :class="{'in-progress': progress}"
              class="sk-btn sk-btn--default booking-footer__btn is-progress-bar"
              :disabled="disableBooking"
              @click="submit">{{ submitBtnText }}</button>
    </div>
  </div>
</template>

<script>

  import {mapGetters, mapState} from 'vuex';

  export default {
    props: {
      progress: {
        type: Boolean,
        default: true
      },
      textParams: {
        type: Object,
        default: () => {
          return {
            dateTimes: [],
            languageId: '3'
          };
        }
      },
      submitButtonText: {
        type: String,
        default: ''
      },
      validateFunc: {
        type: Function,
        default: () => {}
      },
      errorHandlingFunc: {
        type: Function,
        default: () => {}
      },
      successHandlingFunc: {
        type: Function,
        default: () => {}
      },
      sendInfoFunc: {
        type: Function,
        default: () => {}
      },
      summaryObj: {
        type: Object,
        required: false,
        default() {
          return null;
        }
      }
    },
    computed: {
      ...mapGetters('PostingStore', ['disableBooking']),
      ...mapState('PostingStore', {
        validationResult: ({validationResult}) => validationResult
      }),
      ...mapState('PostingNotifierStore', {
        isOnTranslation: ({isOnTranslation}) => isOnTranslation,
      }),
      isDangerousFieldsEdit() {
        return this.$route.query.dangerousEdit === 'true';
      },
      text() {
        const textsArr = [];

        if (this.languageToText) textsArr.push(this.languageToText);
        if (this.dateTimesText
          && (this.dateTimesText[0] != null
            || this.dateTimesText[0] != undefined)) {
          textsArr.push(this.dateTimesText);
        }
        return textsArr.join(' / ');
      },
      languageToText() {
        return this.$getLangName(this.textParams.languageId) || '';
      },
      dateTimesText() {
        // show only the next job
        const dateTimes = [...this.textParams.dateTimes]
          .sort((a, b) => {
            return Number(this.$moment(a.startTime).diff(b.startTime));
          })
          .slice(0, 1) || [];

        return dateTimes.map((dateTime) => {
          const startTime = this.getTime(dateTime.startTime);
          const finishTime = this.getTime(dateTime.finishTime);

          return startTime && finishTime ? `${startTime} - ${finishTime}` : startTime || finishTime;
        }) || [];
      },
      submitBtnText() {
        return this.submitButtonText || this.$gettext('Book');
      },
    },
    methods: {
      getTime(dateTime) {
        if (dateTime) {
          const dateTimeMoment = this.$moment(dateTime);
          const date = dateTimeMoment.format('DD');
          const month = this.$helperData.getMonthNames(this)[dateTimeMoment.month()].slice(0, 3);
          const time = dateTimeMoment.format('HH:mm');

          return `${date} ${month} ${time}`;
        }
      },
      sendInfo() {
        if (this.validateFunc()) {
          const directAward = this.$store.getters['PostInterpretationStore/getDirectAward'];
          this.sendInfoFunc().then((data = {}) => {
            this.$emit('postassignsuccess');
            this.$store.dispatch('ModalStore/closeModal');
            this.successHandlingFunc(data);
            localStorage.removeItem('unfinishedEditingJob');
            localStorage.removeItem('dangerousFieldsEditedValues');
            this.$store.commit('PostInterpretationStore/cleareEditingData');
            if (directAward && ['in_person', 'videoroom'].includes(data.job?.interpretationRequirement?.sessionType)) {
              this.openJobAsSupplier(data.job);
            }
          }).catch((error) => {
            this.$emit('postassignfailed');
            this.$store.dispatch('ModalStore/closeModal');
            this.errorHandlingFunc(error, this);
          });
        } else {
          this.$emit('postassignsuccess');
        }
      },
      submit() {
        if (this.validateFunc()) {
          if (this.summaryObj) {
            this.openSummaryModal();
          } else {
            this.sendInfo();
            this.setDirectAward(false);
          }
        } else {
          this.$scrollToErrors();
          this.setDirectAward(false);
        }
      },
      openSummaryModal() {
        const commonSummary = {
          title: this.$gettext('Review'),
          context: this
        };
        this.$store.commit('ModalStore/setModal', {
          component: 'post-summary',
          classes: ['sk-modal--new', 'booking-modal'],
          data: {...commonSummary, ...this.summaryObj}
        });
      },
      setDirectAward(value) {
        if (!this.isOnTranslation) this.$store.commit('PostInterpretationStore/setDirectAward', value);
      }
    }
  };
</script>

<style scoped>
  .dangerous-edit-footer {
    position: fixed;
    bottom: 0;
    left: 0;
    z-index: 11;
    width: 100%;
    background-color: #fff;
    box-shadow: 0 0 9px 0 rgba(0, 34, 102, 0.3);
  }

  .booking-footer__wrapper {
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin-top: 15px;
    margin-bottom: 15px;
  }

  .booking-footer__text {
    padding-right: 20px;
    font-weight: bold;
    font-size: 18px;
    line-height: 0.89;
  }

  .booking-footer__btn {
    width: 100%;
    max-width: 200px;
    height: 56px;
    padding: 0 15px;
    font-weight: bold;
    font-size: 16px;
    line-height: 56px;
  }

  @media (max-width: 767px) {
    .booking-footer__wrapper {
      flex-wrap: wrap;
    }

    .booking-footer__btn {
      margin: 20px auto 0;
    }

    .booking-footer__text {
      padding-right: 0;
    }
  }
</style>
