// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_GET_URL_IMPORT___ = require("../../../../../node_modules/css-loader/dist/runtime/getUrl.js");
var ___CSS_LOADER_URL_IMPORT_0___ = require("@assets/imgs/undefined_imgs/trash_ico.svg");
exports = ___CSS_LOADER_API_IMPORT___(false);
var ___CSS_LOADER_URL_REPLACEMENT_0___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_0___);
// Module
exports.push([module.id, "\n.attachments-section[data-v-df5acba8] {\n  display: block;\n  width: 100%;\n  margin-bottom: 20px;\n  font-size: 12px;\n}\n.attachments-section__files[data-v-df5acba8] {\n  position: relative;\n  display: -webkit-box;\n  display: -ms-flexbox;\n  display: flex;\n  -webkit-box-align: center;\n      -ms-flex-align: center;\n          align-items: center;\n  width: 100%;\n  height: 30px;\n  padding-left: 25px;\n}\n.attachments-section__remove-btn[data-v-df5acba8] {\n  position: absolute;\n  top: 0;\n  bottom: 0;\n  left: 0;\n  display: block;\n  width: 15px;\n  background-image: url(" + ___CSS_LOADER_URL_REPLACEMENT_0___ + ");\n  background-position: 50% 50%;\n  background-size: 12px auto;\n  background-repeat: no-repeat;\n}\n.attachments-section__remove-btn[data-v-df5acba8]:active {\n  background-color: #e1d7eb;\n}\n.attachments-section__file-error[data-v-df5acba8] {\n  width: 100%;\n  margin-top: 2px;\n  padding-bottom: 10px;\n  color: #f04;\n  font-size: 12px;\n}\n", ""]);
// Exports
module.exports = exports;
