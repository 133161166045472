<template>
  <article class="drag-drop-attachment__wrapper">
    <div :class="{'is-dragover': isDragover, 'is-with-error': showErrors}"
         class="drag-drop-attachment"
         @drop="dropHandler($event)"
         @dragover="dragOverHandler"
         @dragleave="dragLeaveHandler">
      <label :for="id"
             class="drag-drop-attachment__label"></label>
      <input :id="id"
             type="file"
             :multiple="!isSingle"
             :accept="'*'"
             class="drag-drop-attachment__button"
             @change="catchAttachment($event.target)" />
      <label :for="id"
             class="drag-drop-attachment__description"
             v-html="descriptionText"></label>
      <p v-if="subtitle"
         class="drag-drop-attachment__subtitle"
         v-html="subtitle"></p>
      <div class="drag-drop-attachment__attachments-cont">
        <div v-for="(attachment, index) in attachments"
             :key="index"
             class="drag-drop-attachment__files">
          <button type="button"
                  class="drag-drop-attachment__remove-btn"
                  @click="emitDelete({index, id: attachment.id})"></button>
          <p class="drag-drop-attachment__file-name">{{ attachment.name }}</p>
          <p v-if="attachment.error"
             class="drag-drop-attachment__file-error">{{ attachment.error }}</p>
        </div>
      </div>
      <div v-if="showErrors"
           class="sk-input__errors-cont">
        <span v-for="(error, index) in attachmentsErrors"
              :key="index"
              v-html="(attachmentsErrors.length === 1) ? error : `${index + 1}. ${error}`"></span>
      </div>
    </div>
  </article>
</template>

<script>
  // fires attachmentchanged and attachmentdeleted events
  export default {
    props: {
      id: {
        type: [Number, String],
        default: ''
      },
      attachments: {
        type: Array,
        default: () => []
      },
      attachmentsErrors: {
        type: Array,
        default: () => []
      },
      description: {
        type: String,
        default: ''
      },
      subtitle: {
        type: String,
        default: ''
      },
      isSingle: { // select one file only at a time
        type: Boolean,
        default: false
      }
    },
    data() {
      return {
        isDragover: false
      };
    },
    computed: {
      showErrors() { return this.attachmentsErrors.length; },
      descriptionText() {
        return this.description || this.$gettext('<p>Drag and drop or <span class="primary-color">choose a file to upload.</span></p>');
      }
    },
    methods: {
      catchAttachment(target) {
        this.$emit('attachmentchanged', target);
      },
      emitDelete({index, id}) {
        this.$emit('attachmentdeleted', {index, id});
      },
      setIsDragOver(isDragover) {
        this.isDragover = isDragover;
      },
      dropHandler(event) {
        event.preventDefault();
        this.catchAttachment(event.dataTransfer);
        this.setIsDragOver(false);
      },
      dragOverHandler(event) {
        event.preventDefault();
        this.setIsDragOver(true);
      },
      dragLeaveHandler(event) {
        event.preventDefault();
        this.setIsDragOver(false);
      }
    }
  };
</script>

<style scoped>
.drag-drop-attachment {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  padding: 16px;
  border: 1px dashed var(--cool-gray-200);
  border-radius: 8px;
}

.drag-drop-attachment.is-dragover {
  background-color: var(--salita-25);
}

.drag-drop-attachment.is-with-error {
  margin-bottom: 10px;
  background-color: #ff004440;
}

.drag-drop-attachment__button {
  width: 1px;
  height: 1px;
  opacity: 0;
}

.drag-drop-attachment__label {
  width: 40px;
  height: 40px;
  margin: 12px 0 16px 0;
  background-image: url(~@assets/imgs/undefined_imgs/upload_icon_grey.svg);
  background-position: center;
  background-repeat: no-repeat;
  text-decoration: underline;
  cursor: pointer;
}

.drag-drop-attachment__description,
.drag-drop-attachment__subtitle {
  text-align: center;
}

.drag-drop-attachment__description {
  cursor: pointer;
}

.drag-drop-attachment__attachments-cont {
  position: relative;
  display: inline-flex;
  flex-direction: column;
  justify-content: flex-start;
  width: 100%;
  margin: 5px 0;
}

.drag-drop-attachment__files {
  display: inline-flex;
  margin-top: 10px;
}

.drag-drop-attachment__remove-btn {
  display: block;
  width: 15px;
  margin-right: 10px;
  background-image: url(~@assets/imgs/undefined_imgs/trash_ico.svg);
  background-position: 50% 50%;
  background-size: 12px auto;
  background-repeat: no-repeat;
}

.drag-drop-attachment__remove-btn:active {
  background-color: #e1d7eb;
}

.drag-drop-attachment__file-error {
  width: 100%;
  margin-top: 2px;
  padding-bottom: 10px;
  color: #f04;
  font-size: 12px;
}

.sk-input__errors-cont {
  margin-left: 20px;
}
</style>
<style>
.primary-color {
  color: var(--color-primary);
}
</style>
