(<template>
  <div class="inter-blocked-modal">
    <p class="inter-blocked-modal__text">{{ modalText }}</p>
    <div class="inter-blocked-modal__btns-cont">
      <button class="sk-btn sk-btn--white inter-blocked-modal__btn inter-blocked-modal__btn--ok-btn"
              type="button"
              @click.prevent="acceptWarning">{{ $gettext('Yes') }}</button>
      <button class="sk-btn sk-btn--red inter-blocked-modal__btn inter-blocked-modal__btn--cancel-btn"
              type="button"
              @click.prevent="closeModal">{{ $gettext('No') }}</button>
    </div>
  </div>
</template>)

<script>
  import {mapGetters} from 'vuex';

  export default {
    props: {
      data: {
        type: Object,
        default() {
          return {};
        }
      }
    },
    computed: {
      ...mapGetters({
        userIsBusiness: 'UserInfoStore/userIsBusiness',
        userIsEnterpriseMember: 'UserInfoStore/userIsEnterpriseMember'
      }),
      modalData() {
        return this.data;
      },
      modalText() {
        if (this.userIsBusiness) {
          return this.$gettext('Some time ago you blocked this user. Are you sure you with to invite them now?');
        } else if (this.userIsEnterpriseMember) {
          return this.$gettext('Someone in your enterprise blocked this user. Are you sure you with to invite them?');
        }
      },
      modalCallback() {
        return this.modalData.context[this.modalMethod];
      },
      modalMethod() {
        return this.modalData.method;
      },
      modalParams() {
        return this.modalData.params;
      }
    },
    methods: {
      acceptWarning() {
        this.modalCallback(...this.modalParams);
        this.closeModal();
      },
      closeModal() {
        this.$emit('closemodal');
      }
    }
  };
</script>

<style scoped>
  .inter-blocked-modal {
    padding: 20px;
  }

  .inter-blocked-modal__text {
    margin-bottom: 20px;
  }

  .inter-blocked-modal__btns-cont {
    display: flex;
    justify-content: flex-end;
  }

  .inter-blocked-modal__btn {
    width: 100px;
  }

  .inter-blocked-modal__btn--ok-btn {
    margin-right: 20px;
  }
</style>
