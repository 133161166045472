<template>
  <div class="booking-tabs">
    <div class="booking-tabs__wrapper booking-tabs__wrapper--desktop">
      <router-link v-for="tab in tabs"
                   :key="tab.id"
                   :to="tab.link"
                   :class="[`booking-tabs__tile--${tab.id}`]"
                   class="booking-tabs__tile">
        <div class="booking-tabs__tile-icon"></div>
        <h2 class="booking-tabs__tile-title">{{ tab.title }}</h2>
      </router-link>
    </div>
    <div class="booking-tabs__wrapper booking-tabs__wrapper--mobile">
      <sk-select :description="assignmentTypeText"
                 :default-value="assignmentTypeText"
                 :items-list="tabs"
                 :preselected-value="assignmentType"
                 class="booking-tabs__select"
                 @csvaluechanged="setAssignmentType" />
    </div>
  </div>
</template>

<script>
  import {mapGetters} from 'vuex';

  export default {
    props: {
      isTranslation: {
        type: Boolean,
        default: false
      },
    },
    data() {
      return {
        assignmentType: this.$route.meta.assignmentType || ''
      };
    },
    computed: {
      ...mapGetters('UserInfoStore', [
        'allowNewTranslationServices',
        'allowInterpretationServices',
        'userIsBusiness'
      ]),
      assignmentTypeText() {
        return this.$gettext('Select assignment type');
      },
      interpretationTabsList() {
        return [{
                  id: 'interpretation',
                  link: this.$makeRoute({name: 'BuyerPostInterpretation'}),
                  name: this.$gettext('Interpreter'),
                  title: this.$gettext('Interpreter'),
                  condition: this.allowInterpretationServices || this.userIsBusiness
                }, {
                  id: 'fast_interpretation',
                  link: this.$makeRoute({name: 'BuyerPostFastInterpretation'}),
                  name: this.$gettext('Hastetolk'),
                  title: this.$gettext('Hast'),
                  condition: this.$store.getters['PostingNotifierStore/showFastInterpretationForm'] && this.allowInterpretationServices
                }, {
                  id: 'series_interpretation',
                  link: this.$makeRoute({name: 'BuyerPostSeriesInterpretation'}),
                  name: this.$gettext('Series booking'),
                  title: this.$gettext('Series'),
                  condition: this.$store.getters['UserInfoStore/userCanShowSeriesOrderFeature']
                }, {
                  id: 'secretary',
                  link: this.$makeRoute({name: 'BuyerPostSecretaryInterpretation'}),
                  name: this.$gettext('Secretary interpreter'),
                  title: this.$gettext('Secretary'),
                  condition: this.$store.getters['UserInfoStore/userCanShowSecretaryAssignmentFeature']
                },
                {
                  id: 'translation',
                  link: this.$makeRoute({name: 'BuyerPostTranslationProject'}),
                  name: this.$pgettext('translation', 'Translation'),
                  title: this.$pgettext('translation', 'Translation'),
                  condition: this.allowNewTranslationServices
                }
        ];
      },
      translationTabsList() {
        return [{
          id: 'translation',
          link: this.$makeRoute({name: 'BuyerPostTranslationProject'}),
          name: this.$pgettext('translation', 'Translation'),
          title: this.$pgettext('translation', 'Translation'),
        }, {
          id: 'correction',
          link: this.$makeRoute({name: 'BuyerPostCorrectionProject'}),
          name: this.$pgettext('translation', 'Correction'),
          title: this.$pgettext('translation', 'Correction'),
        }, {
          id: 'in_person',
          link: this.$makeRoute({name: 'BuyerPostInPersonProject'}),
          name: this.$pgettext('translation', 'In person'),
          title: this.$pgettext('translation', 'In person'),
        }];
      },
      filteredTabs() {
        return this.interpretationTabsList.filter((tab) => tab.condition);
      },
      tabs() {
        return this.isTranslation ? this.translationTabsList : this.filteredTabs;
      }
    },
    methods: {
      setAssignmentType(assignmentType) {
        const tab = this.tabs.find((tab) => tab.id === assignmentType) || {};

        this.assignmentType = assignmentType;
        this.$router.push(tab.link);
      }
    }
  };
</script>

<style scoped>
  .booking-tabs {
    border-bottom: 1px solid #d3d5de;
  }

  .booking-tabs__wrapper {
    display: flex;
    width: calc(100% + 30px);
    margin-right: -15px;
    margin-left: -15px;
  }

  .booking-tabs__tile {
    display: flex;
    align-items: center;
    margin: 0 15px;
    padding: 0 2px 10px;
    border-bottom: 3px solid transparent;
    border-radius: 1px;
  }

  .booking-tabs__tile-title {
    margin-left: 10px;
    font-weight: bold;
    font-size: 14px;
    line-height: 1;
  }

  .booking-tabs__tile.router-link-active {
    border-bottom: 3px solid var(--color-primary);
    color: var(--color-primary);
  }

  .booking-tabs__tile:hover {
    color: var(--color-secondary);
  }

  .booking-tabs__tile:focus {
    outline: var(--default-button-focus-outline);
  }

  .booking-tabs__tile-icon {
    width: 25px;
    height: 25px;
    background-position: center left;
    background-size: contain;
    background-repeat: no-repeat;
  }

  .booking-tabs__tile--interpretation .booking-tabs__tile-icon {
    width: 33px;
    background-image: url(~@assets/imgs/advanced_booking_types/interpretation.svg);
  }

  .booking-tabs__tile--interpretation:hover .booking-tabs__tile-icon,
  .booking-tabs__tile--interpretation.router-link-active .booking-tabs__tile-icon {
    background-image: var(--booking-tab-interpretation-active);
  }

  .booking-tabs__tile--fast_interpretation .booking-tabs__tile-icon {
    background-image: url(~@assets/imgs/advanced_booking_types/fast_interpretation.svg);
  }

  .booking-tabs__tile--fast_interpretation:hover .booking-tabs__tile-icon,
  .booking-tabs__tile--fast_interpretation.router-link-active .booking-tabs__tile-icon {
    background-image: var(--booking-tab-fast-interpretation-active);
  }

  .booking-tabs__tile--series_interpretation .booking-tabs__tile-icon {
    background-image: url(~@assets/imgs/advanced_booking_types/series_interpretation.svg);
  }

  .booking-tabs__tile--series_interpretation:hover .booking-tabs__tile-icon,
  .booking-tabs__tile--series_interpretation.router-link-active .booking-tabs__tile-icon {
    background-image: var(--booking-tab-series-interpretation-active);
  }

  .booking-tabs__tile--secretary .booking-tabs__tile-icon {
    width: 26px;
    background-image: url(~@assets/imgs/advanced_booking_types/secretary.svg);
  }

  .booking-tabs__tile--secretary:hover .booking-tabs__tile-icon,
  .booking-tabs__tile--secretary.router-link-active .booking-tabs__tile-icon {
    background-image: var(--booking-tab-secretary-active);
  }

  .booking-tabs__tile--translation .booking-tabs__tile-icon,
  .booking-tabs__tile--project .booking-tabs__tile-icon {
    width: 18px;
    background-image: url(~@assets/imgs/advanced_booking_types/translation.svg);
  }

  .booking-tabs__tile--translation:hover .booking-tabs__tile-icon,
  .booking-tabs__tile--translation.router-link-active .booking-tabs__tile-icon,
  .booking-tabs__tile--project:hover .booking-tabs__tile-icon,
  .booking-tabs__tile--project.router-link-active .booking-tabs__tile-icon {
    background-image: var(--booking-tab-translation-active);
  }

  /* booking tabs translation */
  .booking-tabs__tile--in_person .booking-tabs__tile-icon {
    background-image: url(~@assets/imgs/advanced_booking_types/in_person_translation.svg);
  }

  .booking-tabs__tile--in_person:hover .booking-tabs__tile-icon,
  .booking-tabs__tile--in_person.router-link-active .booking-tabs__tile-icon {
    background-image: url(~@assets/imgs/advanced_booking_types/in_person_translation_active.svg);
  }

  .booking-tabs__tile--correction .booking-tabs__tile-icon {
    background-image: url(~@assets/imgs/advanced_booking_types/correction_translation.svg);
  }

  .booking-tabs__tile--correction:hover .booking-tabs__tile-icon,
  .booking-tabs__tile--correction.router-link-active .booking-tabs__tile-icon {
    background-image: url(~@assets/imgs/advanced_booking_types/correction_translation_active.svg);
  }

  .booking-tabs__wrapper--mobile {
    display: none;
  }

  .booking-tabs__select {
    padding: 0 15px;
  }

  @media (max-width: 767px) {
    .booking-tabs {
      border-bottom: none;
    }

    .booking-tabs__wrapper--desktop {
      display: none;
    }

    .booking-tabs__wrapper--mobile {
      display: block;
    }
  }
</style>
