// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_GET_URL_IMPORT___ = require("../../../../node_modules/css-loader/dist/runtime/getUrl.js");
var ___CSS_LOADER_URL_IMPORT_0___ = require("@assets/imgs/arrows/arrow_down_grey.svg");
exports = ___CSS_LOADER_API_IMPORT___(false);
var ___CSS_LOADER_URL_REPLACEMENT_0___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_0___);
// Module
exports.push([module.id, "\n.alternative-language__section[data-v-18cfaddc] {\n  margin: 0 0 10px 15px;\n}\n.lang-input-desc__error[data-v-18cfaddc] {\n  color: #f04;\n}\n.lang-from__error[data-v-18cfaddc] {\n  display: block;\n  width: 100%;\n  margin-top: 2px;\n  color: #f04;\n  font-size: 12px;\n}\n.alternative-language__text[data-v-18cfaddc] {\n  margin-bottom: 5px;\n}\n.alternative-language__radio[data-v-18cfaddc] {\n  margin-left: 5px;\n}\n.languages__correction-checkbox[data-v-18cfaddc] {\n  margin: 0 15px 20px;\n}\n.languages__my-lang[data-v-18cfaddc] {\n  display: -webkit-box;\n  display: -ms-flexbox;\n  display: flex;\n  -webkit-box-pack: end;\n      -ms-flex-pack: end;\n          justify-content: flex-end;\n  width: 100%;\n  margin: 0 15px;\n}\n.languages__my-lang-cont[data-v-18cfaddc] {\n  display: -webkit-box;\n  display: -ms-flexbox;\n  display: flex;\n  -webkit-box-align: center;\n      -ms-flex-align: center;\n          align-items: center;\n  color: #646d8c;\n  font-size: 12px;\n}\n.languages__my-lang-select[data-v-18cfaddc] {\n  width: 80px;\n}\n.languages__my-lang-text[data-v-18cfaddc] {\n  padding-right: 5px;\n  text-align: right;\n}\n.select-language-description[data-v-18cfaddc] {\n  width: 100%;\n  padding-bottom: 2px;\n  font-size: 12px;\n}\n.select-language-btn[data-v-18cfaddc] {\n  display: block;\n  width: 100%;\n  height: 35px;\n  padding: 0 10px;\n  border: 1px solid #ddd;\n  text-align: left;\n}\n.select-language-btn[data-v-18cfaddc]::after {\n  content: url(" + ___CSS_LOADER_URL_REPLACEMENT_0___ + ");\n  float: right;\n  cursor: pointer;\n}\n.lang-input__error[data-v-18cfaddc] {\n  border: solid 1px #f04;\n}\n@media (max-width: 767px) {\n.languages__my-lang[data-v-18cfaddc] {\n    padding: 0 15px 0;\n}\n.languages__my-lang-cont[data-v-18cfaddc] {\n    margin-left: auto;\n}\n.select-language-description[data-v-18cfaddc] {\n    font-size: 8px;\n}\n}\n.in-person-description[data-v-18cfaddc] {\n  margin: 15px 15px 30px;\n}\n", ""]);
// Exports
module.exports = exports;
