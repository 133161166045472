(<template>
  <article class="remove-confirmation">
    <section class="remove-confirmation__wrapper">
      <p v-if="modalText"
         class="remove-confirmation__text"
         v-html="modalText"></p>
      <div class="remove-confirmation__btn-cont">
        <button v-if="modalCancelBtnText"
                class="sk-btn sk-btn--white remove-confirmation__btn remove-confirmation__btn--cancel"
                type="button"
                @click.prevent="closeModal">{{ modalCancelBtnText }}</button>
        <button class="sk-btn sk-btn--red remove-confirmation__btn remove-confirmation__btn--remove"
                type="button"
                @click.prevent="removeItem">{{ modalRemoveBtnText || $gettext('Remove') }}</button>
      </div>
    </section>
  </article>
</template>)

<script>

  export default {
    props: {
      data: {
        type: Object,
        default() {
          return {};
        }
      }
    },
    computed: {
      modalText() {
        return this.data.text || '';
      },
      modalBtnText() {
        return this.data.btnTexts;
      },
      modalRemoveBtnText() {
        return this.modalBtnText.removeBtnText || '';
      },
      modalCancelBtnText() {
        return this.modalBtnText.cancelBtnText || '';
      }
    },
    watch: {
      $route() {
        this.closeModal();
      }
    },
    methods: {
      closeModal() {
        this.$store.dispatch('ModalStore/closeModal');
      },
      removeItem() {
        this.data.context.removeItem(this.data.id);
        this.closeModal();
      }
    }
  };
</script>

<style scoped>
  .remove-confirmation {
    padding: 20px 30px;
  }

  .remove-confirmation__text {
    margin-bottom: 30px;
    text-align: center;
  }

  .remove-confirmation__btn-cont {
    display: flex;
    justify-content: center;
  }

  .remove-confirmation__btn {
    width: auto;
    padding: 0 15px;
  }

  .remove-confirmation__btn--cancel {
    margin-right: 15px;
  }
</style>
