import Vue from 'vue';
import Vuex from 'vuex';
import {cookieManager} from '@skiwo/sk-basic-components';
import UserInfoStore from '@/store/UserInfoStore';
import BusinessDashboardStore from '@/store/dashboard_stores/b_DashboardStore';
import InterpreterDashboardStore from '@/store/dashboard_stores/s_DashboardStore';
import DashboardSearchStore from '@/store/dashboard_stores/DashboardSearchStore';
import OneAssignmentStore from '@/store/assignment_stores/OneAssignmentStore';
import FAIFiltersStore from '@/store/assignment_stores/FAIFiltersStore';
import FAIListStore from '@/store/assignment_stores/FAIListStore';
import AllAssignmentsStore from '@/store/AllAssignmentsStore';
import AllProjectsStore from '@/store/project_stores/AllProjectsStore';
import AllSubtasksStore from '@/store/project_stores/AllSubtasksStore';
import EditProfileStore from '@/store/EditProfileStore';
import DailyCalendarStore from '@/store/DailyCalendarStore';
import EnterpriseStore from '@/store/EnterpriseStore';
import PaymentPayoutStore from '@/store/PaymentPayoutStore';
import InterpretersStore from '@/store/InterpretersStore';
import TTNotifPreferencesStore from '@/store/TTNotifPreferencesStore';
import PostInterpretationStore from '@/store/booking_stores/PostInterpretationStore';
import TTAuthStore from '@/store/TTAuthStore';
import TTManageClientsStore from '@/store/TTManageClientsStore';
import TTEnterpriseInfoStore from '@/store/TTEnterpriseInfoStore';
import TTEnterpriseSecurityStore from '@/store/TTEnterpriseSecurityStore';
import TTCalendarsStore from '@/store/TTCalendarsStore';
import CapacityStore from '@/store/CapacityStore';
import TTWizardStore from '@/store/TTWizardStore';
import TTHotlineStore from '@/store/TTHotlineStore';
import TTInterBookingStore from '@/store/booking_stores/TTInterBookingStore';
import TTDocumentsStore from '@/store/TTDocumentsStore';
import TTWorkingHoursStore from '@/store/TTWorkingHoursStore';
import TTPrivateEventsStore from '@/store/TTPrivateEventsStore';
import TTPayoutStore from '@/store/TTPayoutStore';
import RatesStore from '@/store/RatesStore';
import TTManageSupplierSkill from '@/store/TTManageSupplierSkill';
import MutationPlugin from '@/plugins/MutationPlugin';
import TTTestVideoStore from '@/store/TTTestVideoStore';
import TTSignaturesStore from '@/store/TTSignaturesStore';
import TTInvoicesStore from '@/store/TTInvoicesStore';
import FullAddressStore from '@/store/FullAddressStore';
import PostTranslationProjectStore from '@/store/booking_stores/PostTranslationProjectStore';
import PostStaffingStore from '@/store/booking_stores/PostStaffingStore';
import PostingStore from '@/store/booking_stores/PostingStore';
import PostingNotifierStore from '@/store/booking_stores/PostingNotifierStore';
import OneTranslationStore from '@/store/assignment_stores/OneTranslationStore';
import OneProjectStore from '@/store/project_stores/OneProjectStore';
import OneStaffingStore from '@/store/assignment_stores/OneStaffingStore';
import OpenBookingStore from '@/store/booking_stores/OpenBookingStore';
import ViewersStore from '@/store/ViewersStore';
import AssignmentFlowStore from '@/store/AssignmentFlowStore';
import StatisticsStore from '@/store/StatisticsStore';
import SystemLogStore from '@/store/SystemLogStore';
import TravelExpensesStore from '@/store/TravelExpensesStore';
import TicketSystemStore from '@/store/ticket_system_stores/TicketSystemStore';
import OneTicketStore from '@/store/ticket_system_stores/OneTicketStore';
import AdminChatStore from '@/store/AdminChatStore';
import PricingTemplatesStore from '@/store/PricingTemplatesStore';
import SidebarStore from '@/store/SidebarStore';
import TooltipRootStore from '@/store/TooltipRootStore';
import ToastStore from '@/store/ToastStore';
import InvoiceSettingsStore from '@/store/InvoiceSettingsStore';
import PostSecretaryStore from '@/store/booking_stores/PostSecretaryStore';
import FeedbackStore from '@/store/FeedbackStore';
import JobDecisionStore from '@/store/JobDecisionStore';
import ManagerSocketDataStore from '@/store/ManagerSocketDataStore';
import UserNotesStore from '@/store/UserNotesStore';
import DocumentCenterStore from '@/store/DocumentCenterStore';
import PostAssignmentHelperStore from '@/store/booking_stores/PostAssignmentHelperStore';
// Manager Stores
import AllCustomersStore from '@/store/manager_stores/AllCustomersStore';
import AllSuppliersStore from '@/store/manager_stores/AllSuppliersStore';
import AllEnterprisesStore from '@/store/manager_stores/AllEnterprisesStore';
import AllInterpretationSkillsStore from '@/store/manager_stores/AllInterpretationSkillsStore';
import AllTranslationSkillsStore from '@/store/manager_stores/AllTranslationSkillsStore';
import helpers from '@/helpers';

Vue.use(Vuex);

const isBrowser = new Function('try {return this===window;}catch(e){ return false;}');

export function createStore(context) {
  const {$fetcher: fetchData, $moment: moment} = context;
  return new Vuex.Store({
    plugins: [MutationPlugin(context)],
    modules: {
      SidebarStore: SidebarStore(context),
      TooltipRootStore: TooltipRootStore(context),
      ToastStore: ToastStore(context),
      b_DashboardStore: BusinessDashboardStore(context),
      s_DashboardStore: InterpreterDashboardStore(context),
      OneAssignmentStore: OneAssignmentStore(context),
      AllAssignmentsStore: AllAssignmentsStore(context),
      EditProfileStore: EditProfileStore(context),
      DailyCalendarStore: DailyCalendarStore(context),
      EnterpriseStore: EnterpriseStore(context),
      PaymentPayoutStore: PaymentPayoutStore(context),
      InterpretersStore: InterpretersStore(context),
      TTNotifPreferencesStore: TTNotifPreferencesStore(context),
      UserInfoStore: UserInfoStore(context),
      TTManageClientsStore: TTManageClientsStore(context),
      TTAuthStore: TTAuthStore(context),
      TTEnterpriseInfoStore: TTEnterpriseInfoStore(context),
      TTEnterpriseSecurityStore: TTEnterpriseSecurityStore(context),
      TTCalendarsStore: TTCalendarsStore(context),
      CapacityStore: CapacityStore(context),
      TTWizardStore: TTWizardStore(context),
      TTHotlineStore: TTHotlineStore(context),
      TTDocumentsStore: TTDocumentsStore(context),
      TTWorkingHoursStore: TTWorkingHoursStore(context),
      TTPrivateEventsStore: TTPrivateEventsStore(context),
      TTTestVideoStore: TTTestVideoStore(context),
      TTManageSupplierSkill: TTManageSupplierSkill(context),
      TTSignaturesStore: TTSignaturesStore(context),
      TTPayoutStore: TTPayoutStore(context),
      RatesStore: RatesStore(context),
      OneTranslationStore: OneTranslationStore(context),
      OneStaffingStore: OneStaffingStore(context),
      FullAddressStore: FullAddressStore(context),
      AssignmentFlowStore: AssignmentFlowStore(context),
      DashboardSearchStore: DashboardSearchStore(context),
      ViewersStore: ViewersStore(context),
      StatisticsStore: StatisticsStore(context),
      TravelExpensesStore: TravelExpensesStore(fetchData),
      FAIFiltersStore: FAIFiltersStore(context),
      FAIListStore: FAIListStore(context),
      SystemLogStore: SystemLogStore(context),
      TicketSystemStore: TicketSystemStore(context),
      OneTicketStore: OneTicketStore(context),
      TTInvoicesStore: TTInvoicesStore(context),
      AdminChatStore: AdminChatStore(context),
      PricingTemplatesStore: PricingTemplatesStore(context),
      InvoiceSettingsStore: InvoiceSettingsStore(context),
      FeedbackStore: FeedbackStore(context),
      JobDecisionStore: JobDecisionStore(context),
      // BOOKING STORES
      // common for translation and interpretation
      PostingNotifierStore: PostingNotifierStore(context),
      PostingStore: PostingStore(context),
      PostAssignmentHelperStore: PostAssignmentHelperStore(context),
      // translation project
      PostTranslationProjectStore: PostTranslationProjectStore(context),
      OneProjectStore: OneProjectStore(context),
      AllProjectsStore: AllProjectsStore(context),
      AllSubtasksStore: AllSubtasksStore(context),
      // interpretation
      PostInterpretationStore: PostInterpretationStore(context),
      TTInterBookingStore: TTInterBookingStore(context),
      OpenBookingStore: OpenBookingStore(context),
      // staffing
      PostStaffingStore: PostStaffingStore(context),
      // secretary
      PostSecretaryStore: PostSecretaryStore(context),
      UserNotesStore: UserNotesStore(context),
      DocumentCenterStore: DocumentCenterStore(context),
      // Manager Stores
      ManagerSocketDataStore: ManagerSocketDataStore(context),
      AllCustomersStore: AllCustomersStore(context),
      AllSuppliersStore: AllSuppliersStore(context),
      AllEnterprisesStore: AllEnterprisesStore(context),
      AllInterpretationSkillsStore: AllInterpretationSkillsStore(context),
      AllTranslationSkillsStore: AllTranslationSkillsStore(context)
    },
    state: {
      hardware: '',
      mobileApp: '',
      mobileAppRedirectedLink: '',
      chatWrapperPos: '',
      mobileTitle: '',
      openAssignModal: false,
      filterItemsAmount: 10,
      defaultSortBy: 'id',
      userCountry: '',
      selectsData: {
        language: [],
        qualification: [],
        departments: [],
        attenders: [],
        managers: [],
      },
      subPage: false,
      sidebarOpened: false,
      mobileNavOpened: false,
      mobileChatActive: false,
      notTrackedMutation: '',
      uploadProgress: {
        active: false,
        percentage: 0
      },
      serverVersion: 0
    },
    getters: {
      showMobileNav(state) {
        return state.mobileNavOpened;
      },
      attendersList(state) {
        return state.selectsData.attenders;
      },
      isNorway(state) {
        return state.userCountry === 'no';
      },
      managersForSelect(state) {
        if (state.selectsData.managers.length) {
          return state.selectsData.managers.map((manager) => {
            return {
              id: manager.uid,
              name: manager.name ? manager.name : manager.email,
              avatarPath: manager.avatarPath,
            };
          });
        }
        return [];
      },
      interpretationQualifications(state) { return state.selectsData.qualification?.filter((qualification) => qualification.industry == 'interpretation') || []; },
      translationQualifications(state) { return state.selectsData.qualification?.filter((qualification) => qualification.industry == 'translation') || []; },
    },
    actions: {
      updateProfileData({getters}, form) {
        return new Promise((resolve, reject) => {
          fetchData.updateProfileData(form, getters['UserInfoStore/userUid']).then(() => {
            resolve();
          }).catch((error) => {
            reject(error);
          });
        });
      },
      sendTimezone({commit, state}, moment) {
        if (!state.GlobalStore.timezoneRequestSent) {
          commit('GlobalStore/blockTimezoneRequest');
          return new Promise((resolve, reject) => {
            fetchData.sendTimezone(moment).then(() => {
              commit('GlobalStore/setTimezone');
              resolve();
            }).catch((error) => {
              reject(error);
            });
          });
        }
      },
      updateTimezone(context, moment) {
        return new Promise((resolve, reject) => {
          fetchData.sendTimezone(moment).then(() => {
            resolve();
          }).catch((error) => {
            reject(error);
          });
        });
      },
      sendOneTimeLogin(context, form) {
        return new Promise((resolve, reject) => {
          fetchData.sendOneTimeLogin(form).then((data) => {
            resolve(data);
          }).catch((error) => {
            reject(error);
          });
        });
      },
      sendResetEmail(context, form) {
        return new Promise((resolve, reject) => {
          fetchData.sendResetEmail(form).then((data) => {
            resolve(data);
          }).catch((error) => {
            reject(error);
          });
        });
      },
      sendResetPass(context, {form, token}) {
        return new Promise((resolve, reject) => {
          fetchData.sendResetPass(form, token).then((data) => {
            resolve(data);
          }).catch((error) => {
            reject(error);
          });
        });
      },
      sendNewPass(context, form) {
        return new Promise((resolve, reject) => {
          fetchData.sendNewPass(form).then((data) => {
            resolve(data);
          }).catch((error) => {
            reject(error);
          });
        });
      },
      acceptInvite(context, form) {
        return new Promise((resolve, reject) => {
          fetchData.acceptInvite(form).then((data) => {
            resolve(data);
          }).catch((error) => {
            reject(error);
          });
        });
      },
      sendSocialAuth(context, provider) {
        return new Promise((resolve, reject) => {
          fetchData.sendSocialAuth(provider).then((data) => {
            resolve(data);
          }).catch((error) => {
            reject(error);
          });
        });
      },
      fetchSelectList({commit, getters}, name) {
        return new Promise((resolve, reject) => {
          fetchData.fetchSelectsData(name, getters['UserInfoStore/enterpriseId']).then((item) => {
            if (item?.code === 401) {
              commit('TTAuthStore/logOut', '', {root: true});
              commit('GlobalStore/remove404Page', '', {root: true});
              resolve('LOGOUT');
              return;
            }
            commit('setSelectsData', {name, item});
            resolve(item);
          }).catch((error) => {
            reject(error);
          });
        });
      },
      fetchAttendersList({commit}, department) {
        return new Promise((resolve, reject) => {
          fetchData.fetchAttendersForDepartment(department).then((item) => {
            if (item?.code === 401) {
              commit('TTAuthStore/logOut', '', {root: true});
              commit('GlobalStore/remove404Page', '', {root: true});
              resolve('LOGOUT');
              return;
            }
            commit('setSelectsData', {name: 'attenders', item: item.attenders});
            resolve();
          }).catch((error) => {
            reject(error);
          });
        });
      },
      postAnAssignment({commit}, {jobForm, supportMode}) {
        return new Promise((resolve, reject) => {
            const lastJobCreatedAt = cookieManager.getCookie('lastJobCreatedAt');
            const now = moment(new Date());
            const creationDurationInSec = moment.duration(now.diff(moment(lastJobCreatedAt))).asSeconds();
            // Posting Job should be within minimum interval of 3secs
            if (lastJobCreatedAt && creationDurationInSec < 3) {
              reject();
            } else {
              cookieManager.setCookie('lastJobCreatedAt', now, {path: '/', expires: 2592000});
              fetchData.sendPostAnAssignment(jobForm, supportMode).then((newJobObj) => {
                if (newJobObj.job.bookingMechanism === 'price_check') {
                  const data = {};
                  data.jobId = newJobObj.job.id;
                  fetchData.getBuyerInvoicePreview(data);
                }
                if (newJobObj.redirect_url) {
                  // prepare payload for punchout redirect URL
                  helpers.webRequests.sendHTMLPost({url: newJobObj.redirect_url, payload: newJobObj.payload});
                } else {
                  commit('OneAssignmentStore/setStoreJob', newJobObj);
                }
                resolve(newJobObj);
              }).catch((error) => {
                reject(error);
              });
            }
        });
      },
      postLogoutAssignment(context, {jobForm}) {
        return new Promise((resolve, reject) => {
          fetchData.postLogoutAssignment(jobForm).then((response) => {
            resolve(response);
          }).catch((error) => {
            reject(error);
          });
        });
      },
      registerAnAssignment({commit}, {jobForm}) {
        return new Promise((resolve, reject) => {
          fetchData.sendRegisterAnAssignment(jobForm).then((newJobObj) => {
            commit('OneAssignmentStore/setStoreJob', newJobObj);
            resolve(newJobObj);
          }).catch((error) => {
            reject(error);
          });
        });
      },
      decriptFile(context, link) {
        return new Promise((resolve, reject) => {
          fetchData.decriptFile(link).then((newLink) => {
            resolve(newLink);
          }).catch((error) => {
            reject(error);
          });
        });
      },
      editAssignment({commit}, {jobForm, id, supportMode}) {
        return new Promise((resolve, reject) => {
          fetchData.sendEditAssignment(jobForm, id, supportMode).then((newJobObj) => {
            commit('OneAssignmentStore/setStoreJob', newJobObj);
            resolve(newJobObj);
          }).catch((error) => {
            reject(error);
          });
        });
      },
      editProject({commit}, {projectForm, id, supportMode}) {
        return new Promise((resolve, reject) => {
          fetchData.sendEditProject(projectForm, id, supportMode).then((newProject) => {
            commit('OneProjectStore/setProject', newProject.order);
            resolve(newProject);
          }).catch((error) => {
            reject(error);
          });
        });
      },
      sendPhoneVerificationSMS({getters}, form) {
        return new Promise((resolve, reject) => {
          fetchData.sendPhoneVerificationSMS(getters['UserInfoStore/userUid'], form).then(() => {
            resolve();
          }).catch((response) => {
            reject(response);
          });
        });
      },
      sendSMSCode({getters}, code) {
        return new Promise((resolve, reject) => {
          fetchData.sendSMSCode(getters['UserInfoStore/userUid'], code).then((data) => {
            resolve(data);
          }).catch((response) => {
            reject(response);
          });
        });
      },
      sendHelpMessage(context, form) {
        return new Promise((resolve, reject) => {
          fetchData.sendHelpMessage(form).then(() => {
            resolve();
          }).catch((response) => {
            reject(response);
          });
        });
      },
      verifyMagicLink(context, token) {
        return new Promise((resolve, reject) => {
          fetchData.verifyMagicLink(token).then((data) => {
            resolve(data);
          }).catch((error) => {
            reject(error);
          });
        });
      },
      verifyEmail(context, params) {
        return new Promise((resolve, reject) => {
          fetchData.verifyEmail(params.uid, params.token).then((data) => {
            resolve(data);
          }).catch((response) => {
            reject(response);
          });
        });
      },
      sendVerifyEmail() {
        return new Promise((resolve, reject) => {
          fetchData.sendVerifyEmail().then(() => {
            resolve();
          }).catch((response) => {
            reject(response);
          });
        });
      },
      checkActiveJobs({commit, getters}) {
        return new Promise((resolve, reject) => {
          fetchData.checkActiveJobs(getters['UserInfoStore/userUid']).then((data) => {
            if (data.code === 401 || data.code === 500) {
              commit('TTAuthStore/logOut', '', {root: true});
              commit('GlobalStore/remove404Page', '', {root: true});
              resolve('LOGOUT');
              return;
            }
            resolve(data);
          }).catch((response) => {
            reject(response);
          });
        });
      },
      checkServerVersion() {
        return new Promise((resolve, reject) => {
          fetchData.checkServerVersion().then((data) => {
            resolve(data);
          }).catch((error) => {
            reject(error);
          });
        });
      },
      getShortLivedToken() {
        const shortLivedToken = cookieManager.getCookie('shortLivedToken');
        const shortLivedTokenExpiresAt = cookieManager.getCookie('shortLivedTokenExpiresAt');
        const tokenExpired = moment().isAfter(moment.unix(shortLivedTokenExpiresAt));
        if (!shortLivedToken || tokenExpired) {
          return new Promise((resolve, reject) => {
            fetchData.getShortLivedToken().then((data) => {
              cookieManager.setCookie('shortLivedToken', data.token, {path: '/', expires: data.expiresAt});
              cookieManager.setCookie('shortLivedTokenExpiresAt', data.expiresAt, {path: '/', expires: data.expiresAt});
              resolve(data);
            }).catch((error) => {
              reject(error);
            });
          });
        } else {
          return {token: shortLivedToken};
        }
      },
      sendLog(context, payload) {
        return new Promise((resolve, reject) => {
          fetchData.sendLog(payload).then((data) => {
            resolve(data);
          }).catch((error) => {
            reject(error);
          });
        });
      },
    },
    mutations: {
      toggleSidebar(state) {
        state.sidebarOpened = !state.sidebarOpened;
      },
      closeSidebar(state) {
        state.sidebarOpened = false;
      },
      setHardware(state, obj) { state.hardware = obj; },
      setFilterItemsAmount(state, value) {
        state.filterItemsAmount = value;
        if (isBrowser()) {
          cookieManager.setCookie('filterItemsAmount', value, {path: '/', expires: 2592000});
        }
      },
      setDefaultSort(state, value) {
        state.defaultSortBy = value;
        if (isBrowser()) {
          cookieManager.setCookie('defaultSortBy', value, {path: '/', expires: 2592000});
        }
      },
      setUserCountry(state, value) { state.userCountry = value; },
      setMobileApp(state, value) {
        state.mobileApp = value !== 'undefined' && value !== undefined ? value : '';
        if (isBrowser()) {
          cookieManager.setCookie('mobileApp', state.mobileApp, {path: '/', expires: 2592000});
        }
      },
      setMobileAppRedirectedLink(state, value) {
        state.mobileAppRedirectedLink = value;
      },
      setChatWrapperPos(state, value) {
        state.chatWrapperPos = value;
      },
      clearChatWrapperPos(state) {
        state.chatWrapperPos = '';
      },
      setMobileTitle(state, title) {
        state.mobileTitle = title;
      },
      toggleMobileNav(state, force) {
        if (force) {
          state.mobileNavOpened = false;
          return;
        }
        state.mobileNavOpened = !state.mobileNavOpened;
      },
      setSubpage(state) {
        state.subPage = true;
      },
      removeSubpage(state) {
        state.subPage = false;
      },
      setMobileChatActive(state, chatActive) {
        if (typeof chatActive === 'boolean') {
          state.mobileChatActive = chatActive;
        }
      },
      setSelectsData(state, {name, item}) {
        Vue.set(state.selectsData, name, item);
      },
      removeDepartmentsInfo(state) {
        Vue.set(state.selectsData, 'departments', []);
      },
      removeAttendersInfo(state) {
        Vue.set(state.selectsData, 'attenders', []);
      },
      setOpenAssignModalState(state, value) {
        state.openAssignModal = value;
        if (isBrowser()) {
          cookieManager.setCookie('openAssignModal', (value || ''), {path: '/', expires: 2592000});
        }
      },
      executeNotTrackedMutation(state, value) {
        state.notTrackedMutation = value.mutationName;
      },
      removeSelectsData(state, name) {
        Vue.set(state.selectsData, name, []);
      },
      clearNotTrackedMutation(state) {
        state.notTrackedMutation = '';
      },
      setUploadPercentage(state, value) {
        state.uploadProgress.percentage = value;
      },
      setUploadProgress(state, value) {
        state.uploadProgress.active = value;
      },
      setServerVersion(state, value) {
        state.serverVersion = value;
      }
    }
  });
}
