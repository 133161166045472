// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_GET_URL_IMPORT___ = require("../../../../node_modules/css-loader/dist/runtime/getUrl.js");
var ___CSS_LOADER_URL_IMPORT_0___ = require("@assets/imgs/undefined_imgs/reset_icon.svg");
exports = ___CSS_LOADER_API_IMPORT___(false);
var ___CSS_LOADER_URL_REPLACEMENT_0___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_0___);
// Module
exports.push([module.id, "\n.deadline-section[data-v-ea74e44e] {\n  -ms-flex-wrap: nowrap;\n      flex-wrap: nowrap;\n}\n.deadline-section__datepicker[data-v-ea74e44e] {\n  width: 100%;\n  max-width: 100px;\n}\n.deadline-section__timepicker[data-v-ea74e44e] {\n  width: 100%;\n  max-width: 70px;\n}\n.deadline-section__tooltip[data-v-ea74e44e] {\n  position: relative;\n  top: 28px;\n  z-index: 1;\n  width: 15px;\n  height: 15px;\n  margin-left: 15px;\n  line-height: 15px;\n}\n.deadline-section__tooltip[data-v-ea74e44e]::before {\n  content: '?';\n  position: absolute;\n  top: 0;\n  z-index: -1;\n  width: 100%;\n  height: 100%;\n  border-radius: 50%;\n  background-color: #000;\n  color: #fff;\n  font-size: 12px;\n  text-align: center;\n}\n@media (max-width: 767px) {\n.deadline-section__tooltip[data-v-ea74e44e] {\n    top: 20px;\n}\n}\n.deadline-section__reset-icon[data-v-ea74e44e] {\n  -ms-flex-item-align: center;\n      align-self: center;\n  width: 20px;\n  height: 25px;\n  margin-right: 15px;\n  background-image: url(" + ___CSS_LOADER_URL_REPLACEMENT_0___ + ");\n  background-position: 50% 50%;\n  background-size: 17px auto;\n  background-repeat: no-repeat;\n  cursor: pointer;\n}\n.deadline-section__reset-icon[data-v-ea74e44e]:focus {\n  outline: var(--default-focus-outline);\n}\n.deadline-section__wrapper[data-v-ea74e44e] {\n  display: -webkit-box;\n  display: -ms-flexbox;\n  display: flex;\n  -webkit-box-orient: vertical;\n  -webkit-box-direction: normal;\n      -ms-flex-direction: column;\n          flex-direction: column;\n  -webkit-box-align: start;\n      -ms-flex-align: start;\n          align-items: flex-start;\n  width: 100%;\n}\n.deadline-section__hint-text[data-v-ea74e44e] {\n  margin: 0 15px 10px;\n  color: rgba(0, 0, 0, 0.5);\n}\n.deadline-section__mini-text[data-v-ea74e44e] {\n  margin: 0 15px 10px;\n  color: #f04;\n}\n", ""]);
// Exports
module.exports = exports;
