(<template>
  <div :class="{'is-with-error': showErrors}"
       class="attachments-section post-form__field">
    <sk-upload-file v-if="!hideBtn"
                    :id="'translationFile'"
                    :button-text="uploadText"
                    :reset="fileInputReset"
                    :is-single="isSingle"
                    :accept="'*'"
                    class="attachments-section__button"
                    @changefileinput="catchAttachment" />
    <div v-if="showErrors"
         class="sk-input__errors-cont">
      <span v-for="(error, index) in attachmentsErrors"
            :key="index"
            v-html="(attachmentsErrors.length === 1) ? error : `${index + 1}. ${error}`"></span>
    </div>
    <div v-for="(attachment, index) in attachments"
         :key="index">
      <div class="attachments-section__files">
        <p class="attachments-section__file-name">{{ attachment.name || attachment.desc || attachment.certificateFileName }}</p>
        <button type="button"
                class="attachments-section__remove-btn"
                @click="deleteAttachment({index, id: attachment.id})"></button>
      </div>
      <p v-if="attachment.error"
         class="attachments-section__file-error">{{ attachment.error }}</p>
    </div>
  </div>
</template>)

<script>
  import {mapState, mapGetters, mapMutations} from 'vuex';

  export default {
    props: {
      isSingle: {
        type: Boolean,
        default: false
      },
      hideBtn: {
        type: Boolean,
        default: false
      }
    },
    computed: {
      ...mapGetters('PostingStore/AttachmentsStore', [
        'attachments'
      ]),
      ...mapState('PostingStore/AttachmentsStore', {
        fileInputReset: (state) => state.fileInputReset,
        // errors
        attachmentsErrors: (state) => state.errors.attachments.errors || []
      }),
      uploadText() { return this.$gettext('Upload file'); },
      showErrors() { return this.attachmentsErrors.length; }
    },
    methods: {
      ...mapMutations('PostingStore/AttachmentsStore', [
        'setAttachments',
        'deleteAttachment',
        'setError',
        'removeErrors'
      ]),
      catchAttachment(target) {
        this.removeErrors();
        if ((this.isSingle && this.attachments && this.attachments.length == 0) || !this.isSingle) {
          if (target.files && target.files.length) {
            this.setAttachments(target);
          }
        }
      }
    }
  };
</script>

<style scoped>
  .attachments-section {
    display: block;
    width: 100%;
    margin-bottom: 20px;
    font-size: 12px;
  }

  .attachments-section__files {
    position: relative;
    display: flex;
    align-items: center;
    width: 100%;
    height: 30px;
    padding-left: 25px;
  }

  .attachments-section__remove-btn {
    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
    display: block;
    width: 15px;
    background-image: url(~@assets/imgs/undefined_imgs/trash_ico.svg);
    background-position: 50% 50%;
    background-size: 12px auto;
    background-repeat: no-repeat;
  }

  .attachments-section__remove-btn:active {
    background-color: #e1d7eb;
  }

  .attachments-section__file-error {
    width: 100%;
    margin-top: 2px;
    padding-bottom: 10px;
    color: #f04;
    font-size: 12px;
  }
</style>
