(<template>
  <post-accordion-section ref="directBookingSection"
                          :class="{'in-progress': progressActive}"
                          :disabled="disabledFields"
                          :state="directBookingSelected"
                          :title="title"
                          :description="description"
                          class="direct-booking is-progress-bar"
                          @focusin="openDirectBookingHelper"
                          @statechanged="updateDirectBooking">
    <div v-show="directBookingSelected"
         class="direct-booking__content">
      <search-by-description class="direct-booking__search-by-description" />
      <search-select :description="directBookingSelectDescription"
                     :default-value="directBookingDefaultText"
                     :items-list="computedSuppliersList"
                     :preselected-value="selectedSupplier"
                     :reg-exp="/[\\\/]/g"
                     class="direct-booking__search-select"
                     @startprogress="progressActive = true"
                     @stopprogress="progressActive = false"
                     @onresetfilter="clearSearchResult"
                     @onsuccess="catchSearchResult"
                     @csvaluechanged="changeSupplier" />
      <p v-if="directBookingWarning"
         class="sk-note direct-booking__warning">{{ getWarningText(directBookingWarning) }}</p>
      <template v-if="directBookingSelected">
        <direct-availability-section v-if="directBookingSelected"
                                     class="direct-booking__availability" />
        <p v-if="intersectDeadlines"
           class="direct-booking__deadline-warning">{{ deadlineWarningText }}</p>
        <direct-processing v-if="showDirectProcessingSection"
                           :description="directProcessingText" />
      </template>
    </div>
  </post-accordion-section>
</template>)

<script>
  import {mapState, mapMutations, mapActions, mapGetters} from 'vuex';
  import PostAccordionSection from '@/components/booking_components/PostAccordionSection';
  import SearchSupplierSelect from '@/components/booking_components/parts/direct_booking/SearchSupplierSelect';
  import SearchByDescription from '@/components/booking_components/parts/direct_booking/SearchByDescription';
  import DirectAvailabilitySection from '@/components/booking_components/parts/direct_booking/DirectAvailabilitySection';
  import DirectProcessingSection from '@/components/booking_components/parts/direct_booking/DirectProcessingSection';

  export default {
    components: {
      'search-select': SearchSupplierSelect,
      'search-by-description': SearchByDescription,
      'post-accordion-section': PostAccordionSection,
      'direct-availability-section': DirectAvailabilitySection,
      'direct-processing': DirectProcessingSection
    },
    props: {
      params: {
        type: Object,
        default() {
          return {};
        }
      }
    },
    data() {
      return {
        progressActive: false
      };
    },
    computed: {
      ...mapState('PostingStore/DirectBookingStore', {
        directBookingSelected: ({directBookingSelected}) => directBookingSelected,
        selectedSupplier: ({selectedSupplier}) => selectedSupplier,
        directBookingWarning: ({directBookingWarning}) => directBookingWarning,
        suppliersList: ({suppliersList}) => suppliersList || [],
        availabilityEvents: ({availabilityEvents}) => availabilityEvents || {},
        availabilityErrorName: ({errors}) => errors.availabilityError.name
      }),
      ...mapState('PostingStore/AssignmentTypeStore', {
        assignmentType: ({assignmentType}) => assignmentType
      }),
      ...mapState('PostingStore/DateTimeStore', {
        startTime: ({startTime}) => startTime,
        finishTime: ({finishTime}) => finishTime
      }),
      ...mapGetters('PostingNotifierStore', ['showDirectProcessingSection']),
      ...mapGetters('UserInfoStore', ['isEmailVerificationRequired']),
      startDate() {
        return this.startTime
          ? this.$moment(this.startTime).format('YYYY-MM-DD')
          : this.$moment().format('YYYY-MM-DD');
      },
      paramsList() { return this.params || {}; },
      disabledFields() { return this.paramsList.disabledFields || this.isEmailVerificationRequired; },
      title() { return this.$gettext('Specific interpreter'); },
      description() { return this.$gettext('I need a specific interpreter'); },
      directBookingSelectDescription() { return this.$gettext('Select Interpreter'); },
      directBookingDefaultText() { return this.$gettext('Interpreter name'); },
      directProcessingText() { return this.$gettext('If this interpreter is not available:'); },
      deadlineWarningText() { return this.$gettext('The interpreter is busy during this time. Please try booking a different time slot, or a different interpreter.'); },
      intersectDeadlines() {
        const jobs = this.availabilityEvents?.jobs || [];
        const events = this.availabilityEvents?.events || [];
        const allEvents = [...jobs, ...events];
        return allEvents.some((event) => this.checkOverlapDate(
          this.$moment(event.startTime),
          this.$moment(event.finishTime),
          this.startTime,
          this.finishTime
        ));
      },
      computedSuppliersList() {
        if (!this.suppliersList.length) return [{}];
        return this.suppliersList.map((supplier) => {
          return {
            id: supplier.uid,
            name: supplier.name
          };
        });
      },
      showAssignmentHelper() { return this.params.showAssignmentHelper; }
    },
    methods: {
      ...mapActions('PostingStore/DirectBookingStore', [
        'changeSupplier',
        'changeDirectBooking',
      ]),
      ...mapMutations('PostingStore/DirectBookingStore', [
        'setSuppliersList'
      ]),
      ...mapMutations('PostAssignmentHelperStore', [
        'openHelper',
        'setPopoverReference',
        'setReferenceToBuyerInfoSection',
      ]),
      checkOverlapDate(startEvent, endEvent, startCurrent, endCurrent) {
        return startEvent.isBefore(endCurrent) && endEvent.isAfter(startCurrent);
      },
      openDirectBookingHelper() {
        if (this.showAssignmentHelper) {
          this.setPopoverReference(this.$refs.directBookingSection);
          this.openHelper('directBooking');
        }
      },
      updateDirectBooking(value) {
        if (!value) {
          this.setReferenceToBuyerInfoSection();
          this.openHelper('');
        }
        this.changeDirectBooking(value);
      },
      clearSearchResult() {
        this.setSuppliersList([]);
      },
      catchSearchResult() {
        if (!this.suppliersList.length) {
          this.setSuppliersList([{name: 'Not found', uid: ''}]);
        }
      },
      getWarningText(type) {
        const baseWarning = this.$gettext('The interpreter you had chosen does not fit your requirements. Please search again.');
        let changedField = '';
        switch (type) {
          case 'langFrom':
            changedField = this.$gettext('Your language has changed.');
            break;
          case 'langTo':
            changedField = this.$gettext('Target language has changed.');
            break;
          case 'gender':
            changedField = this.$gettext('Gender has changed.');
            break;
          case 'qualification':
            changedField = this.$gettext('Qualification has changed.');
            break;
        }
        return `${changedField} ${baseWarning}`;
      }
    }
  };
</script>

<style scoped>
  .direct-booking__content {
    margin-top: 15px;
  }

  .direct-booking__search-by-description {
    margin-bottom: 15px;
  }

  .direct-booking__warning {
    margin-top: 10px;
  }

  .direct-booking__availability {
    padding-top: 25px;
  }

  .direct-booking__deadline-warning {
    padding-left: 18px;
    background-image: url(~@assets/imgs/salita/undefined_imgs/warning_icon_red.svg);
    background-size: 17px;
    background-repeat: no-repeat;
    color: #f04;
    font-weight: bold;
    font-size: 12px;
    line-height: 16px;
  }
</style>
