(<template>
  <div class="delete-payout">
    <p class="payout__modal-text">{{ areYouSureText }}</p>
    <section class="payout__modal-btns-cont">
      <button class="sk-btn sk-btn--white payout__modal-btn"
              @click="$emit('closemodal')">
        {{ cancelText }}
      </button>
      <button class="sk-btn sk-btn--red payout__modal-btn"
              @click="remove">{{ removeText }}</button>
    </section>
  </div>
</template>)

<script>
  export default {
    props: {
      data: {
        type: Object,
        default() {
          return {};
        }
      }
    },
    computed: {
      areYouSureText() { return this.$gettext('Are you sure you want to remove this payout method?'); },
      cancelText() { return this.$gettext('Cancel'); },
      removeText() { return this.$gettext('Remove'); }
    },
    methods: {
      remove() {
        this.data.confirm(this.data.data.id, this.data.data.type);
      }
    }
  };
</script>

<style scoped>
  .delete-payout {
    padding: 20px;
  }

  .payout__modal-text {
    font-size: 18px;
  }

  .payout__modal-btns-cont {
    display: flex;
    align-self: flex-end;
    justify-content: flex-end;
    width: 100%;
    padding: 35px 0 0 0;
  }

  .payout__modal-btn {
    width: auto;
    min-width: 100px;
    max-width: 200px;
    margin-bottom: 0;
    margin-left: 10px;
    padding: 0 25px;
  }
</style>
