(<template>
  <div class="post-form__section languages">
    <p v-if="isInPersonAssignment"
       class="in-person-description">{{ inPersonDescription }}</p>
    <section v-if="showMyLang"
             class="languages__my-lang">
      <div class="languages__my-lang-cont">
        <p class="languages__my-lang-text">{{ $gettext('My language:') }}</p>
        <sk-select :default-value="langDefaultValue"
                   :preselected-value="langFrom"
                   :disabled="disabledFields"
                   :items-list="langsDifferentThanTargetLangs"
                   class="sk-select--lang languages__my-lang-select"
                   @csvaluechanged="changeLangFrom" />
      </div>
    </section>
    <sk-checkbox v-if="isCorrectionAssignment"
                 class="languages__correction-checkbox"
                 :item-text="$gettext('No source language')"
                 :preselected-value="noSourceLang"
                 @checkboxvaluechanged="catchNoSourceLangCheckbox" />
    <section v-if="showSelectLanguagePopup"
             class="post-form__field post-form__field--half-width">
      <p class="select-language-description"
         :class="{'lang-input-desc__error': inputFieldValidation}">{{ langFromDescription }}</p>
      <button class="select-language-btn"
              :class="{'lang-input__error': inputFieldValidation}"
              @click="openSelectLanguageModal">
        {{ selectLanguageText }}
      </button>
      <p v-if="inputFieldValidation"
         class="lang-from__error">{{ langFromErrors[0] }}</p>
    </section>
    <sk-select v-else-if="showFromLang && !noSourceLang"
               :description="langFromDescription"
               :items-list="langsDifferentThanTargetLangs"
               :marked-items="prioritizedFromLangs"
               :preselected-value="langFrom"
               :default-value="langDefaultValue"
               :validation-name="langFromErrorsName"
               :disabled="disabledFields"
               class="post-form__field post-form__field--half-width"
               @csvaluechanged="changeLangFrom" />
    <sk-multiselect ref="targetLanguage"
                    :items-list="langsDifferentThanSourceLangs"
                    :description="targetLanguagesText"
                    :preselected-value="targetLanguages"
                    :default-value="langDefaultValue"
                    :disabled="disabledFields"
                    :validation-name="targetLanguagesErrorName"
                    :marked-items="prioritizedToLangs"
                    :class="{'post-form__field--half-width': !noSourceLang}"
                    class="post-form__field"
                    @focus="openTargetLanguageHelper"
                    @checkboxeschanged="handleTargetLangs" />
    <div v-if="alternativeLangParams.show && !isCorrectionAssignment"
         class="alternative-language__section">
      <p class="alternative-language__text">{{ alternativeTargetLanguageText }}</p>
      <sk-checkbox :item-text="$gettext('Yes')"
                   :preselected-value="alternativeTargetLanguage"
                   @checkboxvaluechanged="catchCheckbox" />
    </div>
  </div>
</template>)

<script>
  import {mapActions, mapState, mapMutations} from 'vuex';
  import helpers from '@/helpers';

  export default {
    props: {
      params: {
        type: Object,
        default() {
          return {};
        }
      }
    },
    computed: {
      ...mapState('PostingStore/LanguagesStore', {
        alternativeTargetLanguage: (state) => state.alternativeTargetLanguage,
        langFrom: (state) => state.languageFrom,
        langTo: (state) => state.languageTo,
        noSourceLang: (state) => state.noSourceLang,
        targetLanguages: (state) => state.targetLanguages,
        targetLanguagesErrorName: (state) => state.errors.targetLangs.name,
        langFromErrorsName: (state) => state.errors.langFrom.name,
        langFromErrors: (state) => state.errors.langFrom.errors
      }),
      alternativeTargetLanguageText() {
        const template = this.$gettext('Would you be open to receiving the translation to %{language} as an alternative?');
        return this.$gettextInterpolate(template, {language: this.alternativeLangParams.langName});
      },
      alternativeLangParams() {
        let show = false;
        let langId = '';
        let langName = '';

        if (this.targetLanguages.length === 1) {
          // target langs
          const targetEnglish = this.targetLanguages.includes(2);
          const targetNorwegianNynorsk = this.targetLanguages.includes(212);
          const targetNorwegianBookmal = this.targetLanguages.includes(3);
          // source langs
          const sourceNotUkrainian = this.langFrom !== 43;
          const sourceUkrainian = this.langFrom === 43;
          const sourceNotNorwegianBookmal = this.langFrom != 3;

          if ((targetEnglish || targetNorwegianNynorsk) && sourceNotUkrainian && sourceNotNorwegianBookmal) {
            show = true;
            langId = '3';
            langName = 'Norsk (Bokmål)';
          }
          if (sourceUkrainian && (targetNorwegianNynorsk || targetNorwegianBookmal)) {
            show = true;
            langId = '2';
            langName = 'English';
          }
        }

        return {show, langId, langName};
      },
      inputFieldValidation() { return this.langFromErrors.length && !this.langFrom; },
      langFromDescription() { return this.$pgettext('translation', 'Language From:'); },
      languageText() { return this.$gettext('Language:'); },
      paramsList() { return this.params || {}; },
      disabledFields() { return this.paramsList.disabledFields; },
      targetLanguagesText() {
        return this.noSourceLang ? this.$gettext('Language:') : (this.paramsList.langToText || this.$pgettext('translation', 'Language To:'));
      },
      showMyLang() { return this.paramsList.showMyLang || false; },
      showFromLang() { return this.paramsList.showFromLang || false; },
      isCorrectionAssignment() { return this.paramsList.assignmentType === 'correction'; },
      isInPersonAssignment() { return this.paramsList.assignmentType === 'in_person'; },
      useSelectLanguagePopup() { return this.paramsList.useSelectLanguagePopup || false; },
      langDefaultValue() { return this.$gettext('Select a language'); },
      languages() { return this.$store.state.selectsData.language || []; },
      showSelectLanguagePopup() {
        return this.useSelectLanguagePopup && !this.showMyLang && !this.noSourceLang;
      },
      selectLanguageText() {
        return this.languages.find((lang) => lang.id == this.langFrom)?.name || this.$gettext('Select a language');
      },
      prioritizedFromLangTranslated() {
        return [
          ...helpers.searchArrayItemFromBaseArray({
            baseArr: this.languages,
            searchArr: this.prioritizedFromLangs
          })
        ];
      },
      filteredFromLangs() {
        return [
          ...this.prioritizedFromLangTranslated,
          ...helpers.excludeArrayFromBaseArray({
            baseArr: this.languages,
            excludeArr: this.prioritizedFromLangs
          })
        ];
      },
      filteredToLangs() {
        return [
          ...this.prioritizedToLangTranslated,
          ...helpers.excludeArrayFromBaseArray({
            baseArr: this.languages,
            excludeArr: this.prioritizedToLangs
          })
        ];
      },
      prioritizedToLangTranslated() {
        return [
          ...helpers.searchArrayItemFromBaseArray({
            baseArr: this.languages,
            searchArr: this.prioritizedToLangs
          })
        ];
      },
      // TODO: New Norwegian to be added
      prioritizedFromLangs() {
        return helpers.openBookingHelper.prioritizedFromLanguages();
      },
      prioritizedToLangs() {
        return helpers.openBookingHelper.prioritizedToLanguages();
      },
      // Prevent someone from entering the same source and destination
      langsDifferentThanSourceLangs() {
        return this.filteredToLangs.filter((lang) => lang.id != this.langFrom);
      },
      langsDifferentThanTargetLangs() {
        return this.filteredFromLangs.filter((lang) => !this.targetLanguages.includes(lang.id));
      },
      inPersonDescription() {
        return this.$gettext('In-person translators are available to assist with the translation of your sensitive documents, either at your office or another location. This service is specifically reserved for critical situations where online sharing is not possible or when co-creation is needed.');
      },
      showAssignmentHelper() { return this.params.showAssignmentHelper; }
    },
    methods: {
      ...mapActions('PostingStore/LanguagesStore', ['changeLangFrom']),
      ...mapMutations('PostingStore/LanguagesStore', [
        'setAlternativeTargetLanguage',
        'setTargetLangs',
        'setNoSourceLang'
      ]),
      ...mapMutations('PostAssignmentHelperStore', [
        'openHelper',
        'setContext',
        'setPopoverReference',
      ]),
      catchNoSourceLangCheckbox() {
        this.setNoSourceLang(!this.noSourceLang);
      },
      openTargetLanguageHelper() {
        if (this.showAssignmentHelper) {
          this.setContext('translation');
          this.setPopoverReference(this.$refs.targetLanguage);
          this.openHelper('targetLanguage');
        }
      },
      handleTargetLangs(value) {
        this.setAlternativeTargetLanguage('');
        this.setTargetLangs(value);
      },
      catchCheckbox() {
        this.setAlternativeTargetLanguage(this.alternativeLangParams.langId);
      },
      closeModal() {
        this.$emit('closemodal');
      },
      openSelectLanguageModal() {
        this.closeModal();
        this.$store.commit('ModalStore/setModal', {
          component: 'select-language-modal',
          width: 800,
          data: {
            context: this,
            method: 'changeLangFrom',
            field: 'langFrom',
          }
        });
      },
    }
  };
</script>

<style>
.sk-select__list-item .is-marked {
  font-weight: bold;
}
</style>

<style>
.languages__correction-checkbox .sk-checkbox__input:checked + .sk-checkbox__imitation {
  border: 1px solid #000 !important;
  background-color: #000 !important;
}

.languages__correction-checkbox .sk-checkbox__imitation {
  border: 1px solid #a4a9bc !important;
}
</style>

<style scoped>
.alternative-language__section {
  margin: 0 0 10px 15px;
}

.lang-input-desc__error {
  color: #f04;
}

.lang-from__error {
  display: block;
  width: 100%;
  margin-top: 2px;
  color: #f04;
  font-size: 12px;
}

.alternative-language__text {
  margin-bottom: 5px;
}

.alternative-language__radio {
  margin-left: 5px;
}

.languages__correction-checkbox {
  margin: 0 15px 20px;
}

.languages__my-lang {
  display: flex;
  justify-content: flex-end;
  width: 100%;
  margin: 0 15px;
}

.languages__my-lang-cont {
  display: flex;
  align-items: center;
  color: #646d8c;
  font-size: 12px;
}

.languages__my-lang-select {
  width: 80px;
}

.languages__my-lang-text {
  padding-right: 5px;
  text-align: right;
}

.select-language-description {
  width: 100%;
  padding-bottom: 2px;
  font-size: 12px;
}

.select-language-btn {
  display: block;
  width: 100%;
  height: 35px;
  padding: 0 10px;
  border: 1px solid #ddd;
  text-align: left;
}

.select-language-btn::after {
  content: url(~@assets/imgs/arrows/arrow_down_grey.svg);
  float: right;
  cursor: pointer;
}

.lang-input__error {
  border: solid 1px #f04;
}

@media (max-width: 767px) {
  .languages__my-lang {
    padding: 0 15px 0;
  }

  .languages__my-lang-cont {
    margin-left: auto;
  }

  .select-language-description {
    font-size: 8px;
  }
}

.in-person-description {
  margin: 15px 15px 30px;
}
</style>
