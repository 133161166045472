(<template>
  <form class="advanced-filters"
        @submit.stop.prevent="$emit('submitfilters')">
    <div class="advanced-filters__wrapper">
      <section class="advanced-filters__colored-section">
        <sk-input :placeholder="text1"
                  :icon="'search'"
                  :preselected-value="data.nameSearch"
                  class="advanced-filters__name-search"
                  @fieldvaluechanged="catchNameSearch" />
      </section>
      <section class="advanced-filters__main-section">
        <sk-multiselect :default-value="text3"
                        :description="text2"
                        :items-list="qualificationsList"
                        :preselected-value="data.qualifications"
                        :icon="'qualification'"
                        class="advanced-filters__select"
                        @checkboxeschanged="catchQualifications" />
        <section class="advanced-filters__gender-section">
          <p class="advanced-filters__section-name">{{ $gettext('Gender') }}</p>
          <sk-radio :item-text="text8"
                    :item-name="'gender'"
                    :preselected-value="data.gender"
                    :item-value="2"
                    class="advanced-filters__radio"
                    @radiochanged="catchRadio" />
          <sk-radio :item-text="text9"
                    :item-name="'gender'"
                    :preselected-value="data.gender"
                    :item-value="1"
                    class="advanced-filters__radio"
                    @radiochanged="catchRadio" />
          <sk-radio :item-text="text10"
                    :item-name="'gender'"
                    :preselected-value="data.gender"
                    :item-value="0"
                    class="advanced-filters__radio"
                    @radiochanged="catchRadio" />
        </section>
        <section class="advanced-filters__toggler-section">
          <p class="advanced-filters__section-name">{{ $gettext('Show only my favourite interpreters') }}</p>
          <sk-toggler :preselected-value="data.favor"
                      @togglerchanged="catchFavor" />
        </section>
        <section class="advanced-filters__btns-section">
          <button class="sk-btn sk-btn--white advanced-filters__action-btn"
                  type="button"
                  @click="clearFilters">{{ $gettext('Clear') }}</button>
          <button class="sk-btn sk-btn--default advanced-filters__action-btn"
                  @click="applyFilters">{{ $gettext('Apply filters') }}</button>
        </section>
      </section>
    </div>
  </form>
</template>)

<script>
  export default {
    props: {
      data: {
        type: Object,
        default() {
          return {
            nameSearch: {
              type: String,
              default: ''
            },
            qualifications: {
              type: Array,
              default: () => []
            },
            gender: {
              type: [String, Number],
              default: '2'
            },
            favor: {
              type: [String, Boolean],
              default: ''
            },
            catchNameSearch: () => {},
            catchQualifications: () => {},
            catchGender: () => {},
            catchFavor: () => {},
            submitFilters: () => {},
            clearFilters: () => {}
          };
        }
      }
    },
    computed: {
      qualificationsList() { return JSON.parse(JSON.stringify(this.$store.getters.interpretationQualifications)); },
      text1() { return this.$gettext('Search by name'); },
      text2() { return this.$gettext('Qualifications'); },
      text3() { return this.$gettext('All qualifications'); },
      text8() { return this.$gettext('Any'); },
      text9() { return this.$gettext('Female'); },
      text10() { return this.$gettext('Male'); }
    },
    watch: {
      $route() {
        this.closeModal();
      }
    },
    methods: {
      closeModal() {
        this.$emit('closemodal');
      },
      catchNameSearch(value) {
        this.data.catchNameSearch(value);
      },
      catchQualifications(value) {
        this.data.catchQualifications(value);
      },
      catchRadio(value) {
        this.data.catchGender(value);
      },
      catchFavor(value) {
        this.data.catchFavor(value);
      },
      applyFilters() {
        this.data.submitFilters();
      },
      clearFilters() {
        this.data.clearFilters();
      }
    }
  };
</script>

<style>
  .advanced-filters__modal .sk-modal__wrapper .sk-modal__title {
    padding: 0;
  }

  .advanced-filters__name-search .sk-input__input {
    border: solid 1px #9dd;
    background-color: #fff;
  }

  .advanced-filters__name-search .sk-input__field-left-icon {
    left: 0;
  }

  .advanced-filters__select .sk-date-picker__calendar.sk-date-picker__calendar {
    left: -21px;
  }

  .advanced-filters .advanced-filters__radio.sk-radio__label {
    display: inline-block;
    margin-right: 25px;
    margin-bottom: 15px;
  }

  @media (max-width: 767px) {
    .advanced-filters__name-search.sk-input--with-left-icon {
      padding-left: 0;
    }

    .advanced-filters__name-search .sk-input__input {
      padding-left: 25px;
    }
  }
</style>

<style scoped>
  .advanced-filters__colored-section {
    position: relative;
    display: block;
    width: 100%;
    padding: 70px 20px 25px;
    background-color: #ecf9f9;
  }

  .advanced-filters__main-section {
    display: block;
    width: 100%;
    padding: 10px 20px 25px;
  }

  .advanced-filters__select {
    width: 100%;
    margin-bottom: 15px;
  }

  .advanced-filters__section-name {
    display: block;
    width: calc(100% - 45px);
    margin: 0;
    margin-bottom: 5px;
    font-size: 12px;
  }

  .advanced-filters__gender-section {
    display: flex;
    flex-wrap: wrap;
    width: 100%;
    margin-bottom: 12px;
  }

  .advanced-filters__toggler-section {
    display: flex;
    align-items: center;
    width: 100%;
    padding: 10px 0;
  }

  .advanced-filters__toggler-section .advanced-filters__section-name {
    margin-bottom: 0;
  }

  .advanced-filters__btns-section {
    display: flex;
    justify-content: flex-end;
    width: 100%;
    margin-top: 30px;
  }

  .advanced-filters__action-btn {
    width: auto;
    min-width: 100px;
    padding: 0 15px;
  }

  .advanced-filters__action-btn:first-of-type {
    margin-right: 20px;
  }

  @media (max-width: 767px) {
    .advanced-filters {
      top: 45px;
      right: 0;
      bottom: 50px;
      overflow: auto;
      height: initial;
    }

    .advanced-filters__wrapper {
      position: static;
      width: 100%;
      height: 100%;
      max-height: initial;
    }
  }
</style>
