(<template>
  <post-section :class="{'in-progress': progress}"
                :title="customerInfoSectionTitle"
                class="buyer-info is-progress-bar">
    <div class="buyer-info__items-wrapper">
      <div v-for="(item, index) in items"
           :key="index"
           class="buyer-info__item">
        <div class="buyer-info__item-title-wrapper">
          <button v-if="item.showHelperIcon"
                  class="buyer-info__item-help-icon"
                  @click="item.onHelperClick"></button>
          <div :ref="item.ref"
               :class="{'hasIcon': item.showHelperIcon}"
               class="buyer-info__item-title">
            <p v-html="item.text"></p>
          </div>
        </div>
        <component :is="item.componentName"
                   v-if="item.componentName"
                   :key="index"
                   :ref="item.ref"
                   :no-description="true"
                   :preselected-value="item.value"
                   :class="item.classes"
                   :default-value="item.defaultValue"
                   :placeholder="item.text"
                   :validation-name="item.errorsName"
                   :text="item.message"
                   class="buyer-info__item-input"
                   @focus="item.onFocus ? item.onFocus() : null"
                   @fieldvaluechanged="item.onClick" />
        <department-attenders-section v-else-if="item.isSection"
                                      :params="item.params"
                                      :class="item.classes" />
        <div v-else
             :class="{'is-with-error': (item.errors && item.errors.length)}">
          <div class="buyer-info__item-text"
               :class="item.classes"
               v-html="item.value"></div>
          <div v-if="item.errors && item.errors.length"
               class="sk-input__errors-cont">
            <span v-for="(error, errorIndex) in item.errors"
                  :key="errorIndex"
                  v-html="(item.errors.length === 1) ? error : `${errorIndex + 1}. ${error}`"></span>
          </div>
        </div>
      </div>
      <sk-note :text="messageText"
               type="white"
               class="buyer-info__note" />
    </div>
  </post-section>
</template>)

<script>
  import {mapGetters, mapMutations, mapState} from 'vuex';
  import PostingValidation from '@/prototypes/PostingValidation';
  import PostSection from '@/components/booking_components/PostSection';
  import DepartmentAttendersSection from '@/components/booking_components/parts/DepartmentAttendersSection';

  export default {
    components: {
      'post-section': PostSection,
      'department-attenders-section': DepartmentAttendersSection
    },
    extends: PostingValidation,
    props: {
      title: {
        type: String,
        default: ''
      },
      progress: Boolean
    },
    computed: {
      ...mapState('PostingStore', {
        ownerRealName: ({OwnerRealNameStore}) => OwnerRealNameStore.ownerRealName || '',
        // errors
        departmentErrors: ({DepartmentAttendersStore}) => DepartmentAttendersStore.errors.department.errors,
        ownerRealNameErrorName: ({OwnerRealNameStore}) => OwnerRealNameStore.errors.ownerRealName.name
      }),
      ...mapState('PostAssignmentHelperStore', {
        isHelperVisible: ({isHelperVisible}) => isHelperVisible || false
      }),
      ...mapGetters('PostingStore/BookerStore', ['bookerData']),
      ...mapGetters('PostingStore/DepartmentAttendersStore', ['currentDepartment']),
      ...mapGetters('PostingNotifierStore', [
        'showOwnerRealNameSection',
        'showClientSection'
      ]),
      ...mapGetters('UserInfoStore', [
        'userIsEnterpriseMember',
        'isEmailVerificationRequired'
      ]),
      isAnyNameInput() { return this.showOwnerRealNameSection || this.showClientSection; },
      customerInfoSectionTitle() {
        const btnText = this.$gettext('Edit');
        const buttonEdit = (this.isAnyNameInput || !this.isEmailVerificationRequired) ? `<button class="sk-btn sk-btn--white sk-btn--rounded buyer-info__btn info-edit-btn" key="info-edit-btn" type="button">${btnText}</button>` : '';

        return `${this.title}${buttonEdit}`;
      },
      bookerContacts() {
        const itemsArr = [];

        if (this.bookerData?.email) {
          itemsArr.push(`<a class="js-copy-to-clipboard" href="mailto:${this.bookerData?.email}">${this.bookerData?.email}</a>`);
        }
        if (this.bookerData?.phoneCode && this.bookerData?.phoneNumber) {
          const phone = `${this.bookerData?.phoneCode}${this.bookerData?.phoneNumber}`;

          itemsArr.push(`<a class="js-copy-to-clipboard" href="tel:${phone}">${phone}</a>`);
        }

        return itemsArr.join(', ');
      },
      bookerDepartmentsLength() { return this.bookerData?.departments.length; },
      showDepartment() {
        return this.currentDepartment.name
          && this.bookerDepartmentsLength == 1;
      },
      messageDepartmentHint() {
        return this.bookerDepartmentsLength >= 2
          ? this.$gettext('Please choose which department you are booking for. If you primarily book only for one department, you can remove the other one so you don\'t have to choose every time.')
          : this.$gettext('Add yourself to a department to avoid having to choose every time you post an assignment.');
      },
      messageText() {
        return this.$gettext('You can add other contacts below using the CC option.');
      },
      bookerName() {
        return `${this.bookerData?.firstName} ${this.bookerData?.lastName}`;
      },
      itemsList() {
        return [
          {
            condition: this.showOwnerRealNameSection,
            ref: 'realName',
            text: this.$gettext('Real name:'),
            value: this.ownerRealName,
            componentName: 'sk-input',
            defaultValue: '-',
            errorsName: this.ownerRealNameErrorName,
            onFocus: () => {
              this.setContext('interpretation');
              this.setPopoverReference(this.$refs.realName);
              this.openHelper('realName');
            },
            onClick: (value) => {
              this.setOwnerRealName(value);
            }
          },
          {
            condition: !this.showOwnerRealNameSection,
            ref: 'bookerName',
            text: this.$gettext('Booker name:'),
            value: this.bookerName,
            showHelperIcon: this.isHelperVisible,
            onHelperClick: () => {
              this.setContext('interpretation');
              this.setPopoverReference(this.$refs.bookerName);
              this.openHelper('bookerName');
            }
          },
          {
            condition: this.bookerContacts,
            ref: 'contactInfo',
            text: this.$gettext('Contact info:'),
            value: this.bookerContacts,
            showHelperIcon: this.isHelperVisible,
            onHelperClick: () => {
              this.setContext('interpretation');
              this.setPopoverReference(this.$refs.contactInfo);
              this.openHelper('contactInfo');
            }
          },
          {
            condition: this.showDepartment,
            ref: 'department',
            text: this.$gettext('Department:'),
            value: this.currentDepartment.name,
            showHelperIcon: this.isHelperVisible,
            onHelperClick: () => {
              this.setContext('interpretation');
              this.setPopoverReference(this.$refs.department);
              this.openHelper('department');
            }
          },
          {
            condition: !this.showDepartment && this.userIsEnterpriseMember,
            text: this.$gettext('Department:'),
            params: {
              showEditDepartment: this.bookerDepartmentsLength,
              hideContactPerson: true,
              leftAlign: true,
              departmentDescription: '',
              showAssignmentHelper: true
            },
            classes: ['buyer-info__item-select'],
            isSection: true
          },
          {
            condition: !this.showDepartment && this.userIsEnterpriseMember,
            componentName: 'sk-note',
            message: this.messageDepartmentHint,
            classes: ['buyer-info__item-note'],
            onClick: () => {}
          }
        ];
      },
      items() {
        return this.itemsList.filter((item) => item.condition);
      }
    },
    watch: {
      customerInfoSectionTitle() {
        setTimeout(this.addEditHandler, 100);
      }
    },
    methods: {
      ...mapMutations('PostingStore/OwnerRealNameStore', [
        'setOwnerRealName',
      ]),
      ...mapMutations('PostAssignmentHelperStore', [
        'openHelper',
        'setContext',
        'setPopoverReference'
      ]),
      openEditModal() {
        this.$store.commit('ModalStore/setModal', {
          component: 'assignment-info-edit-modal',
          classes: ['sk-modal--new'],
          data: {
            sectionName: ['personalInformationSections'],
            validateFuncName: 'validatePersonalInfoSection',
            sendInfoFunc: this.sendInfoFunc,
            successHandlingFunc: this.successHandlingFunc,
            submitButtonText: this.$gettext('Confirm changes'),
            openEditFunc: this.openEditModal
          }
        });
      },
      sendInfoFunc() {
        return Promise.resolve();
      },
      successHandlingFunc() {
        this.$store.dispatch('ModalStore/closeModal');
      },
      addEditHandler() {
        const linkElement = document.querySelector('.info-edit-btn');
        if (linkElement) {
          linkElement.addEventListener('click', this.openEditModal);
        }
      }
    },
    mounted() {
      this.addEditHandler();
    }
  };
</script>

<style>
  .buyer-info__item-input .sk-input__input {
    height: 25px;
    font-size: 12px;
    line-height: 25px;
  }

  .buyer-info .post-section__title {
    display: flex;
    justify-content: space-between;
  }

  .buyer-info .buyer-info__btn {
    width: auto;
    min-width: 50px;
    padding: 0 10px;
  }
</style>

<style scoped>
  .buyer-info__items-wrapper {
    display: flex;
    flex-direction: column;
    margin: -5px 0;
  }

  .buyer-info__item {
    display: flex;
    margin: 5px 0;
  }

  .buyer-info__item-help-icon {
    width: 20px;
    height: 20px;
    background-image: url(~@assets/imgs/undefined_imgs/question_mark_icon.svg);
    background-position: 50% 50%;
    background-size: 100% auto;
    background-repeat: no-repeat;
  }

  .buyer-info__item-help-icon:focus {
    box-shadow: inset 0 0 0 2px #3af;
  }

  .buyer-info__item-title-wrapper {
    display: inline-flex;
    gap: 8px;
    align-items: center;
  }

  .buyer-info__item-title {
    width: 160px;
    min-width: 160px;
    padding-right: 10px;
    font-weight: bold;
    line-height: 1.14;
  }

  .buyer-info__item-title.hasIcon {
    width: 132px;
    min-width: 132px;
    word-break: break-word;
  }

  .buyer-info__item-input {
    max-width: 300px;
    margin-bottom: 0;
  }

  .buyer-info__item-select {
    display: flex;
    flex-direction: column;
    max-width: 100%;
  }

  .buyer-info__item-note {
    max-width: 400px;
  }

  .buyer-info__item-text {
    max-width: 300px;
    color: #7f7f7f;
  }

  .buyer-info__note {
    margin-top: 20px;
    border-top: 1px solid #d3d5de;
  }

  @media (max-width: 767px) {
    .buyer-info__item {
      flex-wrap: wrap;
    }

    .buyer-info__item-title {
      width: 100%;
    }

    .buyer-info__item-help-icon {
      display: none;
    }
  }

  @media (max-width: 550px) {
    .buyer-info__item-input {
      max-width: none;
    }
  }
</style>
