(<template>
  <section class="add-travel-expense">
    <sk-select :default-value="typeText"
               :description="typeText"
               :items-list="expensesTypes"
               :preselected-value="expenseType"
               :validation-name="expenseTypeValidationName"
               class="add-travel-expense__select"
               @csvaluechanged="setExpenseType" />
    <p v-if="expenseType"
       class="sk-note add-travel-expense__select-note">{{ expenseWarningText }}</p>
    <div class="add-travel-expense__input-wrapper">
      <p class="sk-input__description add-travel-expense__input-description">{{ expenseAmountText }}</p>
      <sk-input :placeholder="'0'"
                :preselected-value="expenseAmount"
                :reg-exp="/[^0-9]/g"
                :mob-numeric-keyboard="true"
                :validation-name="expenseAmountValidationName"
                :disabled="noRefundWarning"
                class="add-travel-expense__input"
                @fieldvaluechanged="setExpenseAmount" />
      <div class="add-travel-expense__input-text">{{ currency }}</div>
    </div>
    <sk-upload-file :id="'receiptFile'"
                    :button-text="receiptFileText"
                    :is-single="true"
                    :reset="fileInputReset"
                    class="add-travel-expense__upload-btn"
                    @changefileinput="catchReceipt" />
    <div v-if="receiptFile"
         class="add-travel-expense__file">
      <p class="add-travel-expense__file-name">{{ receiptFile.name }}</p>
      <button type="button"
              class="add-travel-expense__remove-btn"
              @click="removeReceipt"></button>
    </div>
    <div class="add-travel-expense__wrapper">
      <div v-if="!isAdminMode"
           class="add-travel-expense__description"
           v-html="travelAllowanceDescription"></div>
      <template v-else>
        <div class="add-travel-expense__input-wrapper">
          <p class="sk-input__description add-travel-expense__input-description">{{ demanderApprovedAmountText }}</p>
          <sk-input :placeholder="'0'"
                    :preselected-value="demanderApprovedAmount"
                    :reg-exp="/[^0-9]/g"
                    :mob-numeric-keyboard="true"
                    class="add-travel-expense__input"
                    @fieldvaluechanged="setExpenseDemanderApprovedAmount" />
          <div class="add-travel-expense__input-text">{{ currency }}</div>
        </div>
        <div class="add-travel-expense__input-wrapper">
          <p class="sk-input__description add-travel-expense__input-description">{{ supplierApprovedAmountText }}</p>
          <sk-input :placeholder="'0'"
                    :preselected-value="supplierApprovedAmount"
                    :reg-exp="/[^0-9]/g"
                    :mob-numeric-keyboard="true"
                    class="add-travel-expense__input"
                    @fieldvaluechanged="setExpenseSupplierApprovedAmount" />
          <div class="add-travel-expense__input-text">{{ currency }}</div>
        </div>
      </template>
    </div>
    <sk-note :text="maxAllowanceAmountText"
             class="add-travel-expense__note" />
    <div class="add-travel-expense__btn-cont">
      <button class="sk-btn sk-btn--white add-travel-expense__btn add-travel-expense__btn--cancel"
              @click="closeModal">{{ $gettext('Cancel') }}</button>
      <button class="sk-btn sk-btn--default add-travel-expense__btn add-travel-expense__btn--edit"
              @click="submitExpense">{{ $gettext('Add') }}</button>
    </div>
  </section>
</template>)

<script>
  import helpers from '@/helpers';
  import constants from '@/modules/constants';
  import {mapActions, mapGetters, mapMutations, mapState} from 'vuex';

  export default {
    props: {
      data: {
        type: Object,
        default: () => {
          return {};
        }
      }
    },
    data() {
      return {
        fileInputReset: false
      };
    },
    computed: {
      ...mapGetters('UserInfoStore', [
        'userCurrency'
      ]),
      ...mapState('TravelExpensesStore', {
        expenseType: ({expense}) => expense.expenseType,
        expenseAmount: ({expense}) => expense.expenseAmount,
        receiptFile: ({expense}) => expense.receiptFile,
        supplierApprovedAmount: ({expense}) => expense.supplierApprovedAmount,
        demanderApprovedAmount: ({expense}) => expense.demanderApprovedAmount,
        expenseTypeValidationName: ({errors}) => errors.expenseType.name,
        expenseAmountValidationName: ({errors}) => errors.expenseAmount.name,
        expenseTypeErrors: ({errors}) => errors.expenseType.errors,
        expenseAmountErrors: ({errors}) => errors.expenseAmount.errors
      }),
      ...mapState('OneAssignmentStore', {
        jobId: ({storeJobObj}) => storeJobObj.id || '',
        discussionId: ({chatDiscussions}) => chatDiscussions.discussion?.id || '',
        expenseSettings: ({storeJobObj}) => storeJobObj.expenseSettings || {}
      }),
      currency() {
        return this.userCurrency?.name || 'NOK';
      },
      isAdminMode() {
        return !!this.data.adminMode;
      },
      maxAllowanceAmount() {
        return this.data.maxAllowanceAmount;
      },
      maxAllowanceAmountText() {
        const template = this.$gettext('This customer pays a maximum of <b>%{amount} %{currency}</b> for costs');

        return this.$gettextInterpolate(template, {
          amount: this.maxAllowanceAmount,
          currency: this.currency
        });
      },
      currentExpenseId() {
        return this.data.expense?.id || '';
      },
      typeText() { return this.$gettext('Expense type'); },
      expenseAmountText() { return this.$gettext('Amount'); },
      receiptFileText() { return this.$gettext('Upload receipt'); },
      demanderApprovedAmountText() {
        return this.$gettext('The customer pays');
      },
      supplierApprovedAmountText() {
        return this.$gettext('The interpreter gets paid');
      },
      selectedExpenseTypeObj() {
        return this.expenseSettings[this.expenseType] || {};
      },
      selectedExpenseMaxAmount() {
        return this.selectedExpenseTypeObj.max;
      },
      noRefundWarning() {
        return !!(this.expenseType && !Number(this.selectedExpenseMaxAmount));
      },
      expenseWarningText() {
        const expenseType = helpers.getJobInfo.getTravelExpensesName(this, this.expenseType);
        const template = this.noRefundWarning
          ? this.$gettext('This customer does not provide refunds for: %{expenseType}')
          : this.$gettext('Maximum refundable %{expenseType} costs: %{amount}%{currency}');

        return this.$gettextInterpolate(template, {
          expenseType,
          amount: +this.selectedExpenseMaxAmount,
          currency: this.currency
        });
      },
      travelAllowanceDescription() {
        return this.$gettext('Unreasonable ticket prices will not be refunded. We retain the right to ask for receipts as proof. For rules regarding cost of diet please <a class="sk-link sk-link--gray-bg" target="_blank" href="https://arbeidsgiver.difi.no/lonn-goder-og-reise/reise/statens-satser-innenlands">click here</a>');
      },
      expensesTypes() {
        return helpers.getJobInfo.getTravelExpensesTypes(this);
      }
    },
    methods: {
      ...mapActions('TravelExpensesStore', [
        'createForm',
        'sendTravelExpense',
        'updateTravelExpense'
      ]),
      ...mapMutations('TravelExpensesStore', [
        'setError',
        'removeErrors',
        'clearModalData',
        'setExpenseType',
        'setExpenseAmount',
        'setReceiptFile',
        'setExpenseDemanderApprovedAmount',
        'setExpenseSupplierApprovedAmount',
        'removeReceiptFile'
      ]),
      removeReceipt() {
        this.fileInputReset = true;
        this.removeReceiptFile();
      },
      catchReceipt(target) {
        this.$store.commit('ModalStore/removeModalErrors');
        if (target.files && target.files.length) {
          if (target.files[0].size > constants.MAX_FILE_SIZE) {
            return this.$store.commit('ModalStore/setModalErrors', this.$gettext('File size can\'t be more than 5 megabytes.'));
          }
          this.setReceiptFile(target.files[0]);
          this.fileInputReset = !this.fileInputReset;
        }
      },
      closeModal() {
        this.$store.dispatch('ModalStore/closeModal');
      },
      validateForm() {
        let isValidForm = true;
        const maxExpenseAmount = +this.selectedExpenseMaxAmount;
        const isReceiptRequired = this.selectedExpenseTypeObj.receipt;

        this.removeErrors();
        this.$store.commit('ModalStore/removeModalErrors');
        if (!this.expenseType) {
          isValidForm = false;
          this.setError({
            fieldName: 'expenseType',
            errorText: this.$gettext('Expense type is required')
          });
        }
        if (!this.noRefundWarning) {
          if (!this.expenseAmount) {
            isValidForm = false;
            this.setError({
              fieldName: 'expenseAmount',
              errorText: this.$gettext('Amount is required')
            });
          }
          if (+this.expenseAmount > maxExpenseAmount) {
            isValidForm = false;
            this.setError({
              fieldName: 'expenseAmount',
              errorText: this.$gettextInterpolate(this.$gettext('Maximum expense for selected type is %{maxExpense}'), {
                maxExpense: maxExpenseAmount
              })
            });
          }
        }
        if (isReceiptRequired && !this.receiptFile) {
          isValidForm = false;
          this.$store.commit('ModalStore/setModalErrors', this.$gettext('Receipt file is required.'));
        }
        this.$store.commit('ErrorsStore/setError', {name: this.expenseTypeValidationName, errors: this.expenseTypeErrors});
        this.$store.commit('ErrorsStore/setError', {name: this.expenseAmountValidationName, errors: this.expenseAmountErrors});

        return isValidForm;
      },
      reloadPage() {
        window.location.reload();
      },
      submitExpense() {
        if (this.validateForm()) {
          this.$store.commit('ModalStore/startModalProgress');
          if (this.noRefundWarning) {
            this.setExpenseAmount(0);
          }
          this.createForm(this.isAdminMode)
            .then((form) => {
              const expenseId = this.currentExpenseId;
              const params = {
                form,
                expenseId,
                jobId: this.jobId,
                discussionId: this.discussionId
              };
              const submitAction = expenseId ? 'updateTravelExpense' : 'sendTravelExpense';

              this[submitAction](params)
                .then(() => {
                  this.$store.commit('ModalStore/stopModalProgress');
                  this.closeModal();
                })
                .catch((error) => {
                  this.handleErrors(error);
                });
            });
        }
      },
      handleErrors(error) {
        this.$store.commit('ModalStore/stopModalProgress');
        const errors = error?.errors;
        if (errors?.includes('Receipt content type er ugyldig, Receipt er ugyldig')) {
          this.$store.commit('ModalStore/setModalErrors', this.$gettext('Invalid file type'));
        } else if (errors?.includes('Job locked')) {
          this.closeModal();
          setTimeout(() => {
            this.$store.commit('InfoModalStore/setInfoModal', {
              text: this.$gettext('This assignment has been locked and is now being handled by our finance team.'),
              method: 'reloadPage',
              context: this
            });
          });
        } else {
          this.closeModal();
          setTimeout(() => {
            this.$store.commit('ModalStore/setModal', {
              component: 'error-modal',
              data: {
                error
              }
            });
          });
        }
      },
      preselectEditValues() {
        const expense = this.data.expense;

        if (expense) {
          this.setExpenseType(expense.expenseType || '');
          this.setExpenseAmount(expense.amount || '');
          this.setExpenseDemanderApprovedAmount(expense.demanderApprovedAmount || '');
          this.setExpenseSupplierApprovedAmount(expense.supplierApprovedAmount || '');
          if (expense.fileName) {
            this.setReceiptFile({
              name: expense.fileName
            });
          }
        }
      },
      clearErrors() {
        this.removeErrors();
        this.$store.commit('ModalStore/removeModalErrors');
        this.$store.commit('ErrorsStore/setError', {name: this.expenseTypeValidationName, errors: this.expenseTypeErrors});
        this.$store.commit('ErrorsStore/setError', {name: this.expenseAmountValidationName, errors: this.expenseAmountErrors});
      }
    },
    beforeMount() {
      this.clearModalData();
      this.clearErrors();
    },
    mounted() {
      this.preselectEditValues();
    }
  };
</script>

<style>
  .add-travel-expense__select--up .sk-select.is-opened .sk-select__list {
    top: -2px;
    max-height: 200px;
    transform: translateY(-100%);
  }
</style>

<style scoped>
  .add-travel-expense {
    display: block;
    width: 100%;
    padding: 20px 30px;
  }

  .add-travel-expense__input-wrapper {
    display: flex;
    flex-wrap: wrap;
    width: 100%;
  }

  .add-travel-expense__select {
    margin-bottom: 20px;
  }

  .add-travel-expense__input {
    width: 65px;
  }

  .add-travel-expense__input-description {
    width: 100%;
  }

  .add-travel-expense__input-text {
    display: flex;
    align-items: center;
    height: 35px;
    margin-left: 5px;
  }

  .add-travel-expense__select-note {
    margin-top: -15px;
  }

  .add-travel-expense__wrapper {
    margin-top: 20px;
    margin-bottom: 20px;
  }

  .add-travel-expense__file {
    position: relative;
    display: flex;
    align-items: center;
    width: 100%;
    height: 30px;
    margin-top: 10px;
    padding-left: 25px;
  }

  .add-travel-expense__remove-btn {
    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
    display: block;
    width: 15px;
    background-image: url(~@assets/imgs/undefined_imgs/trash_ico.svg);
    background-position: 50% 50%;
    background-size: 12px auto;
    background-repeat: no-repeat;
  }

  .add-travel-expense__remove-btn:active {
    background-color: #e1d7eb;
  }

  .add-travel-expense__btn-cont {
    display: flex;
    justify-content: flex-end;
    margin-top: 20px;
  }

  .add-travel-expense__btn {
    display: inline-block;
    width: 100%;
    max-width: 80px;
  }

  .add-travel-expense__btn--cancel {
    flex-shrink: 0;
  }

  .add-travel-expense__btn--edit {
    width: 100%;
    margin-left: 15px;
  }
</style>
