(<template>
  <article class="cancel-modal">
    <p v-if="assignmentIsAccepted"
       class="cancel-modal__note">{{ cancelNoteText }}</p>
    <div class="cancel-modal__checkboxes-cont">
      <h2 class="cancel-modal__checkboxes-title">{{ title }}</h2>
      <sk-radio v-for="value in reasonCheckboxes"
                :key="value.value"
                :item-text="value.text"
                :item-value="value.value"
                :preselected-value="chosenRadio"
                :item-name="'reason'"
                class="cancel-modal__radio"
                @radiochanged="changeReason" />
      <sk-textarea v-if="showField"
                   :placeholder="reasonTextareaText"
                   :preselected-value="reasonText"
                   class="cancel-modal__reason-field"
                   @fieldvaluechanged="catchReasonText" />
      <p class="cancel-modal__charge-note">{{ chargeNoteText }}</p>
      <sk-checkbox v-if="notMyJob"
                   :preselected-value="acceptCancel"
                   :validation-name="errors.acceptCancel.name"
                   :item-text="notMyJobText"
                   class="cancel-modal__checkbox"
                   @checkboxvaluechanged="catchAcceptCancel" />
      <div v-if="isShared && userIsNotInterpreter"
           class="cancel-modal__reason-field">
        <div>{{ $gettext('You are using a shared account. Please provide your real name') }}</div>
        <sk-input :description="ownerRealNameText"
                  :preselected-value="ownerRealName"
                  :validation-name="errors.ownerRealName.name"
                  @fieldvaluechanged="catchOwnerRealName" />
      </div>
      <div v-if="$isGodmode()">
        <sk-datepicker :preselected-value="preselectedDate"
                       :description="dateText"
                       :full-calendar="allowPastBooking"
                       :default-value="'--/--/----'"
                       :prevent-call="true"
                       class="cancel-date__datepicker"
                       @changeday="changeCurrentDate" />
        <sk-timepicker :preselected-value="preselectedTime"
                       :auto-scroll="true"
                       :default-value="'--:--'"
                       :description="timeText"
                       :time-step="1"
                       :disabled="false"
                       :mob-numeric-keyboard="true"
                       class="cancel-date__timepicker"
                       @timepickervaluechanged="changeCurrentTime" />
      </div>
      <template v-if="$isGodmode()">
        <sk-note :text="adminNotes"
                 class="cancel-modal__notes" />
        <h2 class="cancel-modal__checkboxes-title">{{ $gettext('Admin reasons:') }}</h2>
        <sk-radio v-for="value in adminReasonCheckboxes"
                  :key="value.value"
                  :item-text="value.text"
                  :item-value="value.value"
                  :preselected-value="chosenRadio"
                  :item-name="'reason'"
                  class="cancel-modal__radio"
                  :validation-name="startTimeValidation"
                  @radiochanged="changeReason" />
        <sk-textarea v-if="showAdminField"
                     :placeholder="reasonTextareaText"
                     :preselected-value="reasonText"
                     class="cancel-modal__reason-field"
                     @fieldvaluechanged="catchReasonText" />
      </template>

    </div>
    <div class="cancel-modal__btn-cont">
      <button class="sk-btn sk-btn--white cancel-modal__btn"
              @click="closeModal">{{ $gettext('Back') }}</button>
      <button v-if="!$isGodmode()"
              :class="{'is-disabled': isDisabledButton}"
              class="sk-btn sk-btn--red cancel-modal__btn"
              @click="emitCancel">{{ $gettext('Confirm cancellation') }}</button>
      <template v-else>
        <button :class="{'is-disabled': isDisabledButton}"
                class="sk-btn sk-btn--orange cancel-modal__btn"
                @click="emitCancel({withNotifications: true})">{{ $gettext('Cancel and email customer') }}</button>
        <button :class="{'is-disabled': isDisabledButton}"
                class="sk-btn sk-btn--red cancel-modal__btn"
                @click="emitCancel({withNotifications: false})">{{ $gettext('Cancel without email to customer') }}</button>
      </template>
    </div>
  </article>
</template>)

<script>
  import {mapGetters, mapState, mapActions} from 'vuex';
  import helpers from '@/helpers';

  export default {
    props: {
      data: {
        type: Object,
        default() {
          return {};
        }
      }
    },
    data() {
      return {
        chosenRadio: '',
        reasonText: '',
        acceptCancel: false,
        ownerRealName: '',
        currentDate: '',
        timepeekerTime: '',
        errors: {
          acceptCancel: {name: 'acceptCancelValidation', errors: []},
          ownerRealName: {name: 'ownerRealNameValidation', errors: []}
        }
      };
    },
    computed: {
      ...mapGetters('UserInfoStore', [
        'userUid',
        'userIsNotInterpreter',
        'isShared'
      ]),
      ...mapState('OneAssignmentStore', {
        assignmentObj: ({storeJobObj}) => storeJobObj || {},
      }),
      ...mapState('PostingStore/DateTimeStore', {
        fullStartTime: (state) => state.startTime,
        fullFinishTime: (state) => state.finishTime,
        customDuration: (state) => state.customDuration,
        startTimeValidation: (state) => state.errors.startTime.name,
        finishTimeValidation: (state) => state.errors.finishTime.name
      }),
      jobStatus() { return this.assignmentObj.status || ''; },
      assignmentIsAccepted() { return this.jobStatus === 'accepted'; },
      assignmentRequirements() { return this.assignmentObj.interpretationRequirement || {}; },
      assignmentType() { return this.assignmentRequirements.sessionType || {}; },
      inPersonJob() { return this.assignmentType === 'in_person'; },
      ownerRealNameText() { return this.$gettext('Real name:'); },
      notMyJob() { return this.assignmentRequirements.bookerUid !== this.userUid; },
      title() {
        return this.userIsNotInterpreter
          ? this.$gettext('Please help us improve by telling us why:')
          : this.$gettext('User reasons:');
      },
      adminNotes() {
        return this.$gettext('<b>IMPORTANT: </b><br>Use a customer reason, if we should still invoice them.<br>Use an admin reason, if the job is free.');
      },
      maxShortNoticeCancellation() {
        const shortNoticeCancellation = this.data.data.maxShortNoticeCancellation;
        return this.getCancellationPolicyType(shortNoticeCancellation) || this.$gettextInterpolate(this.$gettext('%{shortNoticeCancellation} hour(s)'), {shortNoticeCancellation});
      },
      chargeNoteText() {
        const template = this.$gettext('*Note: The platform has a %{ maxShortNoticeCancellation } cancellation policy. We only charge you if an interpreter is confirmed and the assignment starts in less than %{ maxShortNoticeCancellation }.');
        return this.$gettextInterpolate(template, {maxShortNoticeCancellation: this.maxShortNoticeCancellation});
      },
      cancelNoteText() {
        const template = this.$gettext('Cancellations with over %{ maxShortNoticeCancellation } notice are free. Are you sure you wish to cancel the assignment?');
        return this.$gettextInterpolate(template, {maxShortNoticeCancellation: this.maxShortNoticeCancellation});
      },
      notMyJobText() {
        const template = this.$gettext('This booking was created by %{ ownerName }. I confirm that this should now be cancelled.');
        return this.$gettextInterpolate(template, {ownerName: this.data.data.ownerName});
      },
      reasonCheckboxes() {
        return [
          {value: 'dont_need_interpreter_anymore', text: this.$gettext('I don\'t need an interpreter anymore')},
          {value: 'need_to_rebook_session', text: this.$gettext('I need to rebook this session')},
          {value: 'found_interpreter_from_another_provider', text: this.$gettext('I have found an interpreter from another provider')},
          {value: 'other', text: this.$gettext('Other')}
        ];
      },
      adminReasonCheckboxesList() {
        return [
          {value: 'interpreters_busy', text: this.$gettext('All our interpreters are busy'), condition: true},
          {
            value: 'no_person_attending',
            text: this.$gettext('None of our interpreters are able to attend in-person. May we change this in-person assignment to phone?'),
            condition: this.inPersonJob
          },
          {
            value: 'withdrawn',
            text: this.$gettext('The interpreter that was assigned has withdrawn. May we change this in-person assignment to phone?'),
            condition: this.inPersonJob
          },
          {value: 'admin_other', text: this.$gettext('Other'), condition: true}
        ];
      },
      adminReasonIsChoosen() {
        if (this.chosenRadio === 'interpreters_busy' || this.chosenRadio === 'no_person_attending' || this.chosenRadio === 'withdrawn' || this.chosenRadio === 'admin_other') {
          return true;
        }
        return false;
      },
      adminReasonCheckboxes() { return this.adminReasonCheckboxesList.filter((reason) => reason.condition); },
      reasonTextareaText() { return this.$gettext('Write your reason here...'); },
      showField() { return this.chosenRadio === 'other'; },
      showAdminField() { return this.chosenRadio === 'admin_other'; },
      isDisabledButton() {
        return !this.acceptCancel;
      },
      preselectedDate() {
        const date = this.currentDate ? this.currentDate : new Date();
        return date ? this.$moment(date).format('YYYY-MM-DD') : '';
      },
      preselectedTime() {
        const time = this.timepeekerTime ? this.timepeekerTime : new Date();
        return time ? this.$moment(time).format('HH:mm') : '';
      },
      dateText() { return this.$gettext('Date'); },
      timeText() { return this.$gettext('Time'); },
      allowPastBooking() { return this.$isGodmode(); },

    },
    watch: {
      $route() {
        this.closeModal();
      }
    },
    methods: {
      ...mapActions('PostingStore/DateTimeStore', [
        'catchStartTime',
        'catchFinishTime',
        'catchAssignmentStartDate'
      ]),
      changeCurrentDate(value) {
        this.currentDate = value;
      },
      changeCurrentTime(value) {
        this.timepeekerTime = helpers.dateTime.setTime(this.$moment, {value});
      },
      getCancellationPolicyType(type) {
        switch (type) {
          case 'calendar_hours_12':
            return this.$gettext('12 hours before assignment');
          case 'calendar_hours_24':
            return this.$gettext('24 hours before assignment');
          case 'day_before_1400':
            return this.$gettext('14:00 on the previous calendar day');
          case 'day_before_1200':
            return this.$gettext('12:00 on the previous calendar day');
          case 'day_before_1500':
            return this.$gettext('15:00 on the previous calendar day');
          case 'working_days_3':
            return this.$gettext('3 working days before assignment');
          default:
            return this.$gettext('24 hours before assignment');
        }
      },
      changeReason(value) {
        this.reasonText = '';
        this.chosenRadio = value;
      },
      catchReasonText(value) {
        this.reasonText = value;
      },
      catchAcceptCancel(value) {
        this.removeErrors();
        this.acceptCancel = value;
      },
      catchOwnerRealName(value) {
        this.removeErrors();
        this.ownerRealName = value;
      },
      closeModal() {
        this.$emit('closemodal');
      },
      emitCancel(withNotifications) {
        if (this.validateForm()) {
          const reason = {
            reason: this.chosenRadio,
            date: this.preselectedDate,
            time: this.preselectedTime,
            other: false
          };
          if (this.showField) {
            reason.reason = this.reasonText;
            reason.other = true;
          }
          if (this.showAdminField) {
            reason.admin_other = this.reasonText;
            reason.other = true;
          }
          if (this.isShared) {
            reason.real_person_name = this.ownerRealName;
          }
          this.data.context.cancelAssignment(reason, withNotifications);
          this.closeModal();
        }
      },
      validateForm() {
        let isValidForm = true;

        this.removeErrors();
        if (!this.acceptCancel) {
          isValidForm = false;
          this.errors.acceptCancel.errors.push('required');
        }
        if (!this.ownerRealName.trim() && this.isShared && !this.adminReasonIsChoosen) {
          isValidForm = false;
          this.errors.ownerRealName.errors.push(this.$gettext('Real name is required.'));
        }
        this.$store.commit('ErrorsStore/setError', {name: this.errors.acceptCancel.name, errors: this.errors.acceptCancel.errors});
        this.$store.commit('ErrorsStore/setError', {name: this.errors.ownerRealName.name, errors: this.errors.ownerRealName.errors});

        return isValidForm;
      },
      removeErrors() {
        this.errors.acceptCancel.errors = [];
        this.errors.ownerRealName.errors = [];
      }
    },
    beforeMount() {
      this.acceptCancel = !this.notMyJob;
      this.currentTime = new Date();
    },
    beforeDestroy() {
      this.removeErrors();
      this.$store.commit('ErrorsStore/removeAllErrors');
    }
  };
</script>

<style scoped>
  .cancel-modal {
    display: block;
    width: 100%;
    padding: 20px 30px;
  }

  .cancel-date__datepicker {
    width: 110px;
    margin-top: 10px;
  }

  .cancel-date__timepicker {
    width: 75px;
    margin-top: 10px;
  }

  .cancel-modal__note {
    display: block;
    width: 100%;
    margin-bottom: 30px;
  }

  .cancel-modal__checkboxes-cont {
    display: block;
    width: 100%;
  }

  .cancel-modal__checkbox {
    margin: 20px 0 20px;
  }

  .cancel-modal__notes {
    margin-top: 20px;
    margin-bottom: 10px;
  }

  .cancel-modal__checkboxes-title {
    margin-bottom: 15px;
    font-weight: bold;
    font-size: 14px;
  }

  .cancel-modal__checkboxes-title:first-child {
    margin-top: 0;
  }

  .cancel-modal__radio {
    display: block;
    width: 100%;
    margin-top: 10px;
  }

  .cancel-modal__reason-field {
    height: 80px;
    margin-top: 10px;
  }

  .cancel-modal__charge-note {
    margin-top: 15px;
    font-size: 10px;
  }

  .cancel-modal__btn-cont {
    display: flex;
    flex-wrap: wrap;
    justify-content: flex-end;
    margin: 25px -5px 0;
  }

  .cancel-modal__btn {
    display: inline-block;
    width: auto;
    margin: 0 5px;
    padding: 0 10px;
  }

  @media (max-width: 400px) {
    .cancel-modal__btn-cont {
      flex-wrap: wrap;
    }

    .cancel-modal__btn {
      width: 100%;
      margin-bottom: 10px;
      margin-left: 0;
    }
  }
</style>
