(<template>
  <div class="note">
    <div class="note__wrapper"
         :class="[`note__wrapper--${type}`]">
      <div class="note__text"
           :class="[`note__text--${type}`]"
           v-html="text"></div>
      <slot></slot>
    </div>
  </div>
</template>)

<script>
  export default {
    props: {
      text: {
        type: String,
        default: ''
      },
      type: {
        type: String,
        default: ''
      }
    }
  };
</script>

<style scoped>
  .note__wrapper {
    position: relative;
    padding: 10px 10px 10px 35px;
    border-radius: 3px;
    background-color: #f0e8dd;
    font-size: 12px;
  }

  .note__wrapper--danger {
    background-color: rgba(255, 54, 36, 0.1);
  }

  .note__text::before {
    content: '';
    position: absolute;
    top: 12px;
    left: 10px;
    display: block;
    width: 15px;
    height: 15px;
    background-image: url(~@assets/imgs/undefined_imgs/info-ico.svg);
    background-position: 50% 50%;
    background-size: 15px auto;
    background-repeat: no-repeat;
  }

  .note__text--danger::before {
    background-image: url(~@assets/imgs/salita/hints/application/interpreter_withdrew.svg);
    background-size: 20px;
  }

  .note__text--danger {
    color: #ff3624;
  }

  .note__wrapper--white {
    background-color: #fff;
  }
</style>
