(<template>
  <article class="assign-reject">
    <p class="assign-reject__text"
       v-html="text1"></p>
    <!--<p class="assign-reject__text" v-html="text2"></p>-->
    <button class="sk-btn sk-btn--white assign-reject__btn"
            @click="sendDecline">{{ $gettext('Decline') }}</button>
    <!--<button class="sk-btn sk-btn--red assign-reject__btn" @click="sendReject">{{ $gettext('Reject') }}</button>-->
  </article>
</template>)

<script>
  export default {
    props: {
      data: {
        type: Object,
        default() {
          return {};
        }
      }
    },
    computed: {
      text1() {
        const intName = this.data.discussion ? this.data.discussion.supplierInfo.name : '';
        const template = this.$gettext('If you <b>Decline</b> this interpreter, %{ n } may want to re-apply. Please inform them if you would like them to re-apply with a different price.');
        return this.$gettextInterpolate(template, {n: intName});
      },
      text2() {
        const intName = this.data.discussion ? this.data.discussion.supplierInfo.name : '';
        const template = this.$gettext('<p>If you <b>Reject</b> this interpreter, %{ n } will not be able to reapply or send messages to you.</p>');
        return this.$gettextInterpolate(template, {n: intName});
      }
    },
    watch: {
      $route() {
        this.closeModal();
      }
    },
    methods: {
      closeModal() {
        this.$emit('closemodal');
      },
      sendReject() {
        this.data.context.makeReject();
        this.closeModal();
      },
      sendDecline() {
        this.data.context.makeDecline();
        this.closeModal();
      }
    }
  };
</script>

<style scoped>
  .assign-reject {
    display: block;
    width: 100%;
    padding: 20px 30px;
  }

  .assign-reject__text {
    padding-bottom: 20px;
    font-size: 14px;
  }

  .assign-reject__btn {
    display: inline-block;
    width: auto;
    margin-right: 30px;
    padding: 0 30px;
  }
</style>
