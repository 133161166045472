(<template>
  <section :class="{'post-inner-section--closed': closedState,
                    'post-inner-section--disabled': disabled}"
           class="post-inner-section"
           @focusin="$emit('focusin', true)">
    <h3 v-if="title"
        class="post-inner-section__title">{{ title }}</h3>
    <slot></slot>
  </section>
</template>)

<script>
  export default {
    props: {
      title: {
        type: String,
        default: ''
      },
      disabled: Boolean,
      closedState: Boolean
    }
  };
</script>

<style scoped>
  .post-inner-section {
    width: 100%;
    margin-bottom: 15px;
    padding: 20px 15px;
    background-color: #f4f5f7;
    transition: all 0.2s ease-in;
  }

  .post-inner-section--disabled {
    pointer-events: none;
  }

  .post-inner-section--closed {
    padding: 0 20px;
    background-color: inherit;
    transition: all 0.2s ease-in;
  }

  .post-inner-section__title {
    margin: 5px 0 20px;
    font-weight: bold;
    font-size: 12px;
    text-align: center;
  }

  @media (max-width: 767px) {
    .post-inner-section {
      margin-bottom: 0;
      padding: 15px;
    }
  }
</style>
