const apiPath = process.env.CORE_PATH;
const adminPath = process.env.ADMIN_PATH;
const pushApiPath = process.env.PUSH_PATH;
const samlPath = process.env.SAML_PATH;
const webSocketPath = process.env.WS_PATH;
const godmodeServerPath = process.env.GODMODE_SERVER_PATH;
const cookiePath = process.env.COOKIE_PATH;
const graphqlApiPath = `${apiPath}/graphql`;
const authApiPath = `${apiPath}/v1/accounts`;
const financeApiPath = `${apiPath}/v1/finances`;
const hamlAdminPath = `${adminPath}/admin`;
const googleApiKey = process.env.GOOGLE_KEY;
const showOpenBooking = process.env.SHOW_OPEN_BOOKING;
const interPricePDF = process.env.INTER_PRICE_PDF;
const buyerPricePDF = process.env.BUYER_PRICE_PDF;
const helpscoutKey = process.env.HELPSCOUT_KEY;
const supplierHelpscoutKey = process.env.SUPPLIER_HELPSCOUT_KEY;
const externalLoginPage = process.env.EXTERNAL_LOGIN_PAGE;
const feServerAPIAccessKey = process.env.FE_SERVER_API_ACCESS_KEY;
const iosAppLink = process.env.IOS_APP_LINK;
const androidAppLink = process.env.ANDROID_APP_LINK;

export default {
  // HEROKU AND FAKE_DEVELOPMENT VARIABLES
  API_PATH: apiPath,
  GRAPHQL_API_PATH: graphqlApiPath,
  ADMIN_PATH: adminPath,
  AUTH_API_PATH: authApiPath,
  PUSH_API_PATH: pushApiPath,
  SAML_PATH: samlPath,
  WEB_SOCKET_PATH: webSocketPath,
  FINANCE_API_PATH: financeApiPath,
  HAML_ADMIN_PATH: hamlAdminPath,
  COOKIE_PATH: cookiePath,
  GODMODE_SERVER_PATH: godmodeServerPath,
  EXTERNAL_LOGIN_PAGE: externalLoginPage,
  SHOW_OPEN_BOOKING: showOpenBooking,
  INTER_PRICE_PDF: interPricePDF,
  BUYER_PRICE_PDF: buyerPricePDF,
  // KEYS
  GOOGLE_KEY: googleApiKey,
  HELPSCOUT_KEY: helpscoutKey,
  SUPPLIER_HELPSCOUT_KEY: supplierHelpscoutKey,
  FE_SERVER_API_ACCESS_KEY: feServerAPIAccessKey,
  // LINKS
  IOS_APP_LINK: iosAppLink,
  ANDROID_APP_LINK: androidAppLink,
  // INTERNAL CONSTANTS
  MAX_FILE_SIZE: 5242880,
  TIMEPICKER_STEP: 5,
  COOKIE_EXPIRATION_TIME: 2592000,
  DOCUMENT_CENTER_MAX_FILE_SIZE: 20971520,
  DOCUMENT_CENTER_ALLOWED_FILE_TYPES: ['application/pdf', 'image/png', 'image/jpeg', 'text/csv', 'text/plain', 'application/pdf', 'application/rtf',
    'application/vnd.ms-excel', 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet', 'application/msword',
    'application/vnd.openxmlformats-officedocument.wordprocessingml.document']
};
