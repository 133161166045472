// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_GET_URL_IMPORT___ = require("../../../../../node_modules/css-loader/dist/runtime/getUrl.js");
var ___CSS_LOADER_URL_IMPORT_0___ = require("@assets/imgs/undefined_imgs/trash_ico.svg");
exports = ___CSS_LOADER_API_IMPORT___(false);
var ___CSS_LOADER_URL_REPLACEMENT_0___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_0___);
// Module
exports.push([module.id, "\n.send-mail__inner-section[data-v-7bc2bfcc] {\n  min-width: 430px;\n}\n.modal-class[data-v-7bc2bfcc] {\n  margin: 20px;\n}\n.address__radio[data-v-7bc2bfcc] {\n  margin: 10px 10px 10px 0;\n}\n.address-input__field[data-v-7bc2bfcc] {\n  margin-top: 10px;\n}\n.address-scope-select[data-v-7bc2bfcc] {\n  margin-bottom: 10px;\n}\n.cover-letter-text[data-v-7bc2bfcc] {\n  font-weight: bold;\n}\n.cover-letter-section[data-v-7bc2bfcc] {\n  display: -webkit-box;\n  display: -ms-flexbox;\n  display: flex;\n  -webkit-box-align: center;\n      -ms-flex-align: center;\n          align-items: center;\n  -webkit-box-pack: justify;\n      -ms-flex-pack: justify;\n          justify-content: space-between;\n  margin-top: 10px;\n  margin-bottom: 10px;\n}\n.cover-letter-section__button[data-v-7bc2bfcc] {\n  margin-left: 20px;\n}\n#cover-letter[data-v-7bc2bfcc] {\n  min-width: 190px;\n}\n.cover-letter-section__files[data-v-7bc2bfcc] {\n  position: relative;\n  display: -webkit-inline-box;\n  display: -ms-inline-flexbox;\n  display: inline-flex;\n  -webkit-box-pack: start;\n      -ms-flex-pack: start;\n          justify-content: flex-start;\n  width: 100%;\n  margin-top: 10px;\n}\n.cover-letter-section__remove-btn[data-v-7bc2bfcc] {\n  display: block;\n  width: 15px;\n  margin-right: 10px;\n  background-image: url(" + ___CSS_LOADER_URL_REPLACEMENT_0___ + ");\n  background-position: 50% 50%;\n  background-size: 12px auto;\n  background-repeat: no-repeat;\n}\n.cover-letter-section__remove-btn[data-v-7bc2bfcc]:active {\n  background-color: #e1d7eb;\n}\n", ""]);
// Exports
module.exports = exports;
