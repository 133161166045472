(<template>
  <section class="withdraw-as-supplier">
    <p class="withdraw-as-supplier__text">{{ $gettext('Are you sure you wish to withdraw this interpreter from this assignment?') }}</p>
    <div v-if="notesErrors.length"
         class="sk-input__errors-cont">
      <span v-for="(error, index) in notesErrors"
            :key="index"
            v-html="`${index + 1}. ${error}`"></span>
    </div>
    <div v-if="showRadio">
      <p class="withdraw-as-supplier__radio-title">{{ whoWithdrawsText }}</p>
      <sk-radio v-for="user in whoWithdrawsList"
                :key="user.value"
                :item-text="user.text"
                :item-value="user.value"
                :preselected-value="whoWithdraws"
                :item-name="whoWithdrawsText"
                class="withdraw-as-supplier__radio"
                @radiochanged="catchWhoWithdraws" />
    </div>
    <sk-select v-if="showCustomerReasonSelect"
               :items-list="salitaReasons"
               :default-value="pleaseSelectText"
               :description="salitaReasonsText"
               :preselected-value="salitasReason"
               class="withdraw-as-supplier__select"
               @csvaluechanged="catchSalitasReason" />
    <sk-select v-if="showInterpreterReasonSelect"
               :items-list="interpreterReasons"
               :default-value="pleaseSelectText"
               :description="salitaReasonsText"
               :preselected-value="interpreterReason"
               class="withdraw-as-supplier__select"
               @csvaluechanged="catchInterpretersReason" />
    <sk-textarea v-if="showExtraField"
                 :description="extraInfoDescriptionText"
                 :placeholder="extraInfoPlaceholderText"
                 :preselected-value="interpreterReasonText"
                 :validation-name="withdrawInput"
                 class="withdraw-as-supplier__textarea"
                 @fieldvaluechanged="catchInterpreterReasonText" />
    <sk-textarea v-for="note in notes"
                 :key="note.key"
                 :description="note.text"
                 :preselected-value="note.value"
                 :placeholder="note.text"
                 class="withdraw-as-supplier__textarea"
                 @fieldvaluechanged="note.setValue" />
    <button class="sk-btn sk-btn--red withdraw-as-supplier__btn"
            @click="catchWithdraw">{{ $gettext('Withdraw') }}</button>
  </section>
</template>)

<script>
  export default {
    props: {
      data: {
        type: Object,
        default() {
          return {};
        }
      }
    },
    data() {
      return {
        internalNote: '',
        notesErrors: [],
        salitasReason: '',
        interpreterReason: '',
        interpreterReasonText: '',
        whoWithdraws: '',
        withdrawInput: 'withdrawReason',
        withdrawErrors: [],
        domain: this.$getString('domainData', 'platformName')
      };
    },
    computed: {
      showNotes() { return this.data.showNotes || {}; },
      withNotifications() { return this.data.withNotifications; },
      notesList() {
        return [{
          condition: this.showNotes.showInternalNote,
          key: 'internal_note',
          value: this.internalNote,
          text: this.$gettext('Internal note'),
          setValue: (value) => { this.internalNote = value; }
        }];
      },
      notes() { return this.notesList.filter((note) => note.condition); },
      salitaReasons() {
        const duplicateJob = this.$gettextInterpolate(this.$gettext('%{domain} uses the interpreter in another assignment'), {domain: this.domain});
        const confirmedMistake = this.$gettextInterpolate(this.$gettext('%{domain} confirmed interpreter by mistake'), {domain: this.domain});
        const anotherInterpreter = this.$gettextInterpolate(this.$gettext('%{domain} will use a different interpreter on this assignment'), {domain: this.domain});
        return [
          {id: duplicateJob, name: duplicateJob},
          {id: confirmedMistake, name: confirmedMistake},
          {id: anotherInterpreter, name: anotherInterpreter}
        ];
      },
      interpreterReasons() {
        return [
          {id: this.$gettext('I have double booked myself'), name: this.$gettext('I have double booked myself')},
          {id: this.$gettext('I am sick'), name: this.$gettext('I am sick')},
          {id: this.$gettext('My kid is sick'), name: this.$gettext('My kid is sick')},
          {id: this.$gettext('I am not suitable for this assignment'), name: this.$gettext('I am not suitable for this assignment')},
          {id: this.$gettext('Other'), name: this.$gettext('Other')}
        ];
      },
      pleaseSelectText() { return this.$gettext('Please select'); },
      extraInfoDescriptionText() { return this.$gettext('Extra information'); },
      extraInfoPlaceholderText() { return this.$gettext('Write here...'); },
      salitaReasonsText() {
        return this.$gettextInterpolate(this.$gettext('%{domain} reason'), {domain: this.domain});
      },
      whoWithdrawsList() {
        const valueName = this.$gettextInterpolate(this.$gettext('%{domain}'), {domain: this.domain});
        return [
          {value: valueName, text: 'Salita'},
          {value: 'Interpreter', text: this.$gettext('Interpreter')}
        ];
      },
      whoWithdrawsText() { return this.$gettext('Who withdraws'); },
      showCustomerReasonSelect() {
        return this.showNotes.showCustomerNote && this.whoWithdraws !== 'Interpreter';
      },
      showInterpreterReasonSelect() {
        return this.showNotes.showCustomerNote && this.whoWithdraws === 'Interpreter';
      },
      showExtraField() { return this.interpreterReason === 'Other'; },
      showRadio() { return this.showNotes.showCustomerNote; }
    },
    methods: {
      validateForm() {
        let isValidForm = true;
        const {showSupplierNote, showCustomerNote} = this.showNotes;
        const customerNoteIsInvalid = showCustomerNote && this.whoWithdraws === 'Salita' && !this.salitasReason;
        const interpreterNoteIsInvalid = showSupplierNote && this.whoWithdraws === 'Interpreter' && !this.interpreterReason;
        const radioIsInvalid = (showCustomerNote || showSupplierNote) && !this.whoWithdraws;

        this.notesErrors = [];
        if (radioIsInvalid) {
          isValidForm = false;
          this.notesErrors.push(this.$gettext('Please select who withdraws'));
        }
        if (customerNoteIsInvalid || interpreterNoteIsInvalid) {
          isValidForm = false;
          this.notesErrors.push(this.$gettext('Please select reason'));
        }
        if (!this.interpreterReasonText.trim() && this.showExtraField) {
          isValidForm = false;
          this.withdrawErrors.push(this.$gettext('Please provide a reason'));
          this.$store.commit('ErrorsStore/setError', {name: this.withdrawInput, errors: this.withdrawErrors});
        }

        return isValidForm;
      },
      catchWithdraw() {
        if (this.validateForm()) {
          const interpreterReasonValue = this.interpreterReason === 'Other'
            ? this.interpreterReasonText
            : this.interpreterReason;

          const notesParams = {
            customer_note: this.salitasReason,
            supplier_note: interpreterReasonValue,
            internal_note: this.internalNote
          };
          this.$emit('closemodal');
          this.data.context.catchWithdrawAsSupplier(this.withNotifications, notesParams);
        }
      },
      catchSalitasReason(value) {
        this.salitasReason = value;
      },
      catchInterpretersReason(value) {
        this.interpreterReason = value;
      },
      catchWhoWithdraws(value) {
        this.whoWithdraws = value;
      },
      catchInterpreterReasonText(value) {
        this.interpreterReasonText = value;
      }
    }
  };
</script>

<style scoped>
  .withdraw-as-supplier {
    padding: 20px 30px 30px;
  }

  .withdraw-as-supplier__text {
    padding-bottom: 15px;
  }

  .withdraw-as-supplier__textarea {
    height: 100px;
  }

  .withdraw-as-supplier__btn {
    margin-top: 30px;
  }

  .withdraw-as-supplier__select {
    margin: 10px 0;
  }

  .withdraw-as-supplier__radio {
    margin: 10px 10px 10px 0;
  }
</style>
