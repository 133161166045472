(<template>
  <div ref="interpretersBlock"
       class="inter-list">
    <template v-if="!isCompact">
      <accordion ref="faiAccordion"
                 @statechanged="accordionStateChanged">
        <div slot="header"
             class="default-accordion__header inter-list__header">
          <p class="inter-list__header-text">{{ $gettext('Invite interpreters') }}</p>
        </div>
        <template slot="content">
          <div class="default-accordion__content inter-list__content">
            <div :class="{'inter-list__inter-cont--direct-invite': showDirectInvite,
                          'in-progress': progressActive}"
                 class="inter-list__inter-cont is-progress-bar">
              <template v-if="!progressActive || suitableInterpretersList.length">
                <persons v-if="showRegularSuitableSuppliersBlock"
                         :interpreters-list="suitableInterpretersList"
                         :show-auto-invite="!isDirectJob"
                         @invite="sendInvite"
                         @applyasinterpreter="applyAsInterpreter"
                         @declineinvite="declineInvite"
                         @refetchassignment="refetchAssignment"
                         @blockinterpreter="blockInterpreter" />
                <persons-for-admin v-else
                                   :interpreters-list="suitableInterpretersList"
                                   @invite="sendInvite"
                                   @applyasinterpreter="applyAsInterpreter"
                                   @declineinvite="declineInvite"
                                   @filterinterpreters="fetchInterpreters"
                                   @searchinterpreters="searchInterpreters"
                                   @loginasinterpreter="loginAsInterpreter"
                                   @changeinterpreterrating="changeInterpreterRating"
                                   @blockinterpreter="openBlockInterpreterModal" />
                <div v-if="showDirectInvite"
                     class="inter-list__manual-invite">
                  <p v-if="showCancelDirectInviteNote"
                     class="inter-list__manual-invite-text">{{ $gettext('If your chosen interpreter is unable to take this assignment, then as per your instructions - this assignment will be cancelled.') }}</p>
                  <template v-else>
                    <p class="inter-list__manual-invite-text">{{ $gettext('If your preferred interpreter doesn\'t apply, you might consider inviting other interpreters to this assignment.') }}</p>
                    <button class="sk-btn sk-btn--orange inter-list__manual-invite-btn"
                            @click="inviteOtherInterpreters">{{ $gettext('Invite other interpreters') }}</button>
                  </template>
                </div>
                <sk-pagination v-if="showPagination"
                               :current="+currentPage"
                               :total="+totalPage"
                               class="inter-list__pagination"
                               @prev="toPrevPage"
                               @next="toNextPage"
                               @newpage="toNewPage" />
              </template>
            </div>
            <div v-if="!isDirectJob && userCanUseChat && !$isGodmode()"
                 class="inter-list__info-wrapper">
              <div class="inter-list__info">
                <p class="inter-list__info-title">{{ $gettext('At the platform, we contact interpreters based on your favorites and their qualifications.') }}</p>
                <p>{{ $gettext('1. We will first contact your favorite highest qualified interpreter; and then automatically invite interpreters one by one.') }}</p>
                <p>{{ $gettext('2. OPTIONAL: You can invite more interpreters to your assignment yourself as well! But the system can work on its own too.') }}</p>
                <p>{{ $gettext('3. Once an interpreter confirms, we will send confirmation details.') }}</p>
              </div>
            </div>
          </div>
        </template>
      </accordion>
    </template>
    <template v-else>
      <div :class="{'in-progress': progressActive}"
           class="is-progress-bar">
        <persons-for-admin
          :interpreters-list="suitableInterpretersList"
          :is-compact="isCompact"
          @invite="sendInvite"
          @applyasinterpreter="applyAsInterpreter"
          @declineinvite="declineInvite"
          @filterinterpreters="fetchInterpreters"
          @searchinterpreters="searchInterpreters"
          @loginasinterpreter="loginAsInterpreter"
          @changeinterpreterrating="changeInterpreterRating"
          @blockinterpreter="openBlockInterpreterModal" />
        <sk-pagination v-if="showPagination"
                       :current="+currentPage"
                       :total="+totalPage"
                       class="inter-list__pagination"
                       @prev="toPrevPage"
                       @next="toNextPage"
                       @newpage="toNewPage" />
      </div>
    </template>
  </div>
</template>)

<script>
  import {mapActions, mapGetters, mapMutations, mapState} from 'vuex';
  import helpers from '@/helpers';
  import PersonsForAdmin
    from '@/components/assignment_components/one_assignment/interactive_info/invite_suppliers/TableForAdminFAIBlock';
  import Persons
    from '@/components/assignment_components/one_assignment/interactive_info/invite_suppliers/TableForFAIBlock';
  import Accordion from '@/components/shared_components/Accordion';

  export default {
    components: {
      persons: Persons,
      'persons-for-admin': PersonsForAdmin,
      accordion: Accordion,
    },
    props: {
      params: {
        type: Object,
        default() {
          return {};
        }
      },
    },
    data() {
      return {
        currentPage: 1,
        interPerPage: this.$isGodmode() ? 10 : 5,
        progressActive: true,
        showManualInvite: false,
      };
    },
    computed: {
      ...mapState('OneAssignmentStore', {
        storeJobObj: (state) => state.storeJobObj || {},
      }),
      ...mapGetters('EnterpriseStore', ['getOwnerUid']),
      jobId() { return this.storeJobObj.id || ''; },
      ...mapGetters('UserInfoStore', [
        'userIsBusiness',
        'userIsManager',
        'userIsEnterpriseMember',
        'userCanUseChat'
      ]),
      ...mapState('FAIListStore', {
        suitableInterpreters: ({suitableInterpreters}) => suitableInterpreters || [],
        suitableInterpretersIds: ({suitableInterpretersIds}) => suitableInterpretersIds || []
      }),
      ...mapGetters('OneAssignmentStore', [
        'seriesJobs',
        'isSameSupplierSeriesJob'
      ]),
      ...mapState('OneAssignmentStore', {
        assignmentObj: ({storeJobObj}) => storeJobObj || {},
      }),
      processRequirement() { return this.assignmentObj.processRequirement || {}; },
      priceRequirement() { return this.assignmentObj.priceRequirement || {}; },
      assignmentRequirement() { return this.assignmentObj.interpretationRequirement || {}; },
      jobStatus() { return this.assignmentObj.status || ''; },
      isPublishedJob() { return this.jobStatus === 'published'; },
      isDirectJob() { return this.assignmentObj.direct || ''; },
      directInterUid() { return this.assignmentObj.directInterUid || ''; },
      showDirectInvite() {
        return !this.showManualInvite
          && this.directInterUid
          && this.isDirectJob;
      },
      showCancelDirectInviteNote() {
        return this.processRequirement.directProcessing === 'direct_cancel_said_no';
      },
      interpreterIdsByPages() {
        const pagesList = [];
        let interpretersListOnPage = [];

        this.suitableInterpretersIds.forEach((interId, index) => {
          interpretersListOnPage.push(interId);
          if (interpretersListOnPage.length == this.interPerPage || index == this.suitableInterpretersIds.length - 1) {
            pagesList.push(interpretersListOnPage);
            interpretersListOnPage = [];
          }
        });
        return pagesList;
      },
      interpreterIdsOnCurrentPage() {
        const currentPage = this.currentPage - 1;

        return this.interpreterIdsByPages[currentPage] || [];
      },
      showPagination() { return !this.showDirectInvite && this.suitableInterpreters.length && this.totalPage > 1; },
      totalPage() { return Math.ceil(this.suitableInterpretersIds.length / this.interPerPage) || 0; },
      phoneJob() { return this.assignmentRequirement.sessionType === 'phone'; },
      videoJob() { return this.assignmentRequirement.sessionType === 'video'; },
      inPersonJob() { return this.assignmentRequirement.sessionType === 'in_person'; },
      allowApplyAsInterpreter() {
        const isRegularNotInPersonJob = (this.phoneJob || this.videoJob) && !this.isSameSupplierSeriesJob;
        const isSameInterpreterNotInPersonJob = this.isSameSupplierSeriesJob
          && !this.seriesJobs.find((job) => ['in_person', 'videoroom'].includes(job.sessionType));

        return isRegularNotInPersonJob || isSameInterpreterNotInPersonJob;
      },
      suitableInterpretersList() {
        const suitableInter = this.suitableInterpreters.map((interpreter) => this.returnInterWithRelation(interpreter));
        return suitableInter.sort((a) => {
          return a.unsuitabilityReason[0] === 'declined_invitation' ? -1 : 1;
        });
      },
      showRegularSuitableSuppliersBlock() { return !this.$isGodmode() || this.showDirectInvite; },
      isCompact() { return this.params.isCompactFAI; },
      onSuccessfulApply() { return this.params.onSuccessfulApply; }
    },
    watch: {
      currentPage() {
        this.getSuitableInterpreters();
      },
      totalPage() {
        if (this.currentPage > this.totalPage) {
          this.toNewPage(this.totalPage || 1);
        }
      }
    },
    methods: {
      ...mapActions('FAIListStore', [
        'getSuitableInterpretersList',
        'getSuitableInterpreterById',
        'getSuitableInterpretersIds',
        'getAutoInvitableInterpreters',
        'getSuitableNotInvitedInterpreters',
        'getSuitableInterpretersIdsByName'
      ]),
      ...mapMutations('FAIListStore', [
        'clearSuitableInterpreters'
      ]),
      inviteOtherInterpreters() {
        this.showManualInvite = !this.showManualInvite;
        this.progressActive = true;
        this.$store.dispatch('OneAssignmentStore/updateAutoInvite', true).then(() => {
          this.progressActive = false;
          this.fetchInterpreters();
        }).catch(() => {
          this.progressActive = false;
        });
      },
      changeInterpreterRating(action, interpreterId) {
        const params = {change: {action, interpreter_uid: interpreterId}};

        this.progressActive = true;
        this.$store.dispatch('FAIListStore/changeInterpreterRating', {jobId: this.jobId, params})
          .then(() => {
            this.fetchInterpreters();
          });
      },
      toPrevPage() {
        this.currentPage -= 1;
      },
      toNewPage(page) {
        this.currentPage = +page;
      },
      toNextPage() {
        this.currentPage += 1;
      },
      getSuitableInterpreters() {
        const interpretersList = this.showDirectInvite ? [this.directInterUid] : this.interpreterIdsOnCurrentPage;

        this.progressActive = true;
        this.getSuitableInterpretersList({jobId: this.jobId, interpretersList}).then(() => {
          this.progressActive = false;
        });
      },
      returnInterWithRelation(inter) {
        return {
          ...inter,
          ...{
            favourite: inter.relation && inter.relation === 'favourite',
            blocked: inter.relation && inter.relation === 'blocked'
          }
        };
      },
      verifyBlockedUser(interpreterData = {}) {
        return interpreterData.relation === 'blocked';
      },
      verifyUnsuitableUser(interpreterData = {}) {
        return interpreterData.unsuitabilityReason.length
          && interpreterData.unsuitabilityReason.includes('other_jobs');
      },
      verifyDistanceToUser(interpreterData) {
        if (interpreterData.distance) {
          const distanceInKm = (interpreterData.distance / 1000).toFixed(2);
          const maxDistanceInKm = this.assignmentObj.travelSettings?.maxDistance;

          return (Number(distanceInKm) > Number(maxDistanceInKm)) && this.inPersonJob;
        }
        return false;
      },
      openBlockedConfirmModal(params) {
        this.$store.commit('ModalStore/setModal', {
          component: 'inter-blocked',
          data: {
            title: this.$gettext('Interpreter was blocked'),
            context: this,
            method: 'commitInvite',
            params: [params]
          }
        });
      },
      openDistanceToUserConfirmModal(params, distance) {
        this.$store.commit('ModalStore/setModal', {
          component: 'confirmation-modal',
          width: 410,
          classes: ['sk-modal--new'],
          data: {
            title: this.$gettext('Invite'),
            text: this.$gettextInterpolate(
              this.$gettext('This person is %{distance} away and may need a hotel / flight / diett. If that is ok, please feel free to invite them'), {
                distance
              }
            ),
            context: this,
            modalCallback: this.commitInvite,
            callbackParams: params,
            btnTexts: {
              actionBtnText: this.$gettext('Invite'),
              cancelBtnText: this.$gettext('Cancel')
            }
          }
        });
      },
      openUnsuitableConfirmModal(params) {
        this.$store.commit('ModalStore/setModal', {
          component: 'confirmation-modal',
          width: 410,
          classes: ['sk-modal--new'],
          data: {
            title: this.$gettext('Confirm'),
            text: this.$gettext('Are you sure you want to invite the interpreter that is already busy?'),
            context: this,
            modalCallback: this.commitInvite,
            callbackParams: params,
            btnTexts: {
              actionBtnText: this.$gettext('Confirm'),
              cancelBtnText: this.$gettext('Cancel')
            }
          }
        });
      },
      sendInvite(interpreterUid, invitesCount) {
        const params = {
          id: interpreterUid,
          jobId: this.jobId,
          invitesCount: invitesCount
        };

        if (this.userIsManager) params.headers = {AuthImpersonationUID: this.getOwnerUid};

        const interpreterData = this.suitableInterpreters.find((int) => {
          return int.uid === interpreterUid;
        });

        if (this.verifyBlockedUser(interpreterData)) {
          this.openBlockedConfirmModal(params);
          return;
        }
        if (this.verifyUnsuitableUser(interpreterData)) {
          this.openUnsuitableConfirmModal(params);
          return;
        }
        if (this.verifyDistanceToUser(interpreterData)) {
          const distanceText = helpers.getJobInfo.distanceText(interpreterData.distance, this);

          this.openDistanceToUserConfirmModal(params, distanceText);
          return;
        }
        this.commitInvite(params);
      },
      openApplyConfirmationModal(params) {
        this.$store.commit('ModalStore/setModal', {
          component: 'confirmation-modal',
          width: 410,
          classes: ['sk-modal--new'],
          data: {
            title: this.$gettext('Confirm'),
            text: this.$gettextInterpolate(
              this.$gettext('Are you sure you want to confirm %{interpreterName} for this assignment?'), {
                interpreterName: params.interpreterName
              }
            ),
            context: this,
            modalCallback: this.apply,
            callbackParams: params,
            btnTexts: {
              actionBtnText: this.$gettext('Confirm interpreter'),
              cancelBtnText: this.$gettext('Cancel')
            }
          }
        });
      },
      openForceApplyConfirmationModal(params) {
        this.$store.commit('ModalStore/setModal', {
          component: 'confirmation-modal',
          width: 410,
          classes: ['sk-modal--new'],
          data: {
            title: this.$gettext('Confirm'),
            text: this.$gettextInterpolate(
              this.$gettext('%{interpreterName} is unsuitable due to availability. Are you sure you want to confirm this interpreter for this assignment?'), {
                interpreterName: params.interpreterName
              }
            ),
            context: this,
            modalCallback: this.apply,
            callbackParams: {
              ...params,
              ...{
                force: true
              }
            },
            btnTexts: {
              actionBtnText: this.$gettext('Confirm interpreter'),
              cancelBtnText: this.$gettext('Cancel')
            }
          }
        });
      },
      apply(params) {
        this.$store.commit('OneAssignmentStore/startAssignmentProgress');
        this.$store.dispatch('FAIListStore/applyAsInterpreter', params).then(() => {
          if (this.isCompact) {
            this.onSuccessfulApply();
          } else {
            const promiseArr = [
              this.$store.commit('OneAssignmentStore/setCurrentDiscussionId', ''),
              this.$store.dispatch('OneAssignmentStore/getJobById', this.jobId),
              this.$store.dispatch('OneAssignmentStore/getDiscussions', this.jobId)
            ];
            Promise.all(promiseArr)
              .then(() => {
                this.$store.commit('OneAssignmentStore/stopAssignmentProgress');
              });
          }
        }).catch((error) => {
          this.$store.commit('OneAssignmentStore/stopAssignmentProgress');
          const errors = error?.data?.errors;
          if (errors?.suitable_interpreter?.includes('Interpreter is not suitable anymore')) {
            setTimeout(() => {
              this.openForceApplyConfirmationModal(params);
            });
            return;
          }
          if (errors?.payment_method_present?.includes('Default payment method is absent')) {
            this.$store.commit('InfoModalStore/setInfoModal', {
              text: this.$gettext('Please set a default payment method first'),
              method: 'goToPaymentMethodsPage',
              context: this
            });
            return;
          }
          setTimeout(() => {
            this.$store.commit('ModalStore/setModal', {
              component: 'error-modal'
            });
          });
        });
      },
      goToPaymentMethodsPage() {
        this.$router.push(this.$makeRoute({
          name: this.userIsEnterpriseMember
            ? 'BuyerCompanyPayments'
            : 'BuyerPayments'
        }));
      },
      applyAsInterpreter(interpreterUid, interpreterId, interpreterName) {
        if (this.allowApplyAsInterpreter) {
          const params = {
            interpreterName,
            interpreterUid,
            jobId: this.jobId,
            force: false
          };
          if (this.userIsManager) params.headers = {AuthImpersonationUID: this.getOwnerUid};
          this.openApplyConfirmationModal(params);
        } else {
          this.loginAsInterpreter(interpreterId, interpreterUid);
        }
      },
      declineInvite(params) {
        if (this.userIsManager) params.headers = {AuthImpersonationUID: this.getOwnerUid};
        this.progressActive = true;
        this.$store.dispatch('FAIListStore/declineInvite', {...params, jobId: this.jobId}).then(() => {
          this.progressActive = false;
        }).catch(() => {
          this.progressActive = false;
        });
      },
      openBlockInterpreterModal(params) {
        this.$store.commit('ModalStore/setModal', {
          component: 'confirmation-modal',
          width: 410,
          classes: ['sk-modal--new'],
          data: {
            title: this.$gettext('Block interpreter'),
            text: this.$gettext('Are you sure you want to block the interpreter from the mission?'),
            context: this,
            modalCallback: this.blockInterpreter,
            callbackParams: params,
            btnTexts: {
              actionBtnText: this.$gettext('Block'),
              cancelBtnText: this.$gettext('Cancel')
            }
          }
        });
      },
      blockInterpreter(params) {
        if (this.userIsManager) params.headers = {AuthImpersonationUID: this.getOwnerUid};
        this.progressActive = true;
        this.$store.dispatch('FAIListStore/blockInterpreter', params).then(() => {
          this.progressActive = false;
        }).catch(() => {
          this.progressActive = false;
        });
      },
      loginAsInterpreter(interpreterId, interpreterUid) {
        const params = {
          uid: interpreterUid,
          admin_uid: this.$store.state.UserInfoStore.adminInfo.uid,
          notifs: false
        };
        if (this.userIsManager) params.headers = {AuthImpersonationUID: this.getOwnerUid};
        this.$store.dispatch('TTAuthStore/getGodModeToken', params).then((data) => {
          const link = helpers.getGodmodeLoginLink({
            userId: interpreterId,
            userUid: interpreterUid,
            token: data.token,
            jobId: this.jobId
          });
          window.open(link, '_blank');
        });
      },
      commitInvite(params) {
        this.progressActive = true;
        this.$store.dispatch('FAIListStore/sendInvite', params)
          .then((interpreterData) => {
            this.progressActive = false;
            if (interpreterData?.invitationsCount > 1 && this.$isGodmode()) {
              this.$store.commit('InfoModalStore/setInfoModal', {
                text: this.$gettext('Invitation SMS has been sent.')
              });
            }
          }).catch(() => {
            this.progressActive = false;
          });
      },
      fetchInterpreters() {
        const showInvitationQueue = this.$store.state.FAIFiltersStore.showInvitationQueue;
        const showSuitableNotInvited = this.$store.state.FAIFiltersStore.showSuitableNotInvited;
        const promise = showInvitationQueue
          ? this.getAutoInvitableInterpreters({jobId: this.jobId})
          : showSuitableNotInvited
            ? this.getSuitableNotInvitedInterpreters({jobId: this.jobId})
            : this.getSuitableInterpretersIds({jobId: this.jobId});

        this.progressActive = true;
        this.clearSuitableInterpreters();
        promise.then(() => {
          this.getSuitableInterpreters();
        });
      },
      searchInterpreters() {
        const showInvitationQueue = this.$store.state.FAIFiltersStore.showInvitationQueue;
        const showSuitableNotInvited = this.$store.state.FAIFiltersStore.showSuitableNotInvited;

        const promise = showInvitationQueue
          ? this.getAutoInvitableInterpreters({jobId: this.jobId})
          : showSuitableNotInvited
            ? this.getSuitableNotInvitedInterpreters({jobId: this.jobId})
            : this.getSuitableInterpretersIdsByName({jobId: this.jobId});

        this.progressActive = true;
        this.clearSuitableInterpreters();
        promise.then(() => {
          this.getSuitableInterpreters();
        });
      },
      accordionStateChanged(accordionOpened) {
        if (accordionOpened) this.fetchInterpreters();
      },
      refetchAssignment() {
        this.$store.dispatch('OneAssignmentStore/getJobById', this.jobId);
      }
    },
    mounted() {
      if (this.isPublishedJob && (this.$isGodmode() || this.userIsManager)) this.fetchInterpreters();
    }
  };
</script>

<style src="@assets/css/default_accordion.css"></style>

<style scoped>
  .inter-list {
    position: relative;
    display: block;
    border-radius: 10px;
    background-color: #fff;
  }

  .inter-list__header {
    justify-content: space-between;
  }

  .inter-list__inter-cont {
    display: flex;
    flex-direction: column;
    overflow: auto;
    min-height: 100px;
  }

  .inter-list__inter-cont--direct-invite {
    flex-direction: row;
  }

  .inter-list__inter-cont--direct-invite .inter-list__list-wrapper {
    width: 45%;
  }

  .inter-list__pagination {
    display: flex;
    margin: 10px auto;
  }

  .inter-list__manual-invite {
    display: flex;
    flex-grow: 1;
    flex-direction: column;
    overflow: auto;
    padding: 25px;
    background: #fff5d1;
  }

  .inter-list__manual-invite-text {
    width: 176px;
    margin: 0 auto;
    text-align: center;
  }

  .inter-list__manual-invite-btn {
    width: auto;
    margin: 18px auto 0;
    padding: 0 20px;
  }

  .inter-list__info-title {
    font-weight: bold;
  }

  .inter-list__info-wrapper {
    padding: 10px 60px;
  }

  .inter-list__info {
    padding: 10px 20px;
    border: solid 1px #ffb870;
    border-radius: 5px;
    background-color: #fff5d1;
    color: #333;
  }

  @media (max-width: 767px) {
    .inter-list__info {
      margin: 10px;
    }

    .inter-list__manual-invite {
      padding: 25px 5px;
    }

    .inter-list__info-wrapper {
      padding: 10px 20px;
    }
  }

  @media (max-width: 550px) {
    .inter-list__inter-cont--direct-invite {
      flex-direction: column;
    }

    .inter-list__inter-cont--direct-invite .inter-list__list-wrapper {
      width: 100%;
      margin-bottom: 15px;
    }
  }
</style>
