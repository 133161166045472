export default {
  path: 'assignments',
  component: () => import('@/pages/shared_pages/assignment_pages/sh_AssignmentsRoot'),
  children: [
    {
      path: '/',
      redirect: {name: 'BuyerSelectAssignmentType'}
    },
    {
      path: 'all',
      name: 'BuyerAllAssignments',
      meta: {subPage: true},
      component: () => import('@/pages/shared_pages/assignment_pages/sh_AllAssignments')
    },
    {
      path: 'all/:id/edit',
      name: 'BuyerEditInterpretation',
      meta: {subPage: true, viewers: true},
      component: () => import('@/pages/BuyerPages/assignment_pages/b_EditInterpretation')
    },
    {
      path: 'all/:id/editSecretary',
      name: 'BuyerEditSecretaryInterpretation',
      meta: {subPage: true, viewers: true},
      component: () => import('@/pages/BuyerPages/assignment_pages/b_EditSecretaryInterpretation')
    },
    {
      path: 'all/:id',
      name: 'BuyerOneAssignment',
      meta: {subPage: true, viewers: true},
      component: () => import('@/pages/shared_pages/assignment_pages/sh_OneInterpretation'),
      props: true
    },
    {
      path: 'create',
      name: 'BuyerSelectAssignmentType',
      redirect: {name: 'BuyerPostInterpretation'}
    },
    {
      path: 'createInterpretation',
      name: 'BuyerPostInterpretation',
      meta: {
        subPage: true,
        assignmentType: 'interpretation'
      },
      component: () => import('@/pages/BuyerPages/assignment_pages/b_PostInterpretation')
    },
    {
      path: 'createFastInterpretation',
      name: 'BuyerPostFastInterpretation',
      meta: {
        subPage: true,
        assignmentType: 'fast_interpretation'
      },
      component: () => import('@/pages/BuyerPages/assignment_pages/b_PostFastInterpretation')
    },
    {
      path: 'createSeriesInterpretation',
      name: 'BuyerPostSeriesInterpretation',
      meta: {
        subPage: true,
        assignmentType: 'series_interpretation'
      },
      component: () => import('@/pages/BuyerPages/assignment_pages/b_PostSeriesInterpretation')
    },
    {
      path: 'createSecretary',
      name: 'BuyerPostSecretaryInterpretation',
      meta: {
        subPage: true,
        assignmentType: 'secretary'
      },
      component: () => import('@/pages/BuyerPages/assignment_pages/b_PostSecretaryInterpretation')
    },
    {
      path: 'createStaffing',
      name: 'BuyerPostStaffing',
      meta: {subPage: true},
      component: () => import('@/pages/BuyerPages/assignment_pages/b_PostStaffing')
    },
    // in-case redirect customer using the old translation to new default one
    {
      path: 'createTranslation',
      redirect: {name: 'BuyerPostTranslationProject'}
    },
    {
      path: 'staffing',
      name: 'BuyerOneStaffing',
      meta: {subPage: true},
      component: () => import('@/pages/BuyerPages/assignment_pages/b_OneStaffing')
    }
  ]
};
