// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_GET_URL_IMPORT___ = require("../../../node_modules/css-loader/dist/runtime/getUrl.js");
var ___CSS_LOADER_URL_IMPORT_0___ = require("@assets/imgs/undefined_imgs/info-ico.svg");
var ___CSS_LOADER_URL_IMPORT_1___ = require("@assets/imgs/salita/hints/application/interpreter_withdrew.svg");
exports = ___CSS_LOADER_API_IMPORT___(false);
var ___CSS_LOADER_URL_REPLACEMENT_0___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_0___);
var ___CSS_LOADER_URL_REPLACEMENT_1___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_1___);
// Module
exports.push([module.id, "\n.note__wrapper[data-v-c3738258] {\n  position: relative;\n  padding: 10px 10px 10px 35px;\n  border-radius: 3px;\n  background-color: #f0e8dd;\n  font-size: 12px;\n}\n.note__wrapper--danger[data-v-c3738258] {\n  background-color: rgba(255, 54, 36, 0.1);\n}\n.note__text[data-v-c3738258]::before {\n  content: '';\n  position: absolute;\n  top: 12px;\n  left: 10px;\n  display: block;\n  width: 15px;\n  height: 15px;\n  background-image: url(" + ___CSS_LOADER_URL_REPLACEMENT_0___ + ");\n  background-position: 50% 50%;\n  background-size: 15px auto;\n  background-repeat: no-repeat;\n}\n.note__text--danger[data-v-c3738258]::before {\n  background-image: url(" + ___CSS_LOADER_URL_REPLACEMENT_1___ + ");\n  background-size: 20px;\n}\n.note__text--danger[data-v-c3738258] {\n  color: #ff3624;\n}\n.note__wrapper--white[data-v-c3738258] {\n  background-color: #fff;\n}\n", ""]);
// Exports
module.exports = exports;
