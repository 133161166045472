(<template>
  <div :class="{'post-accordion-section--selected': state,
                'post-accordion-section--disabled': disabled}"
       class="post-accordion-section"
       @focusin="emitFocusin">
    <div class="post-accordion-section__header">
      <div class="post-accordion-section__header-wrapper">
        <h3 class="post-accordion-section__title">{{ title }}</h3>
        <div>{{ description }}</div>
      </div>
      <label class="post-accordion-section__label">
        <input :checked="state"
               :disabled="disabled"
               type="checkbox"
               class="post-accordion-section__input"
               @change="changeAccordionState" />
        <button v-if="!state"
                class="sk-btn sk-btn--white sk-btn--rounded post-accordion-section__btn"
                :class="{'post-accordion-section__btn--disabled': disabled}"
                @click.prevent="changeAccordionState">{{ $gettext('Select') }}</button>
        <button v-if="state"
                class="sk-btn sk-btn--default sk-btn--rounded post-accordion-section__btn"
                :class="{'post-accordion-section__btn--disabled': disabled}"
                @click.prevent="changeAccordionState">{{ $gettext('Remove') }}</button>
      </label>
    </div>
    <div v-if="state">
      <slot></slot>
    </div>
  </div>
</template>)

<script>
  export default {
    props: {
      state: Boolean,
      title: {
        type: String,
        default: ''
      },
      description: {
        type: String,
        default: ''
      },
      disabled: Boolean
    },
    methods: {
      changeAccordionState() {
        return this.$emit('statechanged', !this.state);
      },
      emitFocusin() { this.$emit('focusin', true); }
    }
  };
</script>

<style scoped>
  .post-accordion-section {
    width: 100%;
    margin-bottom: 10px;
    padding: 10px 15px 10px 20px;
    border: 1px solid #e9e9ee;
    border-radius: 6px;
    background-color: #fff;
    text-align: left;
  }

  .post-accordion-section--selected {
    background-color: #e9e9ee;
  }

  .post-accordion-section--disabled {
    opacity: 0.6;
  }

  .post-accordion-section__header {
    display: flex;
    align-items: flex-start;
    justify-content: space-between;
    width: 100%;
  }

  .post-accordion-section__title {
    font-size: 14px;
    line-height: 1.43;
  }

  .post-accordion-section__input {
    position: absolute;
    visibility: hidden;
  }

  .post-accordion-section__label {
    margin-left: 10px;
  }

  .post-accordion-section__btn {
    width: auto;
    padding: 0 10px;
  }

  .post-accordion-section__btn--disabled {
    pointer-events: none;
  }

  @media (max-width: 767px) {
    .post-accordion-section {
      padding-right: 10px;
      padding-left: 10px;
    }
  }
</style>
