(<template>
  <div class="post-form__field booker">
    <sk-select :items-list="bookerList"
               :preselected-value="employeeObj.uid"
               :description="bookerText"
               :default-value="bookerText"
               :preview-value="getPreview"
               class="booker__select"
               :autofocus="!isMobileView || params.autofocus"
               @csvaluechanged="selectBooker" />
    <button v-if="params.showEditBooker"
            class="sk-select--edit-btn"
            @click.prevent.stop="openEditEmployee"></button>
    <button v-if="!params.hideAddBooker"
            class="sk-select-btn"
            @click.prevent.stop="openAddEmployeeModal"></button>
  </div>
</template>)

<script>
  import helpers from '@/helpers';
  import {mapActions, mapGetters, mapState} from 'vuex';

  export default {
    props: {
      params: {
        type: Object,
        default() {
          return {};
        }
      }
    },
    computed: {
      ...mapState('EnterpriseStore', {
        allEmployees: (state) => state.employees || []
      }),
      ...mapState('PostingStore/BookerStore', {
        booker: (state) => state.booker
      }),
      ...mapState('PostingStore/DepartmentAttendersStore', {
        departmentsList: (state) => state.departmentsList || []
      }),
      ...mapState('OneProjectStore', {
        owner: (state) => state.project.owner
      }),
      ...mapGetters('UserInfoStore', ['userIsManager']),
      ...mapGetters('EnterpriseStore', ['getOwnerUid']),
      employees() {
        return this.allEmployees.length
          ? this.allEmployees.filter((booker) => booker.status === 'accepted')
          : [];
      },
      employeeObj() {
        return this.allEmployees.length
          ? this.allEmployees.find((booker) => booker.uid === this.booker)
          : {};
      },
      bookerText() { return this.$gettext('Booker'); },
      bookerList() {
        if (this.employees.length) {
          return this.employees.map((att) => {
            return {
              id: att.uid,
              name: att.firstName || att.lastName ? `${att.firstName} ${att.lastName}` : att.email,
              email: att.email,
              phone: (att.phoneCode || '') + (att.phoneNumber || '')
            };
          });
        }
        return [];
      },
      isMobileView() { return this.$store.state.GlobalStore.clientWidth < 768; },
      headers() { return {AuthImpersonationUID: this.getOwnerUid}; },
      impersonateRequests() { return this.params.impersonateRequests || false; },
    },
    methods: {
      ...mapActions('PostingStore/DepartmentAttendersStore', ['fetchDepartments']),
      ...mapActions('PostingStore/BookerStore', ['changeBooker']),
      getPreview(selectedData) {
        if (selectedData && selectedData.id && !this.userIsManager) {
          const selectedItem = this.employees.find((item) => item.uid == selectedData.id) || {};
          return helpers.getSelectedPreview(selectedItem);
        }
      },
      preselectDataAfterAddingMember(employeeId) {
        this.changeBooker({employeeId, headers: this.impersonateRequests ? this.headers : {}});
      },
      preselectDataAfterAddingDepartment() {
        this.fetchDepartments();
      },
      selectBooker(employeeId) {
        this.changeBooker({employeeId, headers: this.impersonateRequests ? this.headers : {}});
      },
      openAddEmployeeModal() {
        this.$store.commit('EnterpriseStore/setDepartments', {departments: this.departmentsList});
        this.$store.commit('ModalStore/setModal', {
          component: 'add-employee',
          width: 410,
          data: {
            title: this.$gettext('New employee'),
            context: this,
            successHandlingCallbackName: 'preselectDataAfterAddingMember'
          }
        });
      },
      openEditEmployee() {
        this.$store.commit('ModalStore/setModal', {
          component: 'add-employee',
          width: 410,
          data: {
            title: this.$gettext('Edit employee'),
            currentUser: this.employeeObj,
            context: this
          }
        });
      },
    }
  };
</script>

<style>
  .booker__select .sk-select__description {
    min-height: 18px;
  }

  @media (max-width: 768px) {
    .booker__select .sk-select__description {
      min-height: 11px;
    }
  }
</style>

<style scoped>
  .booker {
    display: flex;
  }

  .booker__select {
    flex-grow: 1;
    width: calc(100% - 35px);
    margin-bottom: 0;
  }

  .sk-select--edit-btn,
  .sk-select-btn {
    margin-top: 21px;
  }

  @media (max-width: 768px) {
    .sk-select--edit-btn,
    .sk-select-btn {
      margin-top: 19px;
    }
  }
</style>
