import axios from 'axios';

const other = {
  methods: {
    fetchSelectsData(name, enterpriseId, headers) {
      switch (name) {
        case 'language':
          return new Promise((resolve, reject) => {
            this.ajax.get('/v1/languages')
              .then((response) => {
                const resp = response ? response?.data || {} : {};
                resolve(resp);
              }).catch((error) => {
              this.resolveError403(error, resolve);
              this.resolveError500(error, resolve);
              reject(error?.data || {});
            });
          });
        case 'qualification':
          return new Promise((resolve, reject) => {
            this.ajax.get('/v1/qualifications')
              .then((response) => {
                const resp = response ? response?.data || {} : {};
                resolve(resp);
              }).catch((error) => {
              this.resolveError403(error, resolve);
              this.resolveError500(error, resolve);
              reject(error?.data || {});
            });
          });
        case 'departments':
          return new Promise((resolve, reject) => {
            // No pagination fetch all departments plus needs to be verbose(more than name & id)
            const params = {verbose: true};
            this.ajax.get(`v1/enterprises/${enterpriseId}/departments`, {params, headers})
              .then((response) => {
                resolve(response?.data.departments);
              }).catch((error) => {
              this.resolveError403(error, resolve);
              reject(error?.data || {});
            });
          });
        case 'attenders':
          return new Promise((resolve, reject) => {
            this.ajax.get('/v1/jobs/attenders', {headers})
              .then((response) => {
                resolve(response?.data.attenders);
              }).catch((error) => {
              this.resolveError403(error, resolve);
              reject(error?.data || {});
            });
          });
        case 'countries':
          return new Promise((resolve, reject) => {
            this.ajax.get('/v1/countries')
              .then((response) => {
                const resp = response ? response?.data || {} : {};
                resolve(resp);
              }).catch((error) => {
              this.resolveError403(error, resolve);
              this.resolveError500(error, resolve);
              reject(error?.data || {});
            });
          });
        case 'managers':
          return new Promise((resolve, reject) => {
            this.ajax.get('v1/managers')
              .then((response) => {
                resolve(response?.data.managers);
              }).catch((error) => {
              this.resolveError403(error, resolve);
              reject(error?.data || {});
            });
          });
      }
    },
    getTrafficStations() {
      return new Promise((resolve, reject) => {
        this.ajax.get('v1/traffic_stations')
          .then((response) => {
            resolve(response?.data);
          }).catch((error) => {
          this.resolveError403(error, resolve);
          this.resolveError500(error, resolve);
          reject(error?.data || {});
        });
      });
    },
    checkEmailExist(email) {
      return new Promise((resolve, reject) => {
        this.ajax.get('v1/accounts/identities/exists', {params: {email}}).then((response) => {
          const resp = response ? response?.data || {} : {};
          resolve(resp);
        }).catch((error) => {
          this.resolveError403(error, resolve);
          this.resolveError500(error, resolve);
          reject(error?.data || {});
        });
      });
    },
    fetchSystemLogDetails(id) {
      return new Promise((resolve, reject) => {
        this.ajax.get(`/v1/system_logs/${id}`)
          .then((response) => {
            resolve(response?.data);
          }).catch((error) => {
          this.resolveError403(error, resolve);
          reject(error);
        });
      });
    },
    fetchExpertiseAreas(locale) {
      return new Promise((resolve, reject) => {
        this.ajax.get('v1/expertise_areas', {params: {locale}}).then((response) => {
          const resp = response ? response?.data || {} : {};
          resolve(resp);
        }).catch((error) => {
          this.resolveError403(error, resolve);
          this.resolveError500(error, resolve);
          reject(error?.data || {});
        });
      });
    },
    fetchTopLanguages() {
      return new Promise((resolve, reject) => {
        this.ajax.get('/v1/languages/top').then((response) => {
          const resp = response ? response?.data || {} : {};
          resolve(resp);
        }).catch((error) => {
          this.resolveError403(error, resolve);
          this.resolveError500(error, resolve);
          reject(error?.data || {});
        });
      });
    },
    fetchTopTranslationLanguages() {
      return new Promise((resolve, reject) => {
        this.ajax.get('/v1/languages/top?for=translation').then((response) => {
          const resp = response ? response?.data || {} : {};
          resolve(resp);
        }).catch((error) => {
          this.resolveError403(error, resolve);
          this.resolveError500(error, resolve);
          reject(error?.data || {});
        });
      });
    },
    fetchInterpretationMethods(locale) {
      return new Promise((resolve, reject) => {
        this.ajax.get('v1/interpretation_methods', {params: {locale}}).then((response) => {
          const resp = response ? response?.data || {} : {};
          resolve(resp);
        }).catch((error) => {
          this.resolveError403(error, resolve);
          this.resolveError500(error, resolve);
          reject(error?.data || {});
        });
      });
    },
    sendRole(role, uid) {
      const self = this;

      return new Promise((resolve, reject) => {
        const params = {uid};
        if (role == 'employee') params.enterprise = true;
        self.ajax.post(`v1/${role == 'server' ? 'interpreters' : 'buyers'}`, params).then(() => {
          resolve();
        }).catch((error) => {
          reject(error);
        });
      });
    },
    createStaffingJob(jobForm) {
      const self = this;

      return new Promise((resolve, reject) => {
        self.ajax.post('/v1/staffing_projects', jobForm).then((response) => {
          resolve(response?.data);
        }).catch((error) => {
          reject(error);
        });
      });
    },
    getCalendarJobs(date, view) {
      const self = this;

      return new Promise((resolve, reject) => {
        self.ajax.get(`v1/calendars/${view}`, {params: {date}}).then((response) => {
          resolve(response?.data);
        }).catch((error) => {
          this.resolveError403(error, resolve);
          this.resolveError500(error, resolve);
          reject(error);
        });
      });
    },

    // ==================================================
    // ==================================================
    // OTHER
    // ==================================================
    // ==================================================
    sendHelpMessage(form) {
      const self = this;

      return new Promise((resolve, reject) => {
        self.ajax.post('/v1/notifications/request_support', form).then(() => {
          resolve();
        }).catch((error) => {
          reject(error?.data || {});
        });
      });
    },
    getQualificationsPresence(langId) {
      const self = this;

      return new Promise((resolve, reject) => {
        self.ajax.get(`/v1/languages/${langId}/qualifications_presence`).then((response) => {
          resolve(response?.data);
        }).catch((error) => {
          reject(error?.data || {});
        });
      });
    },
    sendWelcome() {
      const self = this;

      return new Promise((resolve, reject) => {
        self.ajax.post('/v1/welcome_on_board').then(() => {
          resolve();
        }).catch((error) => {
          reject(error);
        });
      });
    },
    sendDrivingLicense(uid) {
      const self = this;

      return new Promise((resolve, reject) => {
        self.ajax.post(`v1/buyers/${uid}/hide_driving_popup`).then(() => {
          resolve();
        }).catch((error) => {
          reject(error);
        });
      });
    },
    checkSupportMode() {
      const self = this;

      return new Promise((resolve, reject) => {
        self.ajax.get('/v1/support/admin_info').then((response) => {
          resolve(response?.data);
        }).catch((error) => {
          this.resolveError403(error, resolve);
          reject(error);
        });
      });
    },
    decriptFile(link) {
      const fetcher = axios.create({
        headers: {Authorization: `Bearer ${this.userToken}`.trim(), 'Accept-language': this.locale.trim()}
      });

      return new Promise((resolve, reject) => {
        fetcher.post(link).then((response) => {
          resolve(response?.data);
        }).catch((error) => {
          reject(error);
        });
      });
    },
    getShortLivedToken() {
      const self = this;

      return new Promise((resolve, reject) => {
        self.ajax.post('/v1/async_tokens').then((response) => {
          resolve(response?.data);
        }).catch((error) => {
          reject(error);
        });
      });
    },
  }
};

export default other;
