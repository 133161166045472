import { render, staticRenderFns } from "./DeadlineSection.vue?vue&type=template&id=ea74e44e&scoped=true&"
import script from "./DeadlineSection.vue?vue&type=script&lang=js&"
export * from "./DeadlineSection.vue?vue&type=script&lang=js&"
import style0 from "./DeadlineSection.vue?vue&type=style&index=0&id=ea74e44e&prod&scoped=true&lang=css&"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "ea74e44e",
  null
  
)

export default component.exports