(<template>
  <div ref="deadline"
       class="deadline-section__wrapper"
       @focusin="openDeadlineHelper">
    <div class="post-form__section deadline-section">
      <sk-tooltip v-if="tooltipText"
                  :item-text="tooltipText"
                  :hover="true"
                  class="deadline-section__tooltip" />
      <sk-datepicker :preselected-value="date"
                     :full-calendar="allowPastBooking"
                     :default-value="datePickerDefaultValue"
                     :prevent-call="true"
                     :disabled="disabledFields"
                     :description="dateText"
                     class="post-form__field deadline-section__datepicker"
                     @changeday="catchDeadline" />
      <sk-timepicker v-if="!hideTime"
                     :preselected-value="time"
                     :start-select-from="allowPastBooking ? '' : startTimeBeginning"
                     :auto-scroll="true"
                     :default-value="'--:--'"
                     :disabled="disabledFields"
                     :description="timeSelectText"
                     :validation-name="deadlineTimeErrorName"
                     :time-step="$timepickerStep()"
                     :mob-numeric-keyboard="true"
                     class="post-form__field deadline-section__timepicker"
                     @timepickervaluechanged="setDeadlineTime" />
      <button v-if="showReset && deadline"
              class="deadline-section__reset-icon"
              @click="emptyDeadline"></button>
    </div>
    <div v-if="!isInPersonOrder">
      <p v-if="isDateOptional"
         class="deadline-section__hint-text">{{ optionalDateHint }}</p>
      <p v-if="showSupportHint"
         class="deadline-section__hint-text">{{ supportText }}</p>
      <!-- <p v-if="showMiniDeadlineText"
         class="deadline-section__mini-text">{{ miniDeadlineText }}</p> -->
    </div>
  </div>
</template>)

<script>
  import helpers from '@/helpers';
  import {mapState, mapMutations, mapGetters} from 'vuex';

  export default {
    props: {
      params: {
        type: Object,
        default: () => {
          return {};
        }
      }
    },
    computed: {
      ...mapState('PostingStore/DeadlineStore', {
        deadline: (state) => state.deadline,
        // error names
        deadlineTimeErrorName: (state) => state.errors.deadlineTime.name
      }),
      ...mapGetters('UserInfoStore', ['userIsPoliceEnterprise']),
      dateText() { return this.isInPersonOrder ? this.$gettext('Meeting date') : this.$pgettext('translation', 'Delivery deadline'); },
      timeSelectText() { return this.$pgettext('translation', 'Time'); },
      isInPersonOrder() { return this.params.assignmentType === 'in_person'; },
      tooltipText() { return this.params.tooltipText; },
      showReset() { return this.params.showReset; },
      isDateOptional() { return this.params.isDateOptional; },
      isSecretaryAssignment() { return this.params.secretaryAssignment; },
      showSupportHint() { return this.params.showSupportHint; },
      miniDeadline() { return this.params.miniDeadlineDays; },
      hideTime() { return this.params.hideTime; },
      invalidDeadline() {
        const deadline = this.$moment(this.deadline);
        const now = this.$moment(new Date());
        const diff = deadline.diff(now, 'days');
        return diff < this.miniDeadline;
      },
      deadlineWarningText() {
        return this.userIsPoliceEnterprise ? this.shortNoticedText : this.miniDeadlineText;
      },
      optionalDateHint() { return this.$pgettext('translation', 'Delivery deadline is optional'); },
      miniDeadlineText() {
        return this.$pgettext('translation', 'The delivery deadline is too short. Minimum delivery deadline is 2 working days after your order is registered. If it is urgent, contact us by email oversettelse@salita.no');
      },
      shortNoticedText() {
        return this.$gettext('Short notice assignments may incur an additional fee');
      },
      supportText() {
        return this.$pgettext('translation', 'As soon as the translation is completed, you will be notified by e-mail, with a link to the translation on Your page in the portal.');
      },
      disabledFields() { return this.params.disabledFields; },
      allowPastBooking() { return this.$isGodmode(); },
      datePickerDefaultValue() { return this.$gettext('Flexible'); },
      startTimeBeginning() {
        const currentTime = helpers.dateTime.convertCurrentTime(this.$moment);
        const currentTimePlusHour = this.$moment(currentTime, 'HH:mm').add(60, 'minutes').format('HH:mm');
        const timeBeginning = this.isSecretaryAssignment ? currentTimePlusHour : currentTime;
        return this.date === this.$moment().format('YYYY-MM-DD') ? timeBeginning : '';
      },
      time() { return this.deadline ? this.$moment(this.deadline).format('HH:mm') : ''; },
      date() { return this.deadline ? this.$moment(this.deadline).format('YYYY-MM-DD') : ''; },
      showAssignmentHelper() { return this.params.showAssignmentHelper; },
      context() { return this.params.context; },
      showMiniDeadlineText() { return this.miniDeadline && this.invalidDeadline && !this.allowPastBooking; }
    },
    methods: {
      ...mapMutations('PostingStore/DeadlineStore', [
        'setDeadlineDate',
        'setDeadlineTime',
        'emptyDeadline',
        'setShortNoticed'
      ]),
      ...mapMutations('PostAssignmentHelperStore', [
        'openHelper',
        'setContext',
        'setPopoverReference',
      ]),
      openDeadlineHelper() {
        if (this.showAssignmentHelper && this.context == 'translation') {
          this.setContext('translation');
          this.setPopoverReference(this.$refs.deadline);
          this.openHelper('deadline');
        } else {
          this.setContext('interpretation');
          this.setPopoverReference(this.$refs.deadline);
          this.openHelper('deadline');
        }
      },
      catchDeadline(value) {
        this.setDeadlineDate(value);
        if (this.userIsPoliceEnterprise) this.setShortNoticed(this.invalidDeadline);
        if (this.hideTime) this.setDeadlineTime('23:59');
      }
    },
  };
</script>

<style scoped>
  .deadline-section {
    flex-wrap: nowrap;
  }

  .deadline-section__datepicker {
    width: 100%;
    max-width: 100px;
  }

  .deadline-section__timepicker {
    width: 100%;
    max-width: 70px;
  }

  .deadline-section__tooltip {
    position: relative;
    top: 28px;
    z-index: 1;
    width: 15px;
    height: 15px;
    margin-left: 15px;
    line-height: 15px;
  }

  .deadline-section__tooltip::before {
    content: '?';
    position: absolute;
    top: 0;
    z-index: -1;
    width: 100%;
    height: 100%;
    border-radius: 50%;
    background-color: #000;
    color: #fff;
    font-size: 12px;
    text-align: center;
  }

  @media (max-width: 767px) {
    .deadline-section__tooltip {
      top: 20px;
    }
  }

  .deadline-section__reset-icon {
    align-self: center;
    width: 20px;
    height: 25px;
    margin-right: 15px;
    background-image: url(~@assets/imgs/undefined_imgs/reset_icon.svg);
    background-position: 50% 50%;
    background-size: 17px auto;
    background-repeat: no-repeat;
    cursor: pointer;
  }

  .deadline-section__reset-icon:focus {
    outline: var(--default-focus-outline);
  }

  .deadline-section__wrapper {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    width: 100%;
  }

  .deadline-section__hint-text {
    margin: 0 15px 10px;
    color: rgba(0, 0, 0, 0.5);
  }

  .deadline-section__mini-text {
    margin: 0 15px 10px;
    color: #f04;
  }
</style>
