(<template>
  <div class="edit-modal">
    <booking-form :form-progress="bookingFormProgress"
                  :sections-list="sectionsList"
                  :validate-func="validateFunc"
                  :submit-button-text="submitButtonText"
                  :show-action-buttons="showActionButtons"
                  :send-info-func="data.sendInfoFunc"
                  :success-handling-func="data.successHandlingFunc"
                  :error-handling-func="errorHandlingFunc"
                  :align-off="true">
      <button slot="closeButton"
              class="sk-btn sk-btn--white post-form__post-btn"
              @click="closeModal">{{ $gettext('Back') }}</button>
    </booking-form>
  </div>
</template>)

<script>
  import {mapState, mapGetters} from 'vuex';
  import BookingForm from '@/components/booking_components/BookingForm';
  import PostInterpretationPrototype from '@/prototypes/PostInterpretationPrototype';

  export default {
    components: {
      'booking-form': BookingForm
    },
    extends: PostInterpretationPrototype,
    props: {
      data: {
        type: Object,
        default() {
          return {
            sectionName: '',
            validateFunc: '',
            submitButtonText: '',
            successHandlingFunc: () => {},
            sendInfoFunc: () => {},
            openEditFunc: () => {},
            showActionButtons: true,
          };
        }
      }
    },
    computed: {
      ...mapState('PostInterpretationStore', {
        shouldBeDisabledImportantFields: ({disabledImportantFields}) => disabledImportantFields,
        jobStatus: ({job}) => job.status
      }),
      ...mapState('FAIListStore', {
        suitableInterpreters: ({suitableInterpreters}) => suitableInterpreters || []
      }),
      ...mapState('OneAssignmentStore', {
        assignmentObj: ({storeJobObj}) => storeJobObj || {},
      }),
      ...mapGetters('UserInfoStore', ['isEmailVerificationRequired']),
      processRequirement() { return this.assignmentObj.processRequirement || {}; },
      disabledImportantFields() {
        return this.shouldBeDisabledImportantFields && !this.$isGodmode();
      },
      jobId() {
        return this.$route.params.id;
      },
      sectionName() {
        return this.data.sectionName;
      },
      submitButtonText() {
        return this.data.submitButtonText;
      },
      showActionButtons() {
        return this.data.showActionButtons;
      },
      sectionsList() {
        return [
          this[this.sectionName]
        ];
      },
      validateFuncName() {
        return this.data.validateFuncName;
      },
      validateFunc() {
        return this[this.validateFuncName];
      },
      showInviteSystem() {
        return this.suitableInterpreters.length > 1
          && this.processRequirement.autoInviteEnabled === 'on'
          && this.processRequirement.autoInviteStatus !== 'finished';
      },
      // additional sections information
      personalInformationSections() {
        return {
          title: this.$gettext('Customer information'),
          lines: [{components: [{
            name: 'booker-section',
            condition: this.showBookerSection && !this.isEmailVerificationRequired,
            params: {
              showEditBooker: true,
              autofocus: true
            }
          }]}, {
            components: [{
              name: 'department-attenders-section',
              condition: this.showDepartmentAttendersSection && !this.isEmailVerificationRequired,
              params: {
                isModalSection: true,
                hideContactPerson: true,
                showEditDepartment: true,
                showEditAttenders: true,
                openEditModal: this.data.openEditFunc
              }
            }]
          }, {
            components: [{
              name: 'owner-real-name-section',
              condition: this.showOwnerRealNameSection
            }],
          }, {
            components: [{
              name: 'client-section',
              condition: this.showClientSection
            }]
          }]
        };
      },
      invoiceInformationSections() {
        return {
          title: this.$gettext('Invoice information'),
          lines: [{
            components: [{
              name: 'payment-method-section',
              condition: this.showPaymentMethod
            }]
          }, {
            components: [{
              name: 'booking-reference-section',
              condition: this.showBookingReferenceSection
            }]
          }, {
            components: [{
              name: 'case-number-section',
              condition: this.showCaseNumberSection
            }]
          }, {
            components: [{
              name: 'client-section',
              condition: this.showClientSection
            }]
          }]
        };
      },
      attachmentInformationSection() {
        return {
          title: this.$gettext('Attachment information'),
          lines: [{
            components: [{
              name: 'attachments-inner-section'
            }]
          }]
        };
      },
      secondaryAssignmentInformationSection() {
        return {
          title: this.$gettext('Assignment information'),
          lines: [{
            components: [{
              name: 'blocked-suppliers-section'
            }]
          }, {
            components: [{
              name: 'blocked-cities-section',
              condition: this.showBlockedCitiesSection
            }]
          }, {
            components: [{
              name: 'participants-section',
              condition: this.showParticipantsSection
            }]
          }, {
            components: [{
              name: 'extra-info-inner-section',
              params: {
                showExtraInfoNote: true
              }
            }]
          }, {
            components: [{
              name: 'attachments-inner-section'
            }]
          }]
        };
      },
      mainAssignmentInformationSection() {
        return {
          title: this.$gettext('Assignment information'),
          lines: [{
            components: [{
              name: 'language-section',
              params: {
                langToText: this.$gettext('Language'),
                showMyLang: true,
                showDialect: true,
                disabledFields: this.disabledImportantFields
              }
            }]
          }, {
            components: [{
              name: 'date-time-section',
              params: {
                disabledFields: this.disabledImportantFields
              }
            }]
          }, {
            components: [{
              name: 'edit-note-section',
              params: {
                jobStatus: this.jobStatus
              },
              condition: this.disabledImportantFields
            }]
          }, {
            components: [{
              name: 'subject-section'
            }]
          }, {
            components: [{
              name: 'assignment-type-section',
              params: {
                disabledFields: this.disabledImportantFields
              }
            }]
          }, {
            components: [{
              name: 'alternative-language-section',
              condition: this.showAlternativeLanguageSection
            }]
          }, {
            components: [{
              name: 'qualification-section',
              condition: this.showQualificationSection,
              params: {
                disabledFields: this.disabledImportantFields
              }
            }]
          }, {
            components: [{
              name: 'gender-section',
              params: {
                disabledFields: this.disabledImportantFields
              }
            }]
          }]
        };
      },
      mainSecretaryAssignmentInformationSection() {
        return {
          title: this.$gettext('Secretary assignment'),
          lines: [{
            components: [{
              name: 'language-section',
              params: {
                langToText: this.$gettext('Language'),
                disabledFields: this.disabledImportantFields
              }
            }, {
              name: 'deadline-section',
              params: {
                disabledFields: this.disabledImportantFields
              }
            }]
          }, {
            components: [{
              name: 'subject-section'
            }]
          }, {
            components: [{
              name: 'secretary-section'
            }]
          }, {
            components: [{
              name: 'alternative-language-section',
              condition: this.showAlternativeLanguageSection
            }]
          }, {
            components: [{
              name: 'qualification-section',
              condition: this.showQualificationSection,
              params: {
                disabledFields: this.disabledImportantFields
              }
            }]
          }, {
            components: [{
              name: 'gender-section',
              params: {
                disabledFields: this.disabledImportantFields
              }
            }]
          }]
        };
      },

      managerAdminInformationSection() {
        return {
          title: this.$gettext('Admin tools'),
          lines: [
            {components: [
              {
                name: 'basic-job-info-table-section',
                condition: true,
                classes: ['post-form__field']
              },
            ]},
            {
              components: [{
                name: 'replacement-section',
                condition: true
              }]
            }, {
              components: [{
                name: 'manual-fees-section',
                condition: true
              }]
            }, {
              components: [{
                name: 'confirmations-section',
                condition: true
              }]
            }]
        };
      },
      managerCustomerInformationSection() {
        return {
          title: this.$gettext('Customer information'),
          lines: [
            {components: [
              {
                name: 'basic-job-info-table-section',
                condition: true,
                classes: ['post-form__field'],
              },
            ]},
            {
              components: [{
                name: 'booker-section',
                condition: true,
                params: {
                  showEditBooker: false,
                  hideAddBooker: true,
                  impersonateRequests: true
                }
              }]
            }, {
              components: [{
                name: 'department-attenders-section',
                condition: true,
                params: {
                  hideAddDepartment: true,
                  hideAddAttenders: true,
                  showEditDepartment: false,
                  showEditAttenders: false,
                  openEditModal: this.data.openEditFunc,
                  isSmallFields: true,
                  impersonateRequests: true
                },
              }]
            }]
        };
      },
      managerAssignmentsInformationSection() {
        return {
          title: this.$gettext('Assignment information'),
          lines: [
            {components: [
              {
                name: 'basic-job-info-table-section',
                condition: true,
                classes: ['post-form__field'],
              },
            ]}, {
              components: [
                {
                  name: 'language-section',
                  params: {
                    langToText: this.$gettext('Language'),
                    showMyLang: true,
                    showDialect: true,
                    disabledFields: this.disabledImportantFields
                  }
                }
              ]
            }, {
              components: [
                {
                  name: 'date-time-section',
                  params: {
                    disabledFields: this.disabledImportantFields
                  }
                }
              ]
            }, {
              components: [{
                name: 'subject-section'
              }]
            }, {
              components: [{
                name: 'assignment-type-section',
                params: {
                  disabledFields: this.disabledImportantFields,
                  impersonateRequests: true
                },
                classes: ['post-form__field', 'assignment-type__wrapper_rounded']
              }]
            }, {
              components: [{
                name: 'alternative-language-section',
                condition: true,
                classes: ['post-form__field']
              }]
            }, {
              components: [{
                name: 'gender-section',
                params: {
                  disabledFields: this.disabledImportantFields
                },
                classes: ['post-form__field']
              }]
            }, {
              components: [{
                name: 'qualification-section',
                condition: this.showQualificationSection,
                params: {
                  disabledFields: this.disabledImportantFields
                },
                classes: ['post-form__field']
              }]
            }
          ]
        };
      },
      managerInvitationsAndApplicationsSection() {
        return {
          title: this.$gettext('Invitations and applications'),
          lines: [
            {components: [
              {
                name: 'fai-admin-invite-system',
                condition: this.showInviteSystem,
                classes: ['invitation-system__wrapper'],
              },
            ]},
            {components: [
              {
                name: 'basic-job-info-table-section',
                condition: true,
                classes: ['post-form__field'],
              },
            ]},
            {components: [
              {
                name: 'find-interpreter-block',
                classes: ['interpreter-section__wrapper'],
                params: {
                  isCompactFAI: true,
                  onSuccessfulApply: () => {
                    // clear store refresh jobs grid
                    this.$store.commit('OneAssignmentStore/clearAssignmentStore');
                    this.$store.state.AllAssignmentsStore.updateManagerJobGrid = Date.now();
                  }
                }
              }
            ]}
          ]
        };
      },
      managerProcessRequirementEditSection() {
        return {
          title: this.$gettext('Admin tools'),
          lines: [
            {components: [
              {
                name: 'basic-job-info-table-section',
                condition: true,
                classes: ['post-form__field']
              },
            ]}, {
              components: [{
                name: 'auto-invite-info-section',
                condition: true,
                classes: ['post-form__field']
              }, {
                name: 'infinite-auto-invite-info-section',
                condition: true,
                classes: ['post-form__field']
              }, {
                name: 'auto-approve-info-section',
                condition: true,
                classes: ['post-form__field']
              }, {
                name: 'standby-info-section',
                condition: true,
                classes: ['post-form__field']
              }]
            }]
        };
      }
    },
    watch: {
      $route() {
        this.closeModal();
      }
    },
    methods: {
      closeModal() {
        this.$emit('closemodal');
      },
      errorHandlingFunc(error) {
        this.$store.commit('ModalStore/stopModalProgress');
        this.$store.commit('ModalStore/setModal', {
          component: 'assignment-info-edit-error-modal',
          classes: ['sk-modal--new'],
          data: {
            title: this.$gettext('Validation error'),
            errors: error
          }
        });
      },
      onCloseClick() {
        if (this.data.context && this.data.context[this.data.closeHandlingCallbackName]) {
          this.data.context[this.data.closeHandlingCallbackName]();
        }
      },
    },
    mounted() {
      const skModalCloseBtn = document.querySelector('.sk-modal__close-btn');
      if (skModalCloseBtn) {
        skModalCloseBtn.addEventListener('click', this.onCloseClick);
      }
    }
  };
</script>

<style>
  .payment-method.group-fields__field {
    margin-left: 15px;
  }

  .edit-modal .post-section {
    width: 100%;
    margin: 0;
  }

  .edit-modal .post-section__container {
    padding: 0 30px 10px;
    box-shadow: none;
  }

  .edit-modal .post-form__post-btn {
    width: auto;
    padding: 0 20px;
  }

  .assignment-type__wrapper_rounded {
    border-radius: 8px;
  }

  .interpreter-section__wrapper {
    width: auto;
    margin: 0 -15px;
  }

  .invitation-system__wrapper {
    width: fit-content;
    margin-right: 15px;
    margin-bottom: 20px;
    margin-left: auto;
  }

  @media (max-width: 768px) {
    .interpreter-section__wrapper {
      overflow-x: scroll;
    }
  }
</style>

<style scoped>

  .edit-modal {
    padding-bottom: 15px;
  }
</style>
