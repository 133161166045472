<template>
  <div v-if="showSendByPostTranslation"
       ref="sendByMailSection"
       class="send-by-mail__section"
       @focusin="openSendByMailHelper">
    <post-accordion-section :state="sendByPost"
                            :title="sendPerPostText"
                            :description="sendDocsText"
                            class="send-by-mail__accordion"
                            @statechanged="changeSendByMail">
      <div class="send-by-mail__wrapper">
        <div class="send-by-mail__warning-text">
          <p v-if="showSendByPostPayableTranslation"
             class="warning-text__red">{{ redWarningText }} </p>
        </div>
        <div class="send-by-mail__container">
          <div v-if="!showUploadCoverLetter"
               class="cover-letter-section">
            <p class="upload__radio-title">{{ uploadRadioText }}</p>
            <sk-radio v-for="(item, index) in radioButtonOptions"
                      :key="index"
                      :item-text="item.text"
                      :item-value="item.value"
                      :preselected-value="coverLetterAtached"
                      :item-name="'upload-radio'"
                      class="address__radio"
                      @radiochanged="catchCoverLetterAtachedChange" />
            <sk-upload-file v-if="coverLetterAtached"
                            :id="'coverLetter'"
                            :button-text="upploadCoverLetterTextPlaceholder"
                            :reset="fileInputReset"
                            :accept="'*'"
                            class="cover-letter-section__button"
                            @changefileinput="catchAttachment" />
          </div>
          <div v-if="showAttachmentsErrors"
               class="sk-input__errors-cont">
            <span v-for="(error, errorIndex) in attachmentsErrors"
                  :key="errorIndex"
                  v-html="(attachmentsErrors.length === 1) ? error : `${errorIndex + 1}. ${error}`"></span>
          </div>
          <!--            <p v-if="showCoverLetterWarning"-->
          <!--               class="cover-letter__warning">{{ $pgettext('translation', 'Cover letter is required') }}</p>-->
          <!--            <div v-if="showCoverLetterAttachments">-->
          <!--              <div v-for="(attachment, index) in project.coverLetters"-->
          <!--                   :key="index"-->
          <!--                   class="cover-letter-section__files">-->
          <!--                <button type="button"-->
          <!--                        class="cover-letter-section__remove-btn"-->
          <!--                        @click="deleteCoverLetter({index, id: attachment.id})"></button>-->
          <!--                <p class="cover-letter-section__file-name">{{ attachment.name }}</p>-->
          <!--              </div>-->
          <!--            </div>-->
          <div v-for="(attachment, index) in attachments"
               :key="index"
               class="cover-letter-section__files">
            <button type="button"
                    class="cover-letter-section__remove-btn"
                    @click="deleteAttachment({index, id: attachment.id})"></button>
            <p class="cover-letter-section__file-name">{{ attachment.name }}</p>
          </div>
          <div v-if="coverLetterAtached">
            <p class="address__radio-title">{{ radioText }}</p>
            <sk-radio v-for="(item, index) in radioButtonOptions"
                      :key="index"
                      :item-text="item.text"
                      :item-value="item.value"
                      :preselected-value="isAddressInLetter"
                      :item-name="'radio-address'"
                      class="address__radio"
                      @radiochanged="catchAddressInLetterChange" />
          </div>
          <div v-if="!isAddressInLetter"
               class="send-by-mail__inputs inputs-block">
            <p class="inputs-block__description">{{ inputsDescription }}</p>
            <p class="inputs-block__text">{{ $pgettext('translation', 'Return address') }}</p>
            <div class="inputs-block__fields">
              <sk-input :placeholder="nameTextPlaceholder"
                        :description="nameText"
                        :validation-name="errors.name.name"
                        :preselected-value="name"
                        @fieldvaluechanged="setName" />
              <sk-input :placeholder="companyTextPlaceholder"
                        :description="companyText"
                        :preselected-value="company"
                        :validation-name="errors.company.name"
                        @fieldvaluechanged="setCompany" />
              <sk-input :preselected-value="address"
                        :description="addressText"
                        :validation-name="errors.address.name"
                        :disabled="isAddressInLetter"
                        :placeholder="addressTextPlaceholder"
                        @fieldvaluechanged="setAddress" />
            </div>
          </div>
        </div>
      </div>
    </post-accordion-section>
  </div>
</template>

<script>

  import PostAccordionSection from '@/components/booking_components/PostAccordionSection';
  import {mapMutations, mapGetters, mapState} from 'vuex';

  export default {
    components: {
      'post-accordion-section': PostAccordionSection,
    },
    data() {
      return {
        deletedAttachments: true
      };
    },
    computed: {
      ...mapState('PostingStore/SendMailStore', {
        name: (state) => state.name,
        isAddressInLetter: (state) => state.isAddressInLetter,
        company: (state) => state.company,
        address: (state) => state.address,
        sendByPost: (state) => state.sendByPost,
        errors: (state) => state.errors
      }),
      ...mapState('PostingStore/CoverLetterStore', {
        fileInputReset: (state) => state.fileInputReset,
        coverLetterAtached: (state) => state.coverLetterAtached,
        // errors
        attachmentsErrors: (state) => state.errors.attachments.errors || []
      }),
      ...mapGetters('PostingStore/BookerStore', [
        'bookerData'
      ]),
      ...mapGetters('UserInfoStore', [
        'showSendByPostTranslation',
        'showSendByPostPayableTranslation',
        'userEnterpriseName'
        // 'translationCoverLetterRequired',
      ]),
      ...mapGetters('PostingStore/CoverLetterStore', [
        'attachments'
      ]),
      ...mapGetters('OneProjectStore', [
        'project'
      ]),
      addressText() { return this.$pgettext('translation', 'Address'); },
      preselectedAddress() {
        return this.project.sendByPostAddress ? this.project.sendByPostAddress : '';
      },
      addressTextPlaceholder() { return this.$pgettext('translation', 'Enter delivery address'); },
      uploadRadioText() {
        return this.$gettext('Do you have a cover letter that should be attached to the printed documents?');
      },
      radioText() {
        return this.$pgettext('translation', 'Is the address on the cover letter?');
      },
      showUploadCoverLetter() {
        if (this.project.status === 'quote_rejected' || this.project.status === 'in_progress' || this.project.status === 'finished' || this.project.status === 'cancelled') return true;
        return this.attachments.length;
      },
      showAttachmentsErrors() {
        return this.attachmentsErrors && this.attachmentsErrors.length && this.coverLetterAtached;
      },
      // showCoverLetterWarning() {
      //   return this.project.coverLetters && !this.deletedAttachments && !this.showUploadCoverLetter && this.translationCoverLetterRequired;
      // },
      // showCoverLetterAttachments() {
      //   const statuses = ['draft', 'quote_send', 'quote_requested', 'quote_accepted'];
      //   return this.project.coverLetters
      //     && this.project.coverLetters.length
      //     && !this.attachments.length
      //     && this.deletedAttachments
      //     && statuses.includes(this.project.status);
      // },
      inputsDescription() {
        return this.$gettext('Since the cover letter does not contain a return address, please provide the address below.');
      },
      upploadCoverLetterText() { return this.$pgettext('translation', 'If the cover letter contains an address, you do not need to add the address again.'); },
      upploadCoverLetterTextPlaceholder() { return this.$pgettext('translation', 'Upload cover letter'); },
      bookerFullName() {
        return (this.bookerData?.firstName && this.bookerData?.lastName) ? `${this.bookerData.firstName} ${this.bookerData.lastName}` : '';
      },
      radioButtonOptions() {
        return [
          {
            value: true,
            text: this.$gettext('Yes'),
          },
          {
            value: false,
            text: this.$gettext('No'),
          }
        ];
      },
      sendPerPostText() { return this.$pgettext('translation', 'Postage information'); },
      sendDocsText() { return this.$gettext('Add postage information to receive translated documents by mail.'); },
      warningText() {
        const template = this.$pgettext('translation', 'Add the address to which %{domain} will send the translated documents.');
        return this.$gettextInterpolate(template, {domain: this.$getString('domainData', 'platformName')});
      },
      redWarningText() { return this.$pgettext('translation', 'This service may incur an additional fee of 250 kr plus postage'); },
      nameText() { return this.$pgettext('translation', 'Name'); },
      nameTextPlaceholder() { return this.$pgettext('translation', 'Full Name'); },
      companyText() { return this.$pgettext('translation', 'Company'); },
      companyTextPlaceholder() { return this.$pgettext('translation', 'Company name'); }
    },
    methods: {
      ...mapMutations('PostingStore/SendMailStore', [
        'setName',
        'setCompany',
        'setAddress',
        'setSendByPost',
        'clearStore',
        'setIsAddressInLetter'
      ]),
      ...mapMutations('PostingStore/CoverLetterStore', [
        'setAttachments',
        'deleteAttachment',
        'setError',
        'removeErrors',
        'archiveFile',
        'setCoverLetterAtached'
      ]),
      ...mapMutations('PostAssignmentHelperStore', [
        'openHelper',
        'setContext',
        'setPopoverReference',
        'setReferenceToBuyerInfoSection'
      ]),
      openSendByMailHelper() {
        this.setContext('translation');
        this.setPopoverReference(this.$refs.sendByMailSection);
        this.openHelper('sendPerPost');
      },
      catchAttachment(target) {
        this.removeErrors();
        this.validFiles(target);
        if (target.files && target.files.length) {
          this.setAttachments(target);
        }
      },
      deleteCoverLetter() {
        this.archiveFile();
        this.deletedAttachments = false;
      },
      validFiles(target) {
        if (target.files.length >= 100 || this.attachments.length >= 100) {
          this.onError = this.$pgettext('translation', 'Max. file size: 128MB, Max. files: 100');
          return;
        }
        this.onError = '';
      },
      changeSendByMail(value) {
        if (!value) {
          this.setReferenceToBuyerInfoSection();
          this.openHelper('');
        }
        this.setName(this.bookerFullName);
        this.setCompany(this.userEnterpriseName);
        this.setSendByPost(value);
        if (!this.sendByPost) this.clearStore();
      },
      catchCoverLetterAtachedChange() {
        this.setCoverLetterAtached(!this.coverLetterAtached);
        // reset choice for adreess in letter
        if (!this.coverLetterAtached) {
          this.setIsAddressInLetter(false);
        }
      },
      catchAddressInLetterChange(value) {
        this.setIsAddressInLetter(value);
      },
      preselectedMailInfo() {
        if (!this.name) this.setName(this.bookerFullName);
        if (!this.company) this.setCompany(this.userEnterpriseName);
        if (!this.address) this.setAddress(this.preselectedAddress);
      }
    },
    mounted() {
      this.preselectedMailInfo();
    }
  };
</script>

<style scoped>
.post-accordion-section {
  padding: 20px 40px;
}

.post-accordion-section__title {
  margin-bottom: 15px;
  font-size: 18px;
}

.inputs-block__fields {
  display: flex;
  gap: 16px;
  margin-top: 8.5px;
}

.send-by-mail-form__btn {
  margin-bottom: 10px;
}

.send-by-mail__section {
  width: 100%;
}

.address__radio {
  margin: 10px 10px 10px 0;
}

.address__radio-title,
.upload__radio-title {
  margin-top: 20px;
  font-weight: bold;
}

.warning-text__red {
  margin-top: 20px;
  color: #f04;
}

.inputs-block__description {
  margin: 6px 0 20px 0;
}

.inputs-block__text {
  font-weight: bold;
}

.send-by-mail__warning-text {
  font-weight: bold;
}

.send-by-mail__wrapper {
  width: 100%;
}

.address-scope-select {
  margin-bottom: 10px;
}

.cover-letter__warning {
  color: #f00;
}

.cover-letter-section {
  align-items: center;
  justify-content: space-between;
  margin-top: 10px;
  margin-bottom: 10px;
}

.cover-letter-section__button {
  margin-top: 16px;
}

#cover-letter {
  min-width: 190px;
}

.cover-letter-section__files {
  position: relative;
  display: inline-flex;
  justify-content: flex-start;
  width: 100%;
  margin-top: 10px;
}

.cover-letter-section__remove-btn {
  display: block;
  width: 15px;
  margin-right: 10px;
  background-image: url(~@assets/imgs/undefined_imgs/trash_ico.svg);
  background-position: 50% 50%;
  background-size: 12px auto;
  background-repeat: no-repeat;
}

.cover-letter-section__remove-btn:active {
  background-color: #e1d7eb;
}
</style>
