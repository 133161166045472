const statistics = {
  methods: {
    getBookedInterpreters(enterpriseId, dateFrom, dateTo) {
      return new Promise((resolve, reject) => {
        this.ajax.get(`/v1/enterprises/${enterpriseId}/statistics_filters/booked_interpreters?date_from=${dateFrom}&date_to=${dateTo}`)
          .then((response) => {
            resolve(response?.data);
          })
          .catch((error) => {
            reject(error);
          });
      });
    },

    getBookedTranslators(enterpriseId, dateFrom, dateTo) {
      return new Promise((resolve, reject) => {
        this.ajax.get(`/v1/enterprises/${enterpriseId}/statistics_filters/booked_translators?date_from=${dateFrom}&date_to=${dateTo}`)
          .then((response) => {
            resolve(response?.data);
          })
          .catch((error) => {
            reject(error);
          });
      });
    },

    getStatistics(enterpriseId, groupBy, scope, filters) {
      const currentScope = scope === 'interpretation' ? 'job_statistics' : 'subtask_statistics';
      let path = `/v1/enterprises/${enterpriseId}/${currentScope}?group_by=${groupBy}`;

        if (filters.dateFrom) {
          const field = scope === 'interpretation' ? 'scheduled' : 'created';
          path += `&${field}_at_from=${filters.dateFrom}`;
        }

        if (filters.dateTo) {
          const field = scope === 'interpretation' ? 'scheduled' : 'created';
          path += `&${field}_at_to=${filters.dateTo}`;
        }

        if (filters.types && filters.types.length > 0) {
          const field = scope === 'interpretation' ? 'session' : 'subtask';
          filters.types.forEach((type) => {
            path += `&${field}_types[]=${type}`;
          });
        }

        if (filters.statuses && filters.statuses.length > 0) {
          const field = scope === 'interpretation' ? 'job' : 'subtask';
          filters.statuses.forEach((status) => {
            path += `&${field}_statuses[]=${status}`;
          });
        }

        if (filters.departments && filters.departments.length > 0) {
          filters.departments.forEach((department) => {
            path += `&department_ids[]=${department}`;
          });
        }

        if (filters.bookers && filters.bookers.length > 0) {
          filters.bookers.forEach((booker) => {
            path += `&booker_uids[]=${booker}`;
          });
        }

        if (filters.interpreters && filters.interpreters.length > 0) {
          const field = scope === 'interpretation' ? 'interpreter_uids' : 'translator_ids';
          filters.interpreters.forEach((interpreter) => {
            path += `&${field}[]=${interpreter}`;
          });
        }

        if (filters.languages && filters.languages.length > 0) {
          filters.languages.forEach((language) => {
            path += `&language_ids[]=${language}`;
          });
        }

        if (filters.sourceLanguage) {
          path += `&source_language_ids[]=${filters.sourceLanguage}`;
        }

        if (filters.targetLanguage) {
          path += `&target_language_ids[]=${filters.targetLanguage}`;
        }

        path += '&date_column=scheduled_at';

      return new Promise((resolve, reject) => {
        this.ajax.get(path, {headers: {Accept: 'application/json'}})
          .then((response) => {
            resolve(response?.data);
          })
          .catch((error) => {
            this.resolveError403(error, resolve);
            reject(error);
          });
      });
    },

    downloadStatistics(enterpriseId, groupBy, scope, filters) {
      const currentScope = scope === 'interpretation' ? 'job_statistics' : 'subtask_statistics';
      let downloadUrl = `/v1/enterprises/${enterpriseId}/${currentScope}.csv?group_by=${groupBy}`;

        if (filters.dateFrom) {
          const field = scope === 'interpretation' ? 'scheduled' : 'created';
          downloadUrl += `&${field}_at_from=${filters.dateFrom}`;
        }

        if (filters.dateTo) {
          const field = scope === 'interpretation' ? 'scheduled' : 'created';
          downloadUrl += `&${field}_at_to=${filters.dateTo}`;
        }

        if (filters.types && filters.types.length > 0) {
          const field = scope === 'interpretation' ? 'session' : 'subtask';
          filters.types.forEach((type) => {
            downloadUrl += `&${field}_types[]=${type}`;
          });
        }

        if (filters.statuses && filters.statuses.length > 0) {
          const field = scope === 'interpretation' ? 'job' : 'subtask';
          filters.statuses.forEach((status) => {
            downloadUrl += `&${field}_statuses[]=${status}`;
          });
        }

        if (filters.departments && filters.departments.length > 0) {
          filters.departments.forEach((department) => {
            downloadUrl += `&department_ids[]=${department}`;
          });
        }

        if (filters.bookers && filters.bookers.length > 0) {
          filters.bookers.forEach((booker) => {
            downloadUrl += `&booker_uids[]=${booker}`;
          });
        }

        if (filters.interpreters && filters.interpreters.length > 0) {
          const field = scope === 'interpretation' ? 'interpreter_uids' : 'translator_ids';
          filters.interpreters.forEach((interpreter) => {
            downloadUrl += `&${field}[]=${interpreter}`;
          });
        }

        if (filters.languages && filters.languages.length > 0) {
          filters.languages.forEach((language) => {
            downloadUrl += `&language_ids[]=${language}`;
          });
        }

        if (filters.sourceLanguage) {
          downloadUrl += `&source_language_ids[]=${filters.sourceLanguage}`;
        }

        if (filters.targetLanguage) {
          downloadUrl += `&target_language_ids[]=${filters.targetLanguage}`;
        }

        if (scope === 'interpretation') {
          downloadUrl += '&date_column=scheduled_at';
        }

      return new Promise((resolve, reject) => {
        this.ajax.get(downloadUrl)
          .then((response) => {
            resolve(response?.data);
          })
          .catch((error) => {
            this.resolveError403(error, resolve);
            reject(error);
          });
      });
    },
  }
};

export default statistics;
