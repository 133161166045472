(<template>
  <div class="post-form__section">
    <sk-note :text="personalInfoWarning"
             class="post-assignment__note" />
  </div>
</template>)

  <script>

  export default {
    computed: {
      personalInfoWarning() {
        return this.$gettext('Please do not provide any personal information while placing the order. This information is shared with several freelancers as they decide if they would like to take on the assignment.');
      }
    },
  };
  </script>

<style scoped>
    .post-form__section {
      margin-bottom: 15px;
    }

    .post-assignment__note {
      width: 100%;
    }
  </style>
