// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_GET_URL_IMPORT___ = require("../../../../node_modules/css-loader/dist/runtime/getUrl.js");
var ___CSS_LOADER_URL_IMPORT_0___ = require("@assets/imgs/undefined_imgs/new_edit_pencil_icon.svg");
var ___CSS_LOADER_URL_IMPORT_1___ = require("@assets/imgs/undefined_imgs/edit_enterprise.svg");
var ___CSS_LOADER_URL_IMPORT_2___ = require("@assets/imgs/undefined_imgs/rounded_new_edit_pencil_icon.svg");
var ___CSS_LOADER_URL_IMPORT_3___ = require("@assets/imgs/undefined_imgs/two_users_icon.svg");
exports = ___CSS_LOADER_API_IMPORT___(false);
var ___CSS_LOADER_URL_REPLACEMENT_0___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_0___);
var ___CSS_LOADER_URL_REPLACEMENT_1___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_1___);
var ___CSS_LOADER_URL_REPLACEMENT_2___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_2___);
var ___CSS_LOADER_URL_REPLACEMENT_3___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_3___);
// Module
exports.push([module.id, ".edit-pencil-btn,\n.edit-enterprise-btn,\n.edit-pencil-btn--outlined,\n.merge-customers-btn,\n.edit-pencil-btn--rounded {\n  width: 30px;\n  height: 30px;\n  border: 0;\n  border-radius: 50%;\n  background-color: #fff5;\n  background-image: url(" + ___CSS_LOADER_URL_REPLACEMENT_0___ + ");\n  background-image: var(--image-new-edit-pencil-icon);\n  background-position: 50% 50%;\n  background-size: 16px auto;\n  background-repeat: no-repeat;\n  -webkit-box-shadow: 0 1px 2px 0 rgba(0, 0, 0, 0.2);\n          box-shadow: 0 1px 2px 0 rgba(0, 0, 0, 0.2);\n}\n.edit-pencil-btn--outlined {\n  background-image: var(--image-outlined-edit-pencil-icon);\n}\n.edit-pencil-btn:focus,\n.sk-text-button:focus,\n.edit-enterprise-btn:focus,\n.edit-pencil-btn--rounded:focus,\n.merge-customers-btn:focus,\n.edit-pencil-btn--outlined:focus {\n  -webkit-box-shadow: var(--default-focus-shadow);\n          box-shadow: var(--default-focus-shadow);\n}\n.edit-pencil-btn:hover,\n.sk-text-button:hover,\n.edit-enterprise-btn:hover,\n.merge-customers-btn:hover,\n.edit-pencil-btn--rounded:hover,\n.edit-pencil-btn--outlined:hover {\n  background-color: #fbefea;\n  background-color: var(--color-primary-hover);\n  -webkit-box-shadow: none;\n          box-shadow: none;\n}\n.edit-pencil-btn:active,\n.edit-pencil-btn--rounded:active,\n.sk-text-button:active,\n.merge-customers-btn:active,\n.edit-enterprise-btn:active {\n  background-color: #f5dacc;\n  background-color: var(--color-primary-active-state);\n  -webkit-box-shadow: none;\n          box-shadow: none;\n}\n.edit-enterprise-btn {\n  background-image: url(" + ___CSS_LOADER_URL_REPLACEMENT_1___ + ");\n}\n.edit-pencil-btn--rounded {\n  background-image: url(" + ___CSS_LOADER_URL_REPLACEMENT_2___ + ");\n}\n.merge-customers-btn {\n  background-image: url(" + ___CSS_LOADER_URL_REPLACEMENT_3___ + ");\n}\n.sk-text-button {\n  padding: 5px;\n  border-radius: 4px;\n  color: var(--color-secondary);\n}\n", ""]);
// Exports
module.exports = exports;
