(<template>
  <div ref="departmentAttendersRef"
       class="post-form__section department-attender"
       @focusin="openDepartmentAttendersHelper">
    <div :class="{'in-progress': departmentsProgress,
                  'post-form__field--half-width': hideAttendersSection,
                  'post-form__field': !leftAlign,
                  'dashboard-section': !isModalSection}"
         class="department-attender__field is-progress-bar">
      <sk-select :items-list="filteredDepartmentsList"
                 :preselected-value="department"
                 :disabled="!showDepartmentSelect"
                 :description="departmentDescription"
                 :default-value="departmentDefaultValue"
                 :marked-items="departmentsBasedBookerList"
                 :preview-value="getPreviewDepartment"
                 :validation-name="departmentErrorsName"
                 class="department-attender__select"
                 @csvaluechanged="selectDepartment" />
      <button v-if="params.showEditDepartment && showDepartmentSelect && !isEmailVerificationRequired"
              :class="{'sk-select--without-margin': leftAlign }"
              class="sk-select--edit-btn"
              @click.prevent.stop="openEditDepartmentModal"></button>
      <button v-if="!params.hideAddDepartment && !isEmailVerificationRequired"
              :class="{'sk-select--without-margin': leftAlign }"
              class="sk-select-btn"
              @click.prevent.stop="openAddDepartmentModal"></button>
    </div>
    <div v-if="showAttendersSelect && showOnProjects && hideAttendersSection"
         :class="{'in-progress': attendersProgress || departmentsProgress}"
         class="post-form__field post-form__field--half-width department-attender__field is-progress-bar">
      <sk-multiselect :items-list="computedAttendersList"
                      :preselected-value="attenders"
                      :description="attendersDescription"
                      :default-value="attendersDefaultValue"
                      :preview-value="getPreviewAttender"
                      :validation-name="attendersListValidation"
                      class="department-attender__select"
                      :class="{'department-attender__select--small': params.showEditAttenders && !showAttendersEditPreview}"
                      :show-preview-edit="showAttendersEditPreview"
                      @editpreviewitem="openEditEmployee"
                      @checkboxeschanged="setAttenders" />
      <button v-if="params.showEditDepartment"
              class="sk-select--edit-btn sk-select-btn__other-contact"
              @click.prevent.stop="openEditEmployee"></button>
      <button v-if="!params.hideAddAttenders"
              class="sk-select-btn sk-select-btn__other-contact"
              @click.prevent.stop="openAddEmployeeModal"></button>
    </div>
  </div>
</template>)

<script>
  import {mapActions, mapGetters, mapMutations, mapState} from 'vuex';
  import helpers from '@/helpers';

  export default {
    props: {
      params: {
        type: Object,
        default() {
          return {};
        },
        showAssignmentHelper: {
          type: Boolean,
        }
      }
    },
    computed: {
      ...mapGetters('UserInfoStore', [
        'showOnlyOwnDepartmentsOnPosting',
        'isEmailVerificationRequired'
      ]),
      ...mapGetters('PostingNotifierStore', [
        'showDepartmentSelect',
        'showAttendersSelect'
      ]),
      ...mapState('EnterpriseStore', {
        employees: (state) => state.employees || [],
      }),
      ...mapState('PostingStore/DepartmentAttendersStore', {
        department: (state) => state.department,
        attenders: (state) => state.attenders,
        departmentsList: (state) => state.departmentsList || [],
        attendersList: (state) => state.attendersList || [],
        departmentsProgress: ({departmentsProgress}) => departmentsProgress,
        attendersProgress: ({attendersProgress}) => attendersProgress,
        showOnProjects: (state) => state.showAttenders,
        // errors name
        departmentErrorsName: (state) => state.errors.department.name,
        attendersListValidation: (state) => state.errors.attenders.name
      }),
      ...mapGetters('EnterpriseStore', ['getOwnerUid']),
      departmentDescription() { return (this.params.departmentDescription === null || this.params.departmentDescription === undefined) ? this.$gettext('Department') : this.params.departmentDescription; },
      departmentDefaultValue() { return this.$gettext('Select department'); },
      attendersDescription() { return this.$gettext('Other contact person (gets copy)'); },
      attendersDefaultValue() { return this.$gettext('Choose contact person'); },
      departmentObj() {
        return this.departmentsList.length
          ? this.departmentsList.find((department) => department.id === this.department)
          : {};
      },
      computedAttendersList() {
        if (this.attendersList.length) {
          return this.attendersList.map((att) => {
            return att.firstName && att.lastName ? {
              id: att.uid,
              name: `${att.firstName} ${att.lastName}`
            } : {
              id: att.uid,
              name: att.email
            };
          });
        }
        return [];
      },
      departmentsBasedBookerList() {
        const bookerData = this.$store.getters['PostingStore/BookerStore/bookerData'] || {};
        const bookerDepartments = bookerData.departments || [];
        return bookerDepartments.map((dep) => {
          return {
            id: dep.id,
            name: dep.name
          };
        }).sort((a, b) => {
          if (a.name.toLowerCase() < b.name.toLowerCase()) { return -1; }
          if (a.name.toLowerCase() > b.name.toLowerCase()) { return 1; }
          return 0;
        }) || [];
      },
      filteredDepartmentsList() {
        const otherDepartmentsList = this.showOnlyOwnDepartmentsOnPosting && this.departmentsBasedBookerList.length
          ? []
          : helpers.excludeArrayFromBaseArray({
            baseArr: this.departmentsList,
            excludeArr: this.departmentsBasedBookerList
          });
        return [
          ...this.departmentsBasedBookerList,
          ...otherDepartmentsList
        ];
      },
      showAttendersEditPreview() {
        return this.attenders && this.attenders.length >= 2;
      },
      openEditModal() { return this.params.openEditModal; },
      impersonateRequests() { return this.params.impersonateRequests || false; },
      headers() { return {AuthImpersonationUID: this.getOwnerUid}; },
      hideAttendersSection() { return !this.params.hideContactPerson; },
      leftAlign() { return this.params.leftAlign; },
      showAssignmentHelper() { return this.params.showAssignmentHelper; },
      isModalSection() { return this.params.isModalSection; }
    },
    methods: {
      ...mapActions('PostingStore/DepartmentAttendersStore', [
        'fetchAttenders',
        'fetchDepartments',
        'changeDepartment'
      ]),
      ...mapMutations('PostingStore/DepartmentAttendersStore', [
        'setAttenders'
      ]),
      ...mapMutations('PostAssignmentHelperStore', [
        'openHelper',
        'setPopoverReference',
      ]),
      openDepartmentAttendersHelper() {
        if (this.showAssignmentHelper) {
          this.setPopoverReference(this.$refs.departmentAttendersRef);
          this.openHelper('department');
        }
      },
      getPreviewAttender(selectedData) {
        if (selectedData) {
          const selectedItem = this.attendersList.find((item) => item.uid == selectedData) || {};
          return helpers.getSelectedPreview(selectedItem);
        }
      },
      getPreviewDepartment(selectedData) {
        if (selectedData && selectedData.id) {
          const selectedItem = this.departmentsList.find((item) => item.id == selectedData.id) || {};
          return helpers.getSelectedPreview(selectedItem);
        }
      },
      preselectDataAfterAddingMember(employeeUid) {
        this.fetchAttenders(this.department).then((data) => {
          const attendersList = data || [];
          const createdAttender = attendersList.find(({uid}) => uid == employeeUid);
          if (createdAttender) {
            this.setAttenders([...this.attenders, employeeUid]);
          }
        });
      },
      selectDepartment(department) {
        this.changeDepartment({department, headers: this.impersonateRequests ? this.headers : {}});
      },
      openAddEmployeeModal() {
        this.$store.commit('EnterpriseStore/setDepartments', {departments: this.departmentsList});
        this.$store.commit('ModalStore/setModal', {
          component: 'add-employee',
          width: 410,
          data: {
            title: this.$gettext('New employee'),
            context: this,
            successHandlingCallbackName: 'preselectDataAfterAddingMember'
          }
        });
      },
      openEditEmployee(uid) {
        const employee = this.showAttendersEditPreview
          ? this.employees.find((employee) => employee.uid === uid)
          : this.employees.find((employee) => employee.uid === this.attenders[0]);
        this.$store.commit('ModalStore/setModal', {
          component: 'add-employee',
          width: 410,
          data: {
            title: this.$gettext('Edit employee'),
            currentUser: employee,
            context: this
          }
        });
      },
      preselectDataAfterAddingDepartment(department) {
        if (this.openEditModal) this.$store.dispatch('PostingStore/DepartmentAttendersStore/changeDepartment', {department});
        this.fetchDepartments();
        if (this.openEditModal) this.openEditModal();
      },
      openAddDepartmentModal() {
        const openModal = () => {
          this.$store.commit('ModalStore/setModal', {
            component: 'add-department',
            width: 410,
            data: {
              title: this.$gettext('New department'),
              context: this,
              successHandlingCallbackName: 'preselectDataAfterAddingDepartment'
            }
          });
        };

        if (!this.$store.state.EnterpriseStore.addresses) {
          this.$store.commit('ModalStore/startModalProgress');
          this.$store.dispatch('EnterpriseStore/getAddresses')
            .then(() => {
              this.$store.commit('ModalStore/stopModalProgress');
              openModal();
            });
        } else {
          openModal();
        }
      },
      openEditDepartmentModal() {
        this.$store.commit('ModalStore/setModal', {
          component: 'add-department',
          width: 410,
          data: {
            currentDepartment: {
              ...this.departmentObj
            },
            title: this.$gettext('Edit department'),
            context: this,
            successHandlingCallbackName: 'preselectDataAfterAddingDepartment'
          }
        });
      },
    }
  };
</script>

<style scoped>
.department-attender__field {
  display: flex;
}

.dashboard-section {
  max-width: 400px;
}

.department-attender__select {
  flex-grow: 1;
  width: calc(100% - 35px);
  margin-bottom: 0;
}

.department-attender__select--small {
  width: calc(100% - 50px);
}

.sk-select--edit-btn,
.sk-select-btn {
  margin-top: 21.5px;
}

@media (max-width: 767px) {
  .sk-select-btn,
  .sk-select--edit-btn {
    margin-top: 19px;
  }

  .sk-select-btn__other-contact {
    margin-top: 20px;
  }
}

@media (max-width: 550px) {
  .dashboard-section {
    max-width: none;
  }
}

.sk-select--without-margin {
  margin-top: 0;
}
</style>
