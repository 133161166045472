(<template>
  <div class="tt-avatar"
       :style="compAvatar"
       @click="emitClick"
       @mouseleave="emitMouseLeave"
       @mouseover="emitMouseOver"
       @touchstart="emitTouchStart"
       @touchend="emitTouchEnd"></div>
</template>)

<script>
  import {mapGetters} from 'vuex';

  export default {
    props: {
      avatar: {
        type: String,
        default: ''
      },
      own: Boolean,
      rotation: {
        type: [String, Number],
        default: 0
      }
    },
    computed: {
      ...mapGetters({
        userAvatar: 'UserInfoStore/userAvatar'
      }),
      compAvatar() {
        const styleObj = {};
        if (this.avatar) {
          styleObj.backgroundImage = `url(${this.avatar})`;
        } else if (this.own && this.ownAvatar) {
          styleObj.backgroundImage = `url(${this.ownAvatar})`;
        }
        if (this.rotation) {
          styleObj.transform = `rotate(${this.rotation}deg)`;
        }
        return styleObj;
      },
      ownAvatar() {
        return this.userAvatar || '';
      }
    },
    methods: {
      emitClick(eventArg) { this.$emit('avatarclick', eventArg); },
      emitMouseLeave(eventArg) { this.$emit('mouseleave', eventArg); },
      emitMouseOver(eventArg) { this.$emit('mouseover', eventArg); },
      emitTouchStart(eventArg) { this.$emit('touchstart', eventArg); },
      emitTouchEnd(eventArg) { this.$emit('touchend', eventArg); },
    }
  };
</script>

<style scoped>
  .tt-avatar {
    display: inline-block;
    border-radius: 50%;
    background-image: url(~@assets/imgs/undefined_imgs/user_icon.svg);
    background-position: 50% 50%;
    background-clip: content-box;
    background-size: cover;
    background-repeat: no-repeat;
    box-shadow: 0 1px 3px 0 rgba(0, 0, 0, 0.1), 0 1px 2px 0 rgba(0, 0, 0, 0.2);
  }
</style>
