import BookingHelper from '@/store/booking_stores/BookingHelper';

export default ({$fetcher: fetchData} = {}) => {
  return {
    namespaced: true,
    state: {
      categoriesList: [],
      translationCategoriesList: [],
      allCategories: [],
      category: '',
      subject: '',
      errors: {
        category: {name: 'categoryError', errors: []},
        subject: {name: 'subjectError', errors: []}
      }
    },
    actions: {
      getCategories({commit, rootGetters}, data) {
        return new Promise((resolve, reject) => {
          let promise = '';
          const enterpriseId = rootGetters['UserInfoStore/enterpriseId'] || rootGetters['EnterpriseStore/getManagerJobEnterpriseId'];
          if (data && data.isOpenBooking) {
            promise = fetchData.getOpenCategories(data.enterpriseId || '', data.departmentId || '', data.organizationName || '');
          } else if (data && data.headers) {
            promise = fetchData.getCategories(enterpriseId, data.department, data.headers);
          } else if (rootGetters['UserInfoStore/enterpriseId']) {
            promise = fetchData.getCategories(rootGetters['UserInfoStore/enterpriseId'], data);
          } else {
            promise = '';
          }

          if (promise) {
            promise.then((data) => {
              if (data.code == 401) {
                commit('TTAuthStore/logOut', '', {root: true});
                commit('GlobalStore/remove404Page', '', {root: true});
                resolve('LOGOUT');
                return;
              }
              commit('setCategories', data.categories);
              resolve();
            }).catch((response) => {
              reject(response);
            });
          } else {
            resolve();
          }
        });
      },
      getTranslationCategories({commit}) {
        return new Promise((resolve, reject) => {
          fetchData.getTranslationCategories().then((data) => {
            commit('setTranslationCategories', data.translationMethods);
            resolve(data);
          }).catch((error) => {
            reject(error);
          });
        });
      },
      getAllCategories({commit}) {
        return new Promise((resolve, reject) => {
          fetchData.getAllCategories().then((data) => {
            commit('setAllCategories', data.jobCategories);
            resolve(data);
          }).catch((error) => {
            reject(error);
          });
        });
      },
      getCustomerCategories({commit}, {enterpriseId, departmentId, headers}) {
        return new Promise((resolve, reject) => {
          fetchData.getCategories(enterpriseId, departmentId, headers).then((data) => {
            commit('setCategories', data.categories);
            resolve(data);
          }).catch((error) => {
            reject(error);
          });
        });
      },
    },
    mutations: {
      ...BookingHelper().mutations,
      setCategories(state, data) {
        state.categoriesList = data;
      },
      setTranslationCategories(state, data) {
        state.translationCategoriesList = [...data, {id: 'other', name: 'Other'}];
      },
      setAllCategories(state, data) {
        state.allCategories = data;
      },
      setSubject(state, value) {
        state.subject = value;
      },
      setCategory(state, value) {
        state.category = value;
      },
      clearStore(state) {
        state.subject = '';
        state.category = '';
        state.categoryList = '';
      }
    }
  };
};
