(<template>
  <article class="post-assign-summary">
    <summary-info :summary-list="summaryList"
                  :has-attachments="data.showFileUploadCheckbox && filesAttached > 0" />
    <sk-checkbox v-if="showFileUploadCheckbox"
                 :preselected-value="confirmNoFile"
                 :item-text="confirmNoFileText"
                 class="post-assign-summary__confirm-checkbox"
                 @checkboxvaluechanged="catchConfirmNoFile" />
    <div class="post-assign-summary__btns-cont">
      <button ref="summaryModalBackBtn"
              class="sk-btn sk-btn--white post-assign-summary__btn post-assign-summary__btn--back"
              @click="onCloseClick">{{ $gettext('Back') }}</button>
      <button class="sk-btn sk-btn--default post-assign-summary__btn"
              :disabled="submitDisabled"
              @click="postJob">{{ data.btnText || defaultBtnText }}</button>
    </div>
  </article>
</template>)

<script>
  import {mapGetters, mapState} from 'vuex';
  import SummaryInfo from '@/components/booking_components/SummaryInfo';
  import helpers from '@/helpers';

  export default {
    components: {
      'summary-info': SummaryInfo
    },
    props: {
      data: {
        type: Object,
        default() {
          return {};
        }
      }
    },
    data() {
      return {
        confirmNoFile: false
      };
    },
    computed: {
      ...mapState('EnterpriseStore', {
        employees: (state) => state.employees || []
      }),
      ...mapGetters('TTAuthStore', [
        'punchOutRedirectUrl'
      ]),
      ...mapGetters('PostingStore/PaymentMethodStore', [
        'currentPaymentMethodData'
      ]),
      defaultBtnText() {
        return this.punchOutRedirectUrl
          ? this.$pgettext('punchout', 'Confirm order')
          : this.$gettext('Confirm order');
      },
      isInPersonOrder() {
        return this.$route.meta.assignmentType === 'in_person';
      },
      showFileUploadCheckbox() {
        return this.data.showFileUploadCheckbox && !this.filesAttached && !this.isInPersonOrder;
      },
      bookingRegExp() { return new RegExp(this.currentPaymentMethodData.bookingReferenceRegex || ''); },
      paymentBookingRegExp() { return new RegExp(this.currentPaymentMethodData.paymentBookingReferenceRegex || ''); },
      defaultRegExpHint() {
        const template = this.$gettext('%{orgname} requires a specific payment booking reference format. The text you have provided does not match.');
        return this.$gettextInterpolate(template, {orgname: this.currentPaymentMethodData.orgName});
      },
      bookingRegExpHint() {
        if (!this.bookingRegExp.test(this.bookingRef)) {
          return this.currentPaymentMethodData.bookingReferenceRegexHint || this.defaultRegExpHint;
        }
        return null;
      },
      paymentBookingRegExpHint() {
        if (!this.paymentBookingRegExp.test(this.paymentBookingRef)) {
          return this.currentPaymentMethodData.paymentBookingReferenceRegexHint || this.defaultRegExpHint;
        }
        return null;
      },
      summaryList() {
        return [{
          title: this.$gettext('My information'),
          lines: [
            {text: this.$gettext('Organization'), value: this.organization},
            {text: this.$gettext('First name'), value: this.firstName},
            {text: this.$gettext('Last name'), value: this.lastName},
            {text: this.$gettext('Org. number'), value: this.orgNumber},
            {text: this.$gettext('Booker'), value: this.booker},
            {text: this.$gettext('Real name'), value: this.ownerRealName},
            {text: this.$gettext('Department'), value: this.department},
            {text: this.$gettext('Other contact person (gets copy)'), value: this.chosenAttenders},
            {text: this.$gettext('Email'), value: this.email},
            {text: this.$gettext('Phone number'), value: this.phoneNumber}
          ]
        }, {
          title: this.$gettext('Assignment information'),
          lines: [
            {text: this.$gettext('Direct interpreter'), value: this.directInterpreterName},
            {value: this.getJobProcessingName('directProcessing')},
            {text: this.$gettext('Language'), value: this.languagePair},
            {text: this.$gettext('Foreign language'), value: this.foreignLanguage},
            {text: this.$gettext('To languages'), value: this.targetLanguageNames},
            {value: this.getSeveralInterpretersProcessingName()},
            {text: this.$gettext('Alternative language'), value: this.alternativeLanguage},
            {text: this.$gettext('Start at'), value: this.startAt},
            {text: this.$gettext('Finish at'), value: this.finishAt},
            {text: this.$gettext('Date(s)'), value: this.dates},
            {value: this.showSeriesJobProcessingText},
            {text: this.$pgettext('translation', 'Deadline'), value: this.deadline},
            {text: this.$gettext('Category'), value: this.category},
            {text: this.$gettext('Subject'), value: this.subject},
            {text: this.$gettext('Dialect'), value: this.dialect},
            {text: this.$gettext('Name of client / patient to be contacted'), value: this.jobInfo.secretaryContactName},
            {
              text: this.$gettext('Client\'s / patient\'s telephone number'),
              value: this.jobInfo.secretaryContactPhone
                ? `${this.jobInfo.secretaryContactPhoneCode}${this.jobInfo.secretaryContactPhone}`
                : ''
            },
            {text: this.$gettext('Information for the interpreter'), value: this.jobInfo.secretaryInfo},
            {
              text: this.$gettext('The interpreter to call me when finished on'),
              value: this.jobInfo.secretaryCallbackPhone
                ? `${this.jobInfo.secretaryCallbackPhoneCode}${this.jobInfo.secretaryCallbackPhone}`
                : ''
            },
            {text: this.$gettext('Direct interpreter(s) at my address'), value: this.sameLocationSuppliersList},
            {text: this.$gettext('Gender'), value: this.gender},
            {value: this.getJobProcessingName('genderProcessing')},
            {text: this.$gettext('Qualification'), value: this.qualification},
            {value: this.getJobProcessingName('qualificationProcessing')},
            {text: this.$gettext('Assignment type'), value: this.interpretationType},
            {value: this.getJobProcessingName('sessionTypeProcessing')},
            {text: this.$gettext('Video system'), value: this.videoSystem},
            {text: this.$gettext('Video system instructions'), value: this.videoSystemInstructions},
            {text: this.$gettext('Contact person'), value: this.contactPersonName},
            {text: this.$gettext('Contact phone number'), value: this.contactPersonPhone},
            {text: this.$gettext('Contact address info'), value: this.savedContactAddress},
            {text: this.$gettext('Address'), value: this.contactAddress},
            {text: this.$gettext('Directions'), value: this.contactWayDescription},
            {text: this.$gettext('Blocked interpreters'), value: this.blockedSuppliers},
            {text: this.$gettext('Blocked cities'), value: this.blockedCities},
            {text: this.$gettext('CC emails'), value: this.participantsEmails},
            {text: this.$gettext('CC phone numbers'), value: this.participantsPhones},
            {text: this.$gettext('Message for the interpreter'), value: this.extraInfo},
            {text: this.$gettext('Information for our translation team'), value: this.description},
            {text: this.$gettext('Details'), value: this.details},
            {text: this.$gettext('Uploaded file(s)'), value: this.attachments}
          ]
        }, {
          title: this.$gettext('Invoice Information'),
          lines: [
            {text: this.$store.getters['UserInfoStore/labelForBookingRef'] || this.$gettext('Booking ref.'), value: this.bookingRef},
            {value: this.bookingRegExpHint},
            {text: this.$store.getters['UserInfoStore/labelForPaymentBookingRef'] || this.$gettext('Attestant BID'), value: this.paymentBookingRef},
            {value: this.paymentBookingRegExpHint},
            {text: this.$store.getters['UserInfoStore/labelForCaseNumber'] || this.$gettext('Case number'), value: this.caseNumber},
            {text: this.$gettext('Client name'), value: this.jobInfo.clientName},
            {text: this.$gettext('Client ID'), value: this.jobInfo.clientId},
            {text: this.$gettext('Send invoice to'), value: this.jobInfo.paymentMethodName},
            {
              text: this.$gettext('Send invoice to'),
              value: helpers.getJobInfo.getPaymentMethodName(this, {
                paymentMethodData: this.paymentMethodData
              })
            },
          ]
        }, {
          title: this.$gettext('Post Information'),
          lines: [
            {text: this.$gettext('Full Name'), value: this.jobInfo.name},
            {text: this.$gettext('Company'), value: this.jobInfo.company},
            {text: this.$gettext('Address'), value: this.jobInfo.address}
          ]
        }, {
          title: this.$gettextInterpolate(this.$gettext('%{domain} - Admin tools'), {
            domain: this.$getString('domainData', 'platformName')
          }),
          isAdminSection: true,
          lines: [
            {text: this.$gettext('Order date / time'), value: this.actualCreatedAt},
            {text: this.$gettext('Booking mechanism'), value: helpers.getJobInfo.jobBookingMechanism(this, this.jobInfo.bookingMechanism)},
            {text: this.$gettext('Manual fees'), value: this.manualFees},
            {text: this.$gettext('Confirmation by phone'), value: this.confirmationByPhone},
            {text: this.$gettext('Confirm booking by'), value: this.confirmationBy},
            {text: this.$gettext('Auto invite'), value: this.getToggleValue(this.processRequirement.autoInvite)},
            {text: this.$gettext('Forever'), value: this.getToggleValue(this.processRequirement.infiniteAutoInvite)},
            {text: this.$gettext('Auto award'), value: this.getToggleValue(this.processRequirement.autoApprove)},
            {text: this.$gettext('Stand by'), value: this.getStandByValue(this.processRequirement.standBy)},
            {text: this.$gettext('Custom price templates'), value: this.customTemplates},
          ]
        }];
      },
      jobInfo() { return this.data.job || {}; },
      contactAttributes() { return this.data.job.contactAttributes || {}; },
      processRequirement() { return this.data.job.processRequirement || {}; },
      showSeriesJobProcessingText() {
        if (this.dates && this.dates.length >= 1) return this.getJobProcessingName('seriesJobProcessing');
        else return false;
      },
      confirmationByPhone() {
        if (this.jobInfo.confirmationByPhone !== undefined) {
          return this.jobInfo.confirmationByPhone.toString();
        }
      },
      actualCreatedAt() {
        return this.jobInfo.actualCreatedAt ? this.$moment(this.jobInfo.actualCreatedAt).format('DD.MM.YYYY HH:mm') : '';
      },
      customTemplates() {
        const templates = [];
        const {
          demanderInternalTemplate: bInternalTemplateId,
          demanderInternalPriceTemplatesList: bInternalTemplates = [],
          demanderExternalTemplate: bExternalTemplateId,
          demanderExternalPriceTemplatesList: bExternalTemplates = [],
          supplierHonorarTemplate: sHonorarTemplateId,
          supplierHonorarPriceTemplatesList: sHonorarTemplates = [],
          supplierCompanyTemplate: sCompanyTemplateId,
          supplierCompanyPriceTemplatesList: sCompanyTemplates = [],
          supplierEmployeeTemplate: sEmployeeTemplateId,
          supplierEmployeePriceTemplatesList: sEmployeeTemplates = []
        } = this.jobInfo.customPriceTemplates || {};
        const bInternalTemplate = bInternalTemplates.find((pt) => pt.id == bInternalTemplateId)?.name;
        const bExternalTemplate = bExternalTemplates.find((pt) => pt.id == bExternalTemplateId)?.name;
        const sHonorarTemplate = sHonorarTemplates.find((pt) => pt.id == sHonorarTemplateId)?.name;
        const sCompanyTemplate = sCompanyTemplates.find((pt) => pt.id == sCompanyTemplateId)?.name;
        const sEmployeeTemplate = sEmployeeTemplates.find((pt) => pt.id == sEmployeeTemplateId)?.name;

        if (bInternalTemplate) templates.push(this.$gettextInterpolate(this.$gettext('Customer Internal: %{bInternalTemplate}'), {bInternalTemplate}));
        if (bExternalTemplate) templates.push(this.$gettextInterpolate(this.$gettext('Customer External: %{bExternalTemplate}'), {bExternalTemplate}));
        if (sHonorarTemplate) templates.push(this.$gettextInterpolate(this.$gettext('Supplier Honorar: %{sHonorarTemplate}'), {sHonorarTemplate}));
        if (sCompanyTemplate) templates.push(this.$gettextInterpolate(this.$gettext('Supplier Company: %{sCompanyTemplate}'), {sCompanyTemplate}));
        if (sEmployeeTemplate) templates.push(this.$gettextInterpolate(this.$gettext('Supplier Employee: %{sEmployeeTemplate}'), {sEmployeeTemplate}));
        return templates.length ? templates : '';
      },
      paymentMethodData() {
        const paymentMethodObj = this.jobInfo.paymentMethod || {};
        const paymentMethods = paymentMethodObj.paymentMethodsList || [];
        return paymentMethods.find((payment) => payment.id == paymentMethodObj.paymentMethodId) || {};
      },
      manualFees() {
        const fees = [];

        for (const key of Object.keys(this.jobInfo.manualFees || {})) {
          if (this.jobInfo.manualFees[key]) fees.push(helpers.getJobInfo.jobManualFee(this, key));
        }
        return fees.length ? fees : '';
      },
      subject() { return this.jobInfo.subject; },
      dialect() { return this.jobInfo.dialect; },
      details() { return this.jobInfo.details; },
      organization() { return this.jobInfo.organization || ''; },
      firstName() { return this.jobInfo.firstName || ''; },
      lastName() { return this.jobInfo.lastName || ''; },
      phoneNumber() { return this.jobInfo.phoneNumber || ''; },
      email() { return this.jobInfo.email || ''; },
      extraInfo() { return this.jobInfo.extra; },
      directInterpreterName() { return this.jobInfo.directInterpreterName; },
      category() {
        const categoryObj = this.jobInfo.category;
        if (categoryObj) {
          const categoryId = categoryObj.categoryId;
          const categoriesList = categoryObj.categoriesList || [];
          if (categoryId && categoriesList.length) {
            const categories = this.getFormattedCategories(categoriesList);
            const selectedCategory = categories.find((cat) => cat.id == categoryId) || {};
            return selectedCategory.name;
          }
        }
      },
      sameLocationSuppliersList() {
        const interpreterList = this.jobInfo.sameLocationSuppliersList || [];
        const selectedInterpretersList = interpreterList.filter((interpreter) => interpreter.selected) || [];

        return selectedInterpretersList.length
          ? selectedInterpretersList.map((interpreter) => interpreter.name)
          : '';
      },
      ownerRealName() {
        return this.jobInfo.ownerRealName;
      },
      department() {
        const departmentObj = this.jobInfo.department;
        if (departmentObj) {
          const departmentId = departmentObj.departmentId;
          const departmentsList = departmentObj.departmentsList || [];
          if (departmentId && departmentsList) {
            const department = departmentsList.find((dep) => dep.id == departmentId) || {};
            return department.name;
          }
        }
      },
      chosenAttenders() {
        const attendersObj = this.jobInfo.attenders;
        if (attendersObj) {
          const attendersList = attendersObj.attendersList || [];
          const attenders = attendersObj.attenderIds || [];
          if (attenders.length && attendersList.length) {
            const res = [];
            for (const att of attendersList) {
              if (attenders.indexOf(att.uid) != -1) {
                res.push(att.firstName ? `${att.firstName} ${att.lastName}` : att.email);
              }
            }
            return res;
          }
        }
      },
      blockedSuppliers() {
        const {blockedSuppliers} = this.jobInfo;
        if (blockedSuppliers && blockedSuppliers.length) { return blockedSuppliers.map((supplier) => supplier.name); }
      },
      blockedCities() {
        const {blockedCities} = this.jobInfo;
        if (blockedCities && blockedCities.length) { return blockedCities.map((city) => city.name); }
      },
      confirmationBy() {
        const {confirmationBy} = this.jobInfo;
        return confirmationBy ? this.$moment(confirmationBy).format('DD.MM.YYYY HH:mm') : '';
      },
      languagePair() {
        const langFrom = this.jobInfo.languageFromId;
        const langTo = this.jobInfo.languageToId;
        if (langTo) return langFrom && langTo ? `${this.$getLangName(langFrom)} - ${this.$getLangName(langTo)}` : '';
        else return this.$getLangName(langFrom);
      },
      alternativeLanguage() {
        const alternativeLanguage = this.jobInfo.alternativeLanguageToId;
        return alternativeLanguage ? this.$getLangName(alternativeLanguage) : '';
      },
      foreignLanguage() {
        const foreignLanguage = this.jobInfo.foreignLanguage;
        return foreignLanguage ? this.$getLangName(foreignLanguage) || this.$gettext('Not important') : '';
      },
      targetLanguageNames() {
        return this.jobInfo.targetLanguageIds?.map((langId) => {
          return this.$getLangName(langId);
        });
      },
      dates() {
        const dates = this.jobInfo.dateTimes || [];

        if (dates.length) {
          const mainDate = this.startDateTime && this.finishDateTime ? [`${this.startDateTime} - ${this.finishDateTime}`] : '';
          const formattedDates = dates.map((date = {}) => {
            return `${this.$moment(date.startTime).format('DD.MM.YYYY HH:mm')} - ${this.$moment(date.finishTime).format('DD.MM.YYYY HH:mm')}`;
          });
          return [
            ...mainDate,
            ...formattedDates
          ];
        } else {
          return '';
        }
      },
      startDateTime() {
        return this.getDateTime(this.jobInfo.startTime);
      },
      startAt() {
        return this.dates ? '' : this.startDateTime;
      },
      finishDateTime() {
        return this.getDateTime(this.jobInfo.finishTime);
      },
      finishAt() {
        return this.dates ? '' : this.finishDateTime;
      },
      deadline() {
        const {deadline} = this.jobInfo;
        return deadline ? this.$moment(deadline).format('DD.MM.YYYY HH:mm') : '';
      },
      bookingRef() { return this.jobInfo.bookingRef; },
      orgNumber() { return this.jobInfo.orgNumber; },
      booker() {
        const booker = this.employees.find((att) => att.uid === this.jobInfo.booker) || {};
        return booker.firstName || booker.lastName ? `${booker.firstName} ${booker.lastName}` : booker.email;
      },
      paymentBookingRef() { return this.jobInfo.paymentBookingRef; },
      caseNumber() { return this.jobInfo.caseNumber; },
      description() { return this.jobInfo.description; },
      participantsEmails() {
        if (this.jobInfo.participantsEmails && this.jobInfo.participantsEmails.length) {
          return this.jobInfo.participantsEmails.map((email) => email.email);
        }
      },
      participantsPhones() {
        if (this.jobInfo.participantsPhones && this.jobInfo.participantsPhones.length) {
          return this.jobInfo.participantsPhones;
        }
      },
      interpretationType() {
        switch (this.jobInfo.sessionType) {
          case 'video':
            return this.$gettext('Video');
          case 'phone':
            return this.$gettext('Phone');
          case 'in_person':
            return this.$gettext('In-person');
          case 'videoroom':
            return this.$gettext('Video room');
        }
      },
      videoSystem() {
        const videoPlatformId = this.jobInfo.videoPlatform?.videoPlatformId;
        const videoPlatformType = this.jobInfo.videoPlatform?.videoPlatformType;
        const videoPlatformsList = this.jobInfo.videoPlatform?.videoPlatformsList || [];

        if (videoPlatformId || videoPlatformType) {
          const videoPlatformName = videoPlatformsList.find((platform) => platform.id == videoPlatformId)?.name;
          return helpers.getJobInfo.videoPlatformType(this, videoPlatformType, videoPlatformName);
        }
      },
      videoSystemInstructions() {
        return this.jobInfo.videoPlatform?.videoPlatformInstructions || '';
      },
      qualification() {
        const qualificationsList = this.$store.state.selectsData.qualification;
        const qualification = qualificationsList.find((qualif) => qualif.id == this.jobInfo.qualificationId) || {};
        return qualification.name;
      },
      gender() {
        const switcher = this.jobInfo.sex !== undefined && this.jobInfo.sex.toString();
        switch (switcher) {
          case '0':
            return this.$gettext('Male');
          case '1':
            return this.$gettext('Female');
          case '2':
            return this.$gettext('Not relevant');
          case '3':
            return this.$gettext('Transgender');
        }
      },
      savedContactAddress() {
        const id = this.contactAttributes.savedContactAddress?.id;
        const list = this.contactAttributes.savedContactAddress?.savedContactAddressesList;
        if (id && list) {
          const savedAddress = list.find((address) => address.id == id) || {};
          return helpers.getJobInfo.savedAddressName(savedAddress);
        }
      },
      contactPersonName() { return this.contactAttributes.person?.name || ''; },
      contactPersonPhone() { return this.contactAttributes.person?.phone || ''; },
      contactWayDescription() { return this.contactAttributes.description || ''; },
      contactAddress() { return this.contactAttributes.address || ''; },
      attachments() {
        const attachments = this.jobInfo.attachments || [];
        if (attachments.length) { return attachments.map((attachment) => attachment.name); }
      },
      filesAttached() {
        return this.attachments && this.attachments.length;
      },
      submitDisabled() {
        if (this.data.showFileUploadCheckbox && !this.filesAttached && !this.isInPersonOrder) return !this.confirmNoFile;
        else return false;
      },
      confirmNoFileText() {
        return this.$gettext('I confirm that there are no files for me to upload right now. If necessary, I will share files directly with the project manager.');
      }
    },
    watch: {
      $route() {
        this.closeModal();
      }
    },
    methods: {
      getToggleValue(value) {
        if (value !== undefined) return value ? this.$gettext('ON') : this.$gettext('OFF');
      },
      getStandByValue(value) {
        if (value !== undefined) return value === 'turned_on' ? this.$gettext('ON') : this.$gettext('OFF');
      },
      getDateTime(dateTime) {
        return dateTime ? this.$moment(dateTime).format('DD.MM.YYYY HH:mm') : '';
      },
      autofocusOnFirstAction() { return this.$nextTick(() => this.$refs.summaryModalBackBtn.focus()); },
      onCloseClick() {
        this.closeModal();
      },
      closeModal() {
        this.$store.commit('PostInterpretationStore/setDirectAward', false);
        this.$emit('closemodal');
      },
      postJob() {
        this.$emit('startprogress');
        this.data.context.sendInfo();
        this.data.context.$on('postassignfailed', this.stopProgress);
        this.data.context.$on('postassignsuccess', this.closeModal);
      },
      getFormattedCategories(initList) {
        const res = [];
        initList.forEach((item) => {
          res.push(item);
          if (item && item.items && item.items.length) {
            item.items.forEach((subItem) => {
              res.push({...subItem, name: `${item.name} - ${subItem.name}`});
            });
          }
        });
        return res;
      },
      getJobProcessingName(processingName) {
        return this.processRequirement[processingName]
          ? helpers.getJobInfo.jobProcessRequirement(this, this.processRequirement[processingName])
          : '';
      },
      getSeveralInterpretersProcessingName() {
        const template = this.$gettext('I need 2 interpreters for this assignment');
        const processingNameText = this.getJobProcessingName('severalSuppliersProcessing');

        return processingNameText ? `${template} <br> ${processingNameText}` : '';
      },
      stopProgress() {
        this.$emit('stopprogress');
      },
      catchConfirmNoFile(value) {
        this.confirmNoFile = value;
      }
    },
    mounted() {
      const skModalCloseBtn = document.querySelector('.sk-modal__close-btn');
      if (skModalCloseBtn) {
        skModalCloseBtn.addEventListener('click', this.onCloseClick);
      }
      this.autofocusOnFirstAction();
    }
  };
</script>

<style scoped>
  .post-assign-summary {
    display: block;
    overflow: hidden;
    width: 100%;
    padding: 10px 30px 20px;
  }

  .post-assign-summary__btns-cont {
    display: flex;
    justify-content: flex-end;
    margin-top: 10px;
  }

  .post-assign-summary__btn {
    display: inline-block;
    width: auto;
    padding: 0 20px;
  }

  .post-assign-summary__btn--back {
    margin-right: 20px;
  }

  .post-assign-summary__confirm-checkbox {
    margin-top: 10px;
    margin-bottom: 20px;
    font-size: 13px;
  }

  @media (max-width: 767px) {
    .post-assign-summary {
      padding-right: 15px;
      padding-left: 15px;
    }
  }

  @media (max-width: 450px) {
    .post-assign-summary__btns-cont {
      flex-direction: column-reverse;
      flex-wrap: wrap;
    }

    .post-assign-summary__btn--back {
      margin-top: 15px;
    }

    .post-assign-summary__btn {
      width: 100%;
      margin-right: 0;
    }
  }
</style>
