(<template>
  <article class="assign-withdraw">
    <div v-if="enableWithdraw">
      <p class="assign-withdraw__text">{{ $gettext('We understand that sometimes last minute changes can occur. We will try our best to find a replacement interpreter for this customer.') }}</p>
      <p class="assign-withdraw__text">{{ contactCustomerText }}</p>
      <p class="assign-withdraw__text">{{ $gettext('After withdrawing, you shall not interpret for this customer. The customer will pay the confirmed interpreter only, and not you.') }}</p>
      <sk-note v-if="showShortPeriodNotice"
               class="assign-withdraw__text">
        <span v-html="withdrawNoteText"></span>
      </sk-note>
      <div class="assign-withdraw__reason">
        <sk-radio v-for="reason in reasonCheckboxes"
                  :key="reason"
                  :preselected-value="chosenRadio"
                  :item-text="reason"
                  :item-value="reason"
                  :item-name="'reason'"
                  class="assign-withdraw__radio"
                  @radiochanged="changeReason" />
        <sk-textarea v-if="showExtraField"
                     :description="extraInfoDescriptionText"
                     :placeholder="extraInfoPlaceholderText"
                     :preselected-value="reasonText"
                     :validation-name="withdrawInput"
                     class="assign-withdraw__reason-field"
                     @fieldvaluechanged="catchReasonText" />
      </div>
      <div class="assign-withdraw__btn-cont">
        <button class="sk-btn sk-btn--white assign-withdraw__btn assign-withdraw__btn--cancel"
                @click="closeModal">{{ $gettext('Cancel') }}</button>
        <button class="sk-btn sk-btn--red assign-withdraw__btn"
                @click="sendWithdraw">{{ $gettext('Withdraw offer') }}</button>
      </div>
    </div>
    <div v-else>
      <p v-html="emailLinkText"></p>
    </div>
  </article>
</template>)

<script>
  import {mapGetters} from 'vuex';
  import getProductConfig from '@/modules/productConfig';

  export default {
    props: {
      data: {
        type: Object,
        default() {
          return {};
        }
      },
      orderStartTime: {
        type: String,
        default: ''
      },
    },
    data() {
      return {
        chosenRadio: '',
        reasonText: '',
        withdrawInput: 'withdrawReason',
        withdrawErrors: [],
        domain: this.$getString('domainData', 'platformName'),
        currentTime: new Date().toLocaleTimeString(),
        enableWithdraw: true,
        withdrawTimer: null
      };
    },
    computed: {
      ...mapGetters('OneAssignmentStore', [
        'interDiscussion',
        'storeJob'
      ]),
      showShortPeriodNotice() { return this.storeJob.shortNoticePeriodStarted; },
      extraInfoDescriptionText() { return this.$gettext('Extra information'); },
      extraInfoPlaceholderText() { return this.$gettext('Write here...'); },
      showExtraField() { return this.chosenRadio == this.reasonCheckboxes[this.reasonCheckboxes.length - 1]; },
      reasonCheckboxes() {
        return [
          this.$gettext('I have double booked myself'),
          this.$gettext('I am sick'),
          this.$gettext('My kid is sick'),
          this.$gettext('I am not suitable for this assignment'),
          this.$gettext('Other')
        ];
      },
      withdrawNoteText() {
        const line1 = this.$gettext('The assignment starts in less than 24 hours.\n'
          + 'Finding a replacement will be difficult, and there is a high chance that this assignment will fail.');

        const line2 = this.$gettext('This will appear on your profile as a no-show. You can of course '
          + 'provide a reason why you are withdrawing on short notice, and the no-show will be removed from your profile.');
        return `${line1}<br><br>${line2}`;
      },
      contactCustomerText() {
        const domain = this.$getString('domainData', 'platformName');
        const template = this.$gettext('If the customer does contacts you, tell them to contact %{domain} or to check their inbox. They may have received a replacement interpreter but haven\'t noticed it yet.');
        return this.$gettextInterpolate(template, {domain});
      },
      supportInterpreterEmail() {
        return getProductConfig(this.$gettext, 'salita', 'domainData', 'subSupportEmail');
      },
      emailLinkText() {
        const template = this.$gettext('If you are unable to complete the assignment, please contact us at <a class="sk-link sk-link--gray-bg" href="mailto:%{email}">%{email}</a> (Remember to state the assignment number)');
        return this.$gettextInterpolate(template, {email: this.supportInterpreterEmail});
      },
    },
    methods: {
      closeModal() {
        this.$emit('closemodal');
      },
      changeReason(value) {
        this.chosenRadio = value;
      },
      catchReasonText(value) {
        this.reasonText = value;
      },
      sendWithdraw() {
        this.withdrawErrors = [];
        this.$store.commit('ModalStore/removeModalErrors');
        if (!this.chosenRadio) {
          return this.$store.commit('ModalStore/setModalErrors', this.$gettext('Reason is required'));
        }
        if (!this.reasonText.trim() && this.showExtraField) {
          this.withdrawErrors.push(this.$gettext('Please provide a reason'));
          this.$store.commit('ErrorsStore/setError', {name: this.withdrawInput, errors: this.withdrawErrors});
        }
        if (!this.withdrawErrors.length) {
          this.data.context.sendWithdraw(this.showExtraField ? this.reasonText : this.chosenRadio);
          this.closeModal();
        }
      }
    },
    mounted() {
      if (this.$isGodmode()) {
        return false;
      } else {
        this.withdrawTimer = setInterval(() => {
          const diffMs = new Date(this.data.orderStartTime) - new Date(); // subtract the current time from the start time order
          const diffHours = diffMs / 1000 / 60 / 60; // сonverting milliseconds to hours
          this.enableWithdraw = diffHours >= 48;
        }, 1500);
      }
    },
    beforeUnmount() {
      clearInterval(this.withdrawTimer);
    }
  };
</script>

<style scoped>
  .assign-withdraw {
    display: block;
    width: 100%;
    padding: 20px 30px 30px;
  }

  .assign-withdraw__text {
    padding-bottom: 20px;
  }

  .assign-withdraw__radio {
    display: block;
    width: 100%;
    margin-top: 10px;
  }

  .assign-withdraw__reason-field {
    height: 98px;
    margin-top: 10px;
  }

  .assign-withdraw__btn-cont {
    display: flex;
    justify-content: flex-end;
    margin-top: 20px;
  }

  .assign-withdraw__btn {
    width: auto;
    padding: 0 20px;
  }

  .assign-withdraw__btn--cancel {
    margin-right: 20px;
  }
</style>
