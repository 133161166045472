import { render, staticRenderFns } from "./ProjectExtraInfoSection.vue?vue&type=template&id=46044919&scoped=true&"
import script from "./ProjectExtraInfoSection.vue?vue&type=script&lang=js&"
export * from "./ProjectExtraInfoSection.vue?vue&type=script&lang=js&"
import style0 from "./ProjectExtraInfoSection.vue?vue&type=style&index=0&id=46044919&prod&lang=css&"
import style1 from "./ProjectExtraInfoSection.vue?vue&type=style&index=1&id=46044919&prod&scoped=true&lang=css&"


/* normalize component */
import normalizer from "!../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "46044919",
  null
  
)

export default component.exports